import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { TaikhoanmymobiAction } from 'src/actions/taikhoanmymobi.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    Box,
    MenuItem,
    Select as Select1,
    InputAdornment,
    SvgIcon,
    InputLabel,
    TableCell,
} from '@material-ui/core';

import {
    DatePicker,
    LocalizationProvider,
  } from '@mui/x-date-pickers';
  import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';

import numeral from 'numeral';

const CreateEditDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { role } = useSelector(state => state.profile);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.taikhoanmymobi)
    const [isOpen, setIsOpen] = useState(false);
    const [ung_dung, setUngDung] = useState("-99")
    const [loai_thue_bao, setLoaiThueBao] = useState("-99")
    const [tai_khoan, setTaiKhoan] = useState("")
    const [mat_khau, setMatKhau] = useState("")
    const [nhom, setNhom] = useState("")
    const [muc_uu_tien, setMucUuTien] = useState("0")
    const [trang_thai, setTrangThai] = useState(false);
    const [nap_ho, setNapHo] = useState(false);

    const [values, setValues] = useState({
        menh_gia: ['-99'],
    });

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
        dispatch(PermissionAction.list(0, 100))
    }, [])

    useEffect(() => {
        if (props.type === 'EDIT') {
            if (selected) {
                setUngDung(selected.ung_dung)
                setLoaiThueBao(selected.loai_thue_bao)
                setTaiKhoan(selected.tai_khoan)
                setNhom(selected.nhom)
                setMucUuTien(selected.muc_uu_tien.toString())
                setTrangThai(selected.trang_thai)
                setNapHo(selected.nap_ho)
                setValues({
                    ...values,
                    menh_gia: ['-99'],
                })
                if (selected.menh_gia) {
                    let str = selected.menh_gia.toLowerCase();
                    let charArray = str.split(', ');
                    setValues({
                        ...values,
                        menh_gia: charArray,
                    })
                }
            }
        }
    }, [selected])

    const onPressPositive = async () => {
        const edited_muc_uu_tien = muc_uu_tien.replace(/,/g, '');

        if (ung_dung === "-99") {
            return;
        }
        if (loai_thue_bao === "-99") {
            return;
        }
        if (tai_khoan === "") {
            return;
        }
        if (values.menh_gia.includes('-99')) {
            return;
        }
        if (props.type === 'EDIT') {
            try {
                dispatch(TaikhoanmymobiAction.update(selected.id, { 
                    ung_dung: ung_dung, 
                    loai_thue_bao: loai_thue_bao, 
                    tai_khoan: tai_khoan,
                    mat_khau: mat_khau,
                    nhom: nhom,
                    muc_uu_tien: edited_muc_uu_tien,
                    menh_gia: values.menh_gia,
                    trang_thai: trang_thai,
                    nap_ho: nap_ho,
                }))
                resetState();
                props.handleClose();
            } catch (error) {
                console.log('update_error', error)
            }
        } else {
            try {
                const response = await dispatch(TaikhoanmymobiAction.create({ 
                    ung_dung: ung_dung, 
                    loai_thue_bao: loai_thue_bao, 
                    tai_khoan: tai_khoan,
                    mat_khau: mat_khau,
                    nhom: nhom,
                    muc_uu_tien: edited_muc_uu_tien,
                    menh_gia: values.menh_gia,
                    trang_thai: trang_thai,
                    nap_ho: nap_ho,
                }))
                resetState();
                props.handleClose();
            } catch (error) {
                console.log('create_error', error)
            }
        }
    }

    const resetState = () => {
        setUngDung("-99")
        setLoaiThueBao("-99")
        setTaiKhoan("")
        setMatKhau("")
        setNhom("")
        setMucUuTien("0")
        setTrangThai(false)
        setNapHo(false)
        setValues({
            ...values,
            menh_gia: ['-99'],
        })
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    const handleChange = (event) => {
        if (event.target.name == 'menh_gia') {
            setIsOpen(false);
            let targetValues = event.target.value;
            targetValues = targetValues.filter((value) => value != '-99')
            if (targetValues.length == 0) targetValues.push('-99');
            setValues({
                ...values,
                [event.target.name]: targetValues,
            });
        } else {
            setValues({
                ...values,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleClickChonNhaMang = async () => {
        setIsOpen(true);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={props.open} onClose={props.handleClose}>
                <DialogTitle>{props.type === 'EDIT' ? "Cập nhật bản ghi" : "Tạo bản ghi mới"}</DialogTitle>
                <DialogContent style={{ width: 600, paddingTop: 20 }}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Select1
                                style={{ height: 56, marginTop: 0, marginBottom: 0, width: "100%", marginLeft: 0 }}
                                name="ung_dung"
                                value={ung_dung || '-99'}
                                onChange={(e) => setUngDung(e.target.value)}
                                variant="outlined"
                            >
                                <MenuItem value="-99">Ứng dụng...</MenuItem>
                                <MenuItem value="MyMobi">MyMobi</MenuItem>
                            </Select1>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Select1
                                style={{ height: 56, marginTop: 0, marginBottom: 0, width: "100%", marginLeft: 0 }}
                                name="loai_thue_bao"
                                value={loai_thue_bao || '-99'}
                                onChange={(e) => setLoaiThueBao(e.target.value)}
                                variant="outlined"
                            >
                                <MenuItem value="-99">Loại thuê bao...</MenuItem>
                                <MenuItem value="1">Trả trước</MenuItem>
                                <MenuItem value="2">Trả sau</MenuItem>
                            </Select1>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Tài khoản"
                                name="tai_khoan"
                                onChange={(e) => setTaiKhoan(e.target.value)}
                                required
                                value={tai_khoan}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Mật khẩu"
                                name="mat_khau"
                                onChange={(e) => setMatKhau(e.target.value)}
                                value={mat_khau}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Nhóm"
                                name="nhom"
                                onChange={(e) => setNhom(e.target.value)}
                                value={nhom}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Mức ưu tiên"
                                name="muc_uu_tien"
                                onChange={(e) => { let value = e.target.value; let raw_value = e.target.value.replace(/,/g, ''); value = numeral(value).format('0,0'); setMucUuTien(value); }}
                                value={muc_uu_tien}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Select1
                                style={{ height: 56, marginTop: 0, marginBottom: 0, width: "100%", marginLeft: 0 }}
                                name="menh_gia"
                                value={values.menh_gia || ['-99']}
                                onChange={handleChange}
                                onOpen={handleClickChonNhaMang}
                                variant="outlined"
                                open={isOpen}
                                multiple
                            >
                                <MenuItem value="-99">Mệnh giá...</MenuItem>
                                <MenuItem value="10" style={{backgroundColor: values.menh_gia.includes('10') ? 'yellow' : 'inherit'}}>10</MenuItem>
                                <MenuItem value="20" style={{backgroundColor: values.menh_gia.includes('20') ? 'yellow' : 'inherit'}}>20</MenuItem>
                                <MenuItem value="30" style={{backgroundColor: values.menh_gia.includes('30') ? 'yellow' : 'inherit'}}>30</MenuItem>
                                <MenuItem value="50" style={{backgroundColor: values.menh_gia.includes('50') ? 'yellow' : 'inherit'}}>50</MenuItem>
                                <MenuItem value="100" style={{backgroundColor: values.menh_gia.includes('100') ? 'yellow' : 'inherit'}}>100</MenuItem>
                                <MenuItem value="200" style={{backgroundColor: values.menh_gia.includes('200') ? 'yellow' : 'inherit'}}>200</MenuItem>
                                <MenuItem value="300" style={{backgroundColor: values.menh_gia.includes('300') ? 'yellow' : 'inherit'}}>300</MenuItem>
                                <MenuItem value="500" style={{backgroundColor: values.menh_gia.includes('500') ? 'yellow' : 'inherit'}}>500</MenuItem>
                                <MenuItem value="1000" style={{backgroundColor: values.menh_gia.includes('1000') ? 'yellow' : 'inherit'}}>1.000</MenuItem>
                            </Select1>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            style={{marginTop: 15, marginLeft: 25}}
                        >
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Typography
                                color="blue"
                                variant="body1"
                                >
                                    Cài đặt khác
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TableCell padding="checkbox" style={{width: '30px'}}>
                                    <Checkbox
                                        checked={nap_ho ? true : false}
                                        onChange={(event) => setNapHo(nap_ho ? false : true)}
                                        value={nap_ho ? 1 : 2}
                                        style={{width: '20px'}}
                                    />
                                </TableCell>
                                <TableCell style={{padding: 0}}>
                                    Nạp hộ
                                </TableCell>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TableCell padding="checkbox" style={{width: '30px'}}>
                                    <Checkbox
                                        checked={trang_thai ? true : false}
                                        onChange={(event) => setTrangThai(trang_thai ? false : true)}
                                        value={trang_thai ? 1 : 2}
                                        style={{width: '20px'}}
                                    />
                                </TableCell>
                                <TableCell style={{padding: 0}}>
                                    Hoạt động
                                </TableCell>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Huỷ</Button>
                    <Button onClick={onPressPositive}>{props.type === 'EDIT' ? "Cập nhật" : "Tạo"}</Button>
                </DialogActions>
            </Dialog >
        </LocalizationProvider>
    );
}

export default CreateEditDialog;