import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { BankAction } from 'src/actions/bank.action';
import {
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core';

import numeral from 'numeral';

const BankLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { items, total, filter, tongChuyenKhoan, tongDonChuyen } = useSelector(state => state.bank);
  const { role } = useSelector(state => state.profile);
  const [selectedBankIds, setSelectedBankIds] = useState([]);
  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);
  const [bankUpdate, setBankUpdate] = useState([]);

  useEffect(() => {
    const payload = {
      ...filter,
      page: page,
      limit: limit,
    };

    dispatch(BankAction.updateFilter(payload));

    dispatch(BankAction.list());
  }, [page, limit])

  useEffect(() => {
    if (items) {
      console.log(items)
      for (let i = 0; i < items.length; i++) {
        if (typeof items[i] !== 'undefined') {
          setBankUpdate({
            ...bankUpdate,
            [items[i].id]: {
              so_dien_thoai: items[i].so_dien_thoai,
              goi_cuoc: items[i].goi_cuoc,
            },
          });
        }
      }
    }
  }, [items, page, limit])

  const handleSelectAll = (event) => {
    let newSelectedBankIds;

    if (event.target.checked) {
      newSelectedBankIds = items.map((bank) => bank.id);
    } else {
      newSelectedBankIds = [];
    }

    setSelectedBankIds(newSelectedBankIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedBankIds.indexOf(id);
    let newSelectedBankIds = [];

    if (selectedIndex === -1) {
      newSelectedBankIds = newSelectedBankIds.concat(selectedBankIds, id);
    } else if (selectedIndex === 0) {
      newSelectedBankIds = newSelectedBankIds.concat(selectedBankIds.slice(1));
    } else if (selectedIndex === selectedBankIds.length - 1) {
      newSelectedBankIds = newSelectedBankIds.concat(selectedBankIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedBankIds = newSelectedBankIds.concat(
        selectedBankIds.slice(0, selectedIndex),
        selectedBankIds.slice(selectedIndex + 1)
      );
    }

    setSelectedBankIds(newSelectedBankIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleUpdate = (id, fieldName, value) => {
    setBankUpdate({
      ...bankUpdate,
      [id]: {
        ...bankUpdate[id],
        [fieldName]: typeof bankUpdate[id] !== 'undefined' && typeof bankUpdate[id][fieldName] !== 'undefined' ? value : '',
      },
    });
  };

  const updateChange = async (id, so_dien_thoai, goi_cuoc) => {
    if (typeof bankUpdate[id] !== 'undefined') {
      console.log(bankUpdate[id])

      const result = await dispatch(BankAction.update(id, {so_dien_thoai: typeof bankUpdate[id]['so_dien_thoai'] !== 'undefined' ? bankUpdate[id]['so_dien_thoai'] : so_dien_thoai, goi_cuoc: typeof bankUpdate[id]['goi_cuoc'] !== 'undefined' ? bankUpdate[id]['goi_cuoc'] : goi_cuoc }))
    }
  };

  const approve = async (id) => {
    const question = "Xác nhận duyệt?" ;
    if (window.confirm(question)) {
      try {
        console.log("Duyệt", id)
        const result = await dispatch(BankAction.approve(id))
      } catch (error) {

      }
    } else {
      
    }
  };

  const deny = async (id) => {
    const question = "Xác nhận từ chối?" ;
    if (window.confirm(question)) {
      try {
        console.log("Từ chối", id)
        const result = await dispatch(BankAction.deny(id))
      } catch (error) {

      }
    } else {
      
    }
  };

  const setTrangThaiXuLy = async (id, is_read) => {
    console.log("Đổi trạng thái xử lý", id, is_read)

    const result = await dispatch(BankAction.setTrangThaiXuLy(id, {is_read}))
  };

  function replaceAll(inputString, search, replacement) {
    // Create a regular expression with the 'g' flag to replace all occurrences
    const regex = new RegExp(search, 'g');
    
    // Use the replace method with the regular expression to replace all occurrences
    const result = inputString.replace(regex, replacement);
    
    return result;
  }

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
            <TableRow>
                <TableCell style={{color: "green"}}>
                TỔNG CHUYỂN KHOẢN
                </TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongChuyenKhoan).format('0,0')}
                </TableCell>
                <TableCell style={{color: "green"}}>
                TỔNG ĐƠN CHUYỂN
                </TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongDonChuyen).format('0,0')}
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {role.priority < 3 && <TableCell></TableCell>}
                {/* <TableCell></TableCell>
                <TableCell></TableCell>
                {role.priority < 3 && <TableCell></TableCell>}
                {role.priority < 3 && <TableCell></TableCell>}
                <TableCell></TableCell> */}
              </TableRow>
              <TableRow style={{ background: 'red' }}>
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  #
                </TableCell>
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  User nhận CK
                </TableCell>
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Kênh
                </TableCell>
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Số tiền
                </TableCell>
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Thời gian
                </TableCell>
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Nội dung
                </TableCell>
                {role.priority < 3 && <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Trạng thái tự động xử lý
                </TableCell>}
                {/* <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Số điện thoại
                </TableCell>
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Gói cước
                </TableCell>
                {role.priority < 3 && <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Người cập nhật
                </TableCell>}
                {role.priority < 3 && <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Trạng thái duyệt cập nhật
                </TableCell>}
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Hành động
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, (page + 1) * limit).map((bank, index) => (
                <TableRow
                  hover
                  key={bank.id}
                  selected={selectedBankIds.indexOf(bank.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    {bank.username} - {bank.fullname}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {bank.sender}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {numeral(bank.money).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {moment(bank.datetime).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {replaceAll(bank.str_mes, 'Đại lý chuyển khoản', 'AUTOBANK đại lý cộng số dư')}
                  </TableCell>
                  {role.priority < 3 && <TableCell>
                    <Select style={{ fontSize: "14px", width: "100%", color: bank.is_read ? 'green' : 'red'}} variant="outlined" value={bank.is_read} onChange={(e) => setTrangThaiXuLy(bank.id, e.target.value)}>
                      <MenuItem value="0">Chưa xử lý</MenuItem>
                      <MenuItem value="1">Đã xử lý</MenuItem>
                    </Select>
                  </TableCell>}
                  {/* <TableCell>
                    {bank.is_read == 0 && <TextField
                      name='so_dien_thoai'
                      onChange={(e) => handleUpdate(bank.id, 'so_dien_thoai', e.target.value)}
                      value={(typeof bankUpdate[bank.id] !== 'undefined' && typeof bankUpdate[bank.id]['so_dien_thoai'] !== 'undefined' ? bankUpdate[bank.id]['so_dien_thoai'] : '') || bank.so_dien_thoai}
                      placeholder="Số điện thoại"
                      variant="outlined"
                      disabled={role.priority > 2 && bank.so_dien_thoai != null && bank.so_dien_thoai.trim() != '' ? true : false}
                      style={{ height: 56, width: 150, marginLeft: 0 }}
                    />}
                    {bank.is_read == 1 && bank.so_dien_thoai}
                  </TableCell>
                  <TableCell>
                    {bank.is_read == 0 && <TextField
                      name='goi_cuoc'
                      onChange={(e) => handleUpdate(bank.id, 'goi_cuoc', e.target.value)}
                      value={(typeof bankUpdate[bank.id] !== 'undefined' && typeof bankUpdate[bank.id]['goi_cuoc'] !== 'undefined' ? bankUpdate[bank.id]['goi_cuoc'] : '') || bank.goi_cuoc}
                      placeholder="Gói cước"
                      variant="outlined"
                      disabled={role.priority > 2 && bank.goi_cuoc != null && bank.goi_cuoc.trim() != '' ? true : false}
                      style={{ height: 56, width: 150, marginLeft: 0 }}
                    />}
                    {bank.is_read == 1 && bank.goi_cuoc}
                  </TableCell>
                  {role.priority < 3 && <TableCell>
                    {bank.nguoi_cap_nhat}
                  </TableCell>}
                  {role.priority < 3 && <TableCell style={{color: bank.trang_thai_duyet_thong_tin == 1 ? 'green' : bank.trang_thai_duyet_thong_tin == 2 ? 'red' : ''}}>
                    {bank.trang_thai_duyet_thong_tin == 1 ? 'Đã duyệt' : (bank.trang_thai_duyet_thong_tin == 2 ? 'Đã từ chối' : '')}
                  </TableCell>}
                  <TableCell>
                    {bank.is_read == 0 && 
                      <>{(role.priority < 3 || role.priority > 2 && (!bank.so_dien_thoai || !bank.goi_cuoc)) && <Button onClick={() => updateChange(bank.id, bank.so_dien_thoai, bank.goi_cuoc)}>
                        Cập nhật
                      </Button>}
                      {role.priority < 3 && bank.so_dien_thoai && bank.goi_cuoc && !bank.username_duyet_trang_thai_duyet_thong_tin && <><Button style={{color: 'green'}} onClick={() => approve(bank.id)}>
                        Duyệt
                      </Button>
                      <Button style={{color: 'red'}} onClick={() => deny(bank.id)}>
                        Từ chối
                      </Button></>}</>
                    }
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
    </Card>
  );
};

export default BankLookupDetail;
