import { InviteService } from 'src/services/invite.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';
import { ReportAction } from './report.acction';

import moment from 'moment';

import MainSocket from 'src/components/MainSocket.js'

export const INVITE_GET_OTP_SUCCESS = 'INVITE:GET_OTP_SUCCESS'
export const INVITE_GET_OTP_FAILD = 'INVITE:GET_OTP_FAILD'
export const INVITE_CONFIRM_OTP_SUCCESS = 'INVITE:CONFIRM_OTP_SUCCESS'
export const INVITE_CONFIRM_OTP_FAILD = 'INVITE:CONFIRM_OTP_FAILD'

export const INVITE_GET_OTPnew_SUCCESS = 'INVITE:GET_OTPnew_SUCCESS'
export const INVITE_GET_OTPnew_FAILD = 'INVITE:GET_OTPnew_FAILD'
export const INVITE_CONFIRM_OTPnew_SUCCESS = 'INVITE:CONFIRM_OTPnew_SUCCESS'
export const INVITE_CONFIRM_OTPnew_FAILD = 'INVITE:CONFIRM_OTPnew_FAILD'

export const INVITE_GET_OTPnew1_SUCCESS = 'INVITE:GET_OTPnew1_SUCCESS'
export const INVITE_GET_OTPnew1_FAILD = 'INVITE:GET_OTPnew1_FAILD'
export const INVITE_CONFIRM_OTPnew1_SUCCESS = 'INVITE:CONFIRM_OTPnew1_SUCCESS'
export const INVITE_CONFIRM_OTPnew1_FAILD = 'INVITE:CONFIRM_OTPnew1_FAILD'

export const INVITE_RENEW_SUCCESS = 'INVITE:RENEW_SUCCESS'
export const INVITE_RENEW_FAILD = 'INVITE:RENEW_FAILD'

export const InviteAction = {
    inviteGetOTP,
    inviteConfirmOTP,
    inviteGetOTPnew,
    inviteConfirmOTPnew,
    inviteGetOTPnew1,
    inviteConfirmOTPnew1,
    inviteRenew,
    inviteImmediate
}

function inviteGetOTP(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            dispatch(AppAction.appLoading(true));
            InviteService.invite(payload)
            .then(rs => {
                // Handle the successful response
                dispatch(success(rs.data))
                showToast(NOTI_TYPES.SUCCESS, "Đã gửi mã OTP thành công")
                resolve(rs); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                showToast(NOTI_TYPES.ERROR, error.msg)
                reject(error); // Reject the promise with the error
            })
            .finally(() => {
                dispatch(AppAction.appLoading(false));
            });
        });
    };
    function success(data) { return { type: INVITE_GET_OTP_SUCCESS, data } }
    function failure(error) { return { type: INVITE_GET_OTP_FAILD, data: error } }
}

function inviteConfirmOTP(payload) {
    return (dispatch, getState) => {
        const { socket, socket_client_id, domain, username_nv } = MainSocket(getState());
        dispatch(AppAction.appLoading(true));
        const { report } = getState();
        InviteService.invite(payload).then(async (rs) => {
            dispatch(success(rs.data))
            await dispatch(ReportAction.list9084({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: report && typeof report.query9084 !== 'undefined' && report.query9084 && typeof report.query9084.page !== 'undefined' ? report.query9084.page : 0,
                count: report && typeof report.query9084 !== 'undefined' && report.query9084 && typeof report.query9084.count !== 'undefined' ? report.query9084.count : 10,
            }))
            showToast(NOTI_TYPES.SUCCESS, "Xác nhận mã OTP thành công")
            socket.emit("showThongBao", {domain: domain, content: username_nv.substring(0, 5) + "***** đã mời thành công gói " + payload.package.toUpperCase() + " cho *****" + payload.phone.slice(-3)})
        }).catch(async (error) => {
            dispatch(failure(error.error));
            await dispatch(ReportAction.list9084({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: report && typeof report.query9084 !== 'undefined' && report.query9084 && typeof report.query9084.page !== 'undefined' ? report.query9084.page : 0,
                count: report && typeof report.query9084 !== 'undefined' && report.query9084 && typeof report.query9084.count !== 'undefined' ? report.query9084.count : 10,
            }))
            showToast(NOTI_TYPES.ERROR, error.msg)
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: INVITE_CONFIRM_OTP_SUCCESS, data } }
    function failure(error) { return { type: INVITE_CONFIRM_OTP_FAILD, data: error } }
}

function inviteGetOTPnew(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            dispatch(AppAction.appLoading(true));
            InviteService.invite(payload)
            .then(rs => {
                // Handle the successful response
                dispatch(success(rs.data))
                showToast(NOTI_TYPES.SUCCESS, "Đã gửi mã OTP thành công")
                resolve(rs); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                showToast(NOTI_TYPES.ERROR, error.msg)
                reject(error); // Reject the promise with the error
            })
            .finally(() => {
                dispatch(AppAction.appLoading(false));
            });
        });
    };
    function success(data) { return { type: INVITE_GET_OTPnew_SUCCESS, data } }
    function failure(error) { return { type: INVITE_GET_OTPnew_FAILD, data: error } }
}

function inviteConfirmOTPnew(payload) {
    return (dispatch, getState) => {
        const { socket, socket_client_id, domain, username_nv } = MainSocket(getState());
        dispatch(AppAction.appLoading(true));
        const { report } = getState();
        InviteService.invite(payload).then((rs) => {
            dispatch(success(rs.data))
            dispatch(ReportAction.list9084new({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: report && typeof report.query9084new !== 'undefined' && report.query9084new && typeof report.query9084new.page !== 'undefined' ? report.query9084new.page : 0,
                count: report && typeof report.query9084new !== 'undefined' && report.query9084new && typeof report.query9084new.count !== 'undefined' ? report.query9084new.count : 10,
            }))
            showToast(NOTI_TYPES.SUCCESS, "Xác nhận mã OTP thành công")
            socket.emit("showThongBao", {domain: domain, content: username_nv.substring(0, 5) + "***** đã mời thành công gói " + payload.package.toUpperCase() + " cho *****" + payload.phone.slice(-3)})
        }).catch((error) => {
            dispatch(failure(error.error));
            dispatch(ReportAction.list9084new({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: report && typeof report.query9084new !== 'undefined' && report.query9084new && typeof report.query9084new.page !== 'undefined' ? report.query9084new.page : 0,
                count: report && typeof report.query9084new !== 'undefined' && report.query9084new && typeof report.query9084new.count !== 'undefined' ? report.query9084new.count : 10,
            }))
            showToast(NOTI_TYPES.ERROR, error.msg)
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: INVITE_CONFIRM_OTPnew_SUCCESS, data } }
    function failure(error) { return { type: INVITE_CONFIRM_OTPnew_FAILD, data: error } }
}

function inviteGetOTPnew1(payload) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            dispatch(AppAction.appLoading(true));
            InviteService.invite(payload)
            .then(rs => {
                // Handle the successful response
                dispatch(success(rs.data))
                showToast(NOTI_TYPES.SUCCESS, "Đã gửi mã OTP thành công")
                resolve(rs); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                showToast(NOTI_TYPES.ERROR, error.msg)
                reject(error); // Reject the promise with the error
            })
            .finally(() => {
                dispatch(AppAction.appLoading(false));
            });
        });
    };
    function success(data) { return { type: INVITE_GET_OTPnew1_SUCCESS, data } }
    function failure(error) { return { type: INVITE_GET_OTPnew1_FAILD, data: error } }
}

function inviteConfirmOTPnew1(payload) {
    return (dispatch, getState) => {
        const { socket, socket_client_id, domain, username_nv } = MainSocket(getState());
        dispatch(AppAction.appLoading(true));
        const { report } = getState();
        InviteService.invite(payload).then((rs) => {
            dispatch(success(rs.data))
            dispatch(ReportAction.list9084new1({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: report && typeof report.query9084new1 !== 'undefined' && report.query9084new1 && typeof report.query9084new1.page !== 'undefined' ? report.query9084new1.page : 0,
                count: report && typeof report.query9084new1 !== 'undefined' && report.query9084new1 && typeof report.query9084new1.count !== 'undefined' ? report.query9084new1.count : 10,
            }))
            showToast(NOTI_TYPES.SUCCESS, "Xác nhận mã OTP thành công")
            socket.emit("showThongBao", {domain: domain, content: username_nv.substring(0, 5) + "***** đã mời thành công gói " + payload.package.toUpperCase() + " cho *****" + payload.phone.slice(-3)})
        }).catch((error) => {
            dispatch(failure(error.error));
            dispatch(ReportAction.list9084new1({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: report && typeof report.query9084new1 !== 'undefined' && report.query9084new1 && typeof report.query9084new1.page !== 'undefined' ? report.query9084new1.page : 0,
                count: report && typeof report.query9084new1 !== 'undefined' && report.query9084new1 && typeof report.query9084new1.count !== 'undefined' ? report.query9084new1.count : 10,
            }))
            showToast(NOTI_TYPES.ERROR, error.msg)
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: INVITE_CONFIRM_OTPnew1_SUCCESS, data } }
    function failure(error) { return { type: INVITE_CONFIRM_OTPnew1_FAILD, data: error } }
}

function inviteRenew(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { report } = getState();
        InviteService.invite(payload).then((rs) => {
            dispatch(ReportAction.list9332({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: report && typeof report.query9332 !== 'undefined' && report.query9332 && typeof report.query9332.page !== 'undefined' ? report.query9332.page : 0,
                count: report && typeof report.query9332 !== 'undefined' && report.query9332 && typeof report.query9332.count !== 'undefined' ? report.query9332.count : 10,
            }))
            if (rs.status === 'success') {
                showToast(NOTI_TYPES.SUCCESS, rs.data)
            } else {
                showToast(NOTI_TYPES.ERROR, rs.error.msg)
            }
        }).catch((error) => {
            dispatch(ReportAction.list9332({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: report && typeof report.query9332 !== 'undefined' && report.query9332 && typeof report.query9332.page !== 'undefined' ? report.query9332.page : 0,
                count: report && typeof report.query9332 !== 'undefined' && report.query9332 && typeof report.query9332.count !== 'undefined' ? report.query9332.count : 10,
            }))
            if (error) {
                showToast(NOTI_TYPES.ERROR, error.msg)
            } else {
                showToast(NOTI_TYPES.ERROR, "Đăng ký gói cước không thành công")
            }
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}

function inviteImmediate(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { report } = getState();
        InviteService.invite(payload).then((rs) => {
            dispatch(ReportAction.list9332({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: report && typeof report.query9332 !== 'undefined' && report.query9332 && typeof report.query9332.page !== 'undefined' ? report.query9332.page : 0,
                count: report && typeof report.query9332 !== 'undefined' && report.query9332 && typeof report.query9332.count !== 'undefined' ? report.query9332.count : 10,
            }))
            if (rs.status === 'success') {
                showToast(NOTI_TYPES.SUCCESS, rs.data)
            } else {
                showToast(NOTI_TYPES.ERROR, rs.error.msg)
            }
        }).catch((error) => {
            dispatch(ReportAction.list9332({
                endDate: moment(new Date()).format('DD/MM/YYYY'),
                status: '',
                startDate: moment(new Date()).format('DD/MM/YYYY'),
                user: '',
                page: report && typeof report.query9332 !== 'undefined' && report.query9332 && typeof report.query9332.page !== 'undefined' ? report.query9332.page : 0,
                count: report && typeof report.query9332 !== 'undefined' && report.query9332 && typeof report.query9332.count !== 'undefined' ? report.query9332.count : 10,
            }))
            if (error) {
                showToast(NOTI_TYPES.ERROR, error.msg)
            } else {
                showToast(NOTI_TYPES.ERROR, "Đăng ký gói cước không thành công")
            }
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}