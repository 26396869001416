import {
  ROLE_CLEAR_SELECTED,
  ROLE_CREATE_SUCCESS,
  ROLE_LIST_SUCCESS,
  ROLE_ONE_SUCCESS,
} from 'src/actions/role.acction';

const initState = {
  items: [],
  total: 0,
  selected: {}
}

const roleReducer = (state = initState, action) => {
  switch (action.type) {
    case ROLE_LIST_SUCCESS: {
      return {
        ...state,
        items: action.data.items,
        total: action.data.total,
      }
    }

    case ROLE_ONE_SUCCESS: {
      return {
        ...state,
        selected: action.data
      }
    }

    case ROLE_CREATE_SUCCESS: {
      return {
        ...state,
        list: {
          data: action.data
        }
      }
    }

    case ROLE_CLEAR_SELECTED: {
      return {
        ...state,
        selected: {}
      }
    }
    default: {
      return state;
    }
  }
}
export default roleReducer