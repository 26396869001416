import { Helmet } from 'react-helmet';
import DivisionListResults from 'src/components/division/DivisionListResults';
import DivisionListToolbar from 'src/components/division/DivisionListToolbar';

import {
  Box,
  Container,
} from '@material-ui/core';

const Division = () => (
  <>
    <Helmet>
      <title>Chia data cho các chi nhánh</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <DivisionListToolbar />
        <Box sx={{ pt: 3 }}>
          <DivisionListResults />
        </Box>
      </Container>
    </Box>
  </>
);

export default Division;
