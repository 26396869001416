import {
    useEffect,
    useState,
} from 'react';

import { useDispatch } from 'react-redux';
import { CustomerStatus } from 'src/utils/constants';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from '@material-ui/core';

const CustomerCreateDialog = (props) => {
    const dispatch = useDispatch()

    useEffect(() => {
    }, [])

    const [values, setValues] = useState({
        fullname: '',
        phone: '',
        package: '',
        type: '',
        registerAt: "",
        expiredAt: "",
        packageCycle: 1,
        status: '',
        packageCanRegister: ''
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleCreate = () => {
        // if (items.length > 0 && values.role === '') {
        //     values.role = items[0].id
        // }
        // dispatch(AccountAction.create(values))
        // props.handleClose()
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Thêm khách hàng mới</DialogTitle>
            <DialogContent style={{ paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Số điện thoại"
                            name="phone"
                            onChange={handleChange}
                            type="number"
                            value={values.phone}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Loại thuê bao"
                            name="type"
                            onChange={handleChange}
                            value={values.type}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Gói cước"
                            name="package"
                            onChange={handleChange}
                            required
                            value={values.package}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Ngày đăng ký"
                            name="registerAt"
                            onChange={handleChange}
                            required
                            value={values.registerAt}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Ngày hết hạn"
                            name="expiredAt"
                            onChange={handleChange}
                            required
                            value={values.expiredAt}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Chu kỳ hiện tại"
                            name="packageCycle"
                            onChange={handleChange}
                            required
                            value={values.packageCycle}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Trạng thái"
                            name="status"
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.status}
                            variant="outlined"
                        >
                            {CustomerStatus.map((option) => (
                                <option
                                    key={option.code}
                                    value={option.name}
                                >
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Gói có thể ĐK"
                            name="packageCanRegister"
                            onChange={handleChange}
                            required
                            value={values.packageCanRegister}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={handleCreate}>Tạo</Button>
            </DialogActions>
        </Dialog >
    );
}

export default CustomerCreateDialog;