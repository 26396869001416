import { CauhinhgsmService } from 'src/services/cauhinhgsm.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const CAUHINHGSM_NEW_CARE_SUCCESS = 'CAUHINHGSM:NEW_CARE_SUCCESS'
export const CAUHINHGSM_LIST_SUCCESS = 'CAUHINHGSM:FIND_ALL_SUCCESS'
export const CAUHINHGSM_LIST_FAILD = 'CAUHINHGSM:FIND_ALL_FAILD'
export const CAUHINHGSM_COUNT_ASSIGN_SUCCESS = 'CAUHINHGSM:COUNT_ASSIGN_SUCCESS'
export const CAUHINHGSM_COUNT_ASSIGN_FAILD = 'CAUHINHGSM:COUNT_ASSIGN_FAILD'

export const CAUHINHGSM_CREATE_SUCCESS = 'CAUHINHGSM:CREATE_SUCCESS'
export const CAUHINHGSM_CREATE_FAILD = 'CAUHINHGSM:CREATE_FAILD'

export const CAUHINHGSM_IMPORT_SUCCESS = 'CAUHINHGSM:IMPORT_SUCCESS'
export const CAUHINHGSM_SAVE_SUCCESS = 'CAUHINHGSM:SAVE_SUCCESS'
export const CAUHINHGSM_SAVE_FAILD = 'CAUHINHGSM:SAVE_SUCCESS'

export const CAUHINHGSM_DEL_SUCCESS = 'CAUHINHGSM:DEL_SUCCESS'
export const CAUHINHGSM_DEL_FAILD = 'CAUHINHGSM:DEL_FAILD'

export const CAUHINHGSM_ONE_SUCCESS = 'CAUHINHGSM:FIND_ONE_SUCCESS'
export const CAUHINHGSM_ONE_FAILD = 'CAUHINHGSM:FIND_ONE_FAILD'

export const CAUHINHGSM_HISTORY_LIST_SUCCESS = 'CAUHINHGSM:HISTORY_FIND_ALL_SUCCESS'
export const CAUHINHGSM_HISTORY_LIST_FAILD = 'CAUHINHGSM:HISTORY_FIND_ALL_FAILD'

export const CAUHINHGSM_UPDATE_FILTER = 'CAUHINHGSM:UPDATE_FILTER'

export const CAUHINHGSMCOMLIST_LIST_SUCCESS = 'CAUHINHGSMCOMLIST:FIND_ALL_SUCCESS'
export const CAUHINHGSMCOMLIST_LIST_FAILD = 'CAUHINHGSMCOMLIST:FIND_ALL_FAILD'

export const CauhinhgsmAction = {
    list,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    approve,
    updateFilter,
    batTatLoaiDichVu,
    batTatLoaiDichVuChiTiet,
    xoaMucDaChon,
    findAllByOrder,
    handleSuaPortNhieuMuc,
}

function updateFilter(payload) {
    return { type: CAUHINHGSM_UPDATE_FILTER, data: payload }
}

function handleSuaPortNhieuMuc(idgsm, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { cauhinhgsm } = getState();

            if (payload.yeuCauXuLy == 'refresh') {
                const oldItems = cauhinhgsm.cauhinhgsmcomlist_items;

                let uniqueItems = oldItems;
                for (let i = 0; i < cauhinhgsm.cauhinhgsmcomlist_total; i++) {
                    if (typeof uniqueItems[i] !== 'undefined' && payload.selectedItems.includes(uniqueItems[i].id)) {
                        uniqueItems[i].ban_dau = 0;
                        uniqueItems[i].hien_tai = 0;
                        uniqueItems[i].so_luong_da_ban = 0;
                        uniqueItems[i].so_dien_thoai = null;
                        uniqueItems[i].operator = null;
                        uniqueItems[i].loai_thue_bao = null;
                    }
                }
                
                console.log({
                    items: uniqueItems,
                    total: cauhinhgsm.cauhinhgsmcomlist_total,
                })
                
                dispatch(success({
                    items: uniqueItems,
                    total: cauhinhgsm.cauhinhgsmcomlist_total,
                }));
            } else if (payload.yeuCauXuLy == 'refresh_khi_mo_cong') {
                const oldItems = cauhinhgsm.cauhinhgsmcomlist_items;

                let uniqueItems = oldItems;
                let newSelectedItems = []
                for (let i = 0; i < cauhinhgsm.cauhinhgsmcomlist_total; i++) {
                    if (typeof uniqueItems[i] !== 'undefined' && payload.selectedItems.includes(uniqueItems[i].id) && uniqueItems[i].trang_thai == false) {
                        uniqueItems[i].ban_dau = 0;
                        uniqueItems[i].hien_tai = 0;
                        uniqueItems[i].so_luong_da_ban = 0;
                        uniqueItems[i].so_dien_thoai = null;
                        uniqueItems[i].operator = null;
                        uniqueItems[i].loai_thue_bao = null;
                        newSelectedItems.push(uniqueItems[i].id)
                    }
                }
                
                payload.selectedItems = newSelectedItems;
                payload.yeuCauXuLy = 'refresh'
            }
            
            // Perform your asynchronous operation (e.g., API call)
            CauhinhgsmService.handleSuaPortNhieuMuc(payload)
            .then(response => {
                // Handle the successful response
                // dispatch(success(response));
                console.log(payload.yeuCauXuLy)
                if (payload.yeuCauXuLy != 'refresh' && payload.yeuCauXuLy != 'mo') dispatch(CauhinhgsmAction.findAllByOrder(idgsm, true));
                resolve(response);
            })
            .catch(error => {
                // Handle the error
                // dispatch(failure(error));
                reject(error);
            });
        });
    };
    function success(data) { return { type: CAUHINHGSMCOMLIST_LIST_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSMCOMLIST_LIST_FAILD, error } }
}

function batTatLoaiDichVu(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            CauhinhgsmService.batTatLoaiDichVu(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(CauhinhgsmAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: CAUHINHGSM_CREATE_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSM_CREATE_FAILD, error } }
}

function batTatLoaiDichVuChiTiet(id, id_don_tong, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { cauhinhgsm } = getState();
            let noRefresh = false;

            if (payload.yeu_cau == 'trang_thai') {
                const oldItems = cauhinhgsm.cauhinhgsmcomlist_items;

                let uniqueItems = oldItems;
                for (let i = 0; i < cauhinhgsm.cauhinhgsmcomlist_total; i++) {
                    if (typeof uniqueItems[i] !== 'undefined' && uniqueItems[i].id == id && uniqueItems[i].trang_thai == false) {
                        uniqueItems[i].ban_dau = 0;
                        uniqueItems[i].hien_tai = 0;
                        uniqueItems[i].so_luong_da_ban = 0;
                        uniqueItems[i].so_dien_thoai = null;
                        uniqueItems[i].operator = null;
                        uniqueItems[i].loai_thue_bao = null;
                        uniqueItems[i].trang_thai = true;
                        noRefresh = true;
                        break;
                    }
                }
                
                console.log({
                    items: uniqueItems,
                    total: cauhinhgsm.cauhinhgsmcomlist_total,
                })
                
                dispatch(success({
                    items: uniqueItems,
                    total: cauhinhgsm.cauhinhgsmcomlist_total,
                }));
            }

            // Perform your asynchronous operation (e.g., API call)
            CauhinhgsmService.batTatLoaiDichVuChiTiet(id, payload)
            .then(response => {
                // Handle the successful response
                // dispatch(success(response));

                if (!noRefresh) {
                    dispatch(CauhinhgsmAction.findAllByOrder(id_don_tong));
                }
                
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                // dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: CAUHINHGSMCOMLIST_LIST_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSMCOMLIST_LIST_FAILD, error } }
}

function xoaMucDaChon(payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            CauhinhgsmService.xoaMucDaChon(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(CauhinhgsmAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: CAUHINHGSM_CREATE_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSM_CREATE_FAILD, error } }
}

function approve(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            CauhinhgsmService.approve(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(CauhinhgsmAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: CAUHINHGSM_CREATE_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSM_CREATE_FAILD, error } }
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        CauhinhgsmService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CAUHINHGSM_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSM_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { cauhinhgsm } = getState()
        dispatch(AppAction.appLoading(true));
        CauhinhgsmService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...cauhinhgsm.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CAUHINHGSM_LIST_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSM_LIST_FAILD, error } }
}

function findAllByOrder(id, no_sync = false, not_show_loading_img = false) {
    return (dispatch, getState) => {
        if (!not_show_loading_img) dispatch(AppAction.appLoading(true));
        const { cauhinhgsm } = getState();
        const page = cauhinhgsm && typeof cauhinhgsm.filter !== 'undefined' && cauhinhgsm.filter && typeof cauhinhgsm.filter.page !== 'undefined' ? cauhinhgsm.filter.page : 0;
        const limit = cauhinhgsm && typeof cauhinhgsm.filter !== 'undefined' && cauhinhgsm.filter && typeof cauhinhgsm.filter.limit !== 'undefined' ? cauhinhgsm.filter.limit : 10;
        CauhinhgsmService.findAllByOrder(id, page, limit, {...cauhinhgsm.filter, no_sync}).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                const oldItems = cauhinhgsm.cauhinhgsmcomlist_items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                console.log({
                    items: uniqueItems,
                    total: rs.data.count
                })
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CAUHINHGSMCOMLIST_LIST_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSMCOMLIST_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { cauhinhgsm } = getState();
        const page = cauhinhgsm && typeof cauhinhgsm.filter !== 'undefined' && cauhinhgsm.filter && typeof cauhinhgsm.filter.page !== 'undefined' ? cauhinhgsm.filter.page : 0;
        const limit = cauhinhgsm && typeof cauhinhgsm.filter !== 'undefined' && cauhinhgsm.filter && typeof cauhinhgsm.filter.limit !== 'undefined' ? cauhinhgsm.filter.limit : 10;
        
        return new Promise((resolve, reject) => {
            CauhinhgsmService.findAll(page, limit, cauhinhgsm.filter).then((rs) => {
                if (page === 0) {
                    dispatch(success({
                        items: rs.data.values,
                        total: rs.data.count
                    }));
                } else {
                    const oldItems = cauhinhgsm.items;
                    const rsDataValues = rs.data.values;

                    var uniqueItems = oldItems;
                    for (var i = limit * page; i < limit * page + limit; i++) {
                        if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                            uniqueItems[i] = rsDataValues[i - limit * page];
                        } else if (typeof uniqueItems[i] !== 'undefined') {
                            delete uniqueItems[i];
                        }
                    }
                    
                    dispatch(success({
                        items: uniqueItems,
                        total: rs.data.count
                    }));
                }

                resolve(rs);
            }).catch((error) => {
                dispatch(failure(error));
                reject(error);
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            })
        });
    };
    function success(data) { return { type: CAUHINHGSM_LIST_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSM_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: CAUHINHGSM_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            CauhinhgsmService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(CauhinhgsmAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: CAUHINHGSM_SAVE_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSM_SAVE_FAILD, error } }
}

function create(payload) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            // Perform your asynchronous operation (e.g., API call)
            CauhinhgsmService.create(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(CauhinhgsmAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            });
        });
    };
    function success(data) { return { type: CAUHINHGSM_CREATE_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSM_CREATE_FAILD, error } }
}

function update(id, payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        CauhinhgsmService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(CauhinhgsmAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CAUHINHGSM_CREATE_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSM_CREATE_FAILD, error } }
}

function del(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        CauhinhgsmService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(CauhinhgsmAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CAUHINHGSM_DEL_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSM_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        CauhinhgsmService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CAUHINHGSM_ONE_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSM_ONE_FAILD, error } }
}

function assign(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        CauhinhgsmService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(CauhinhgsmAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { cauhinhgsm } = getState()
        dispatch(AppAction.appLoading(true));
        CauhinhgsmService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...cauhinhgsm.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CAUHINHGSM_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: CAUHINHGSM_HISTORY_LIST_FAILD, error } }
}