import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { CauhinhgsmAction } from 'src/actions/cauhinhgsm.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    Box,
    MenuItem,
    Select as Select1,
    InputAdornment,
    SvgIcon,
    InputLabel,
    TableCell,
} from '@material-ui/core';

import {
    DatePicker,
    LocalizationProvider,
  } from '@mui/x-date-pickers';
  import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';

import numeral from 'numeral';

const CreateEditDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { role } = useSelector(state => state.profile);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.cauhinhgsm)
    const [isOpen, setIsOpen] = useState(false);
    const [ma_nhom, setMaNhom] = useState("")
    const [ten_nhom, setTenNhom] = useState("")
    const [ma_may_tram, setMaMayTram] = useState("")
    const [gioi_han_canh_bao, setGioiHanCanhBao] = useState("0")
    const [gioi_han_off_cong, setGioiHanOffCong] = useState("0")
    const [ban_tien, setBanTien] = useState(false);
    const [nap_sim, setNapSim] = useState(false);
    const [nap_tra_truoc, setNapTraTruoc] = useState(false);
    const [nap_tra_sau, setNapTraSau] = useState(false);
    const [trang_thai, setTrangThai] = useState(false);
    const [auto_imei, setAutoImei] = useState(false);

    const [values, setValues] = useState({
        nha_mang: ['-99'],
    });

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
        dispatch(PermissionAction.list(0, 100))
    }, [])

    useEffect(() => {
        if (props.type === 'EDIT') {
            if (selected) {
                setMaNhom(selected.ma_nhom)
                setTenNhom(selected.ten_nhom)
                setMaMayTram(selected.ma_may_tram)
                setGioiHanCanhBao(selected.gioi_han_canh_bao.toString())
                setGioiHanOffCong(selected.gioi_han_off_cong.toString())
                setBanTien(selected.ban_tien)
                setNapSim(selected.nap_sim)
                setNapTraTruoc(selected.nap_tra_truoc)
                setNapTraSau(selected.nap_tra_sau)
                setTrangThai(selected.trang_thai)
                setAutoImei(selected.auto_imei)
                setValues({
                    ...values,
                    nha_mang: ['-99'],
                })
                if (selected.nha_mang) {
                    let str = selected.nha_mang.toLowerCase();
                    let charArray = str.split(', ');
                    setValues({
                        ...values,
                        nha_mang: charArray,
                    })
                }
            }
        }
    }, [selected])

    const onPressPositive = async () => {
        const edited_gioi_han_canh_bao = gioi_han_canh_bao.replace(/,/g, '');
        const edited_gioi_han_off_cong = gioi_han_off_cong.replace(/,/g, '');

        if (ma_nhom === "") {
            return;
        }
        if (ten_nhom === "") {
            return;
        }
        if (ma_may_tram === "") {
            return;
        }
        if (values.nha_mang.includes('-99')) {
            return;
        }
        if (props.type === 'EDIT') {
            try {
                dispatch(CauhinhgsmAction.update(selected.id, { 
                    ma_nhom: ma_nhom, 
                    ten_nhom: ten_nhom, 
                    ma_may_tram: ma_may_tram,
                    gioi_han_canh_bao: edited_gioi_han_canh_bao,
                    gioi_han_off_cong: edited_gioi_han_off_cong,
                    nha_mang: values.nha_mang,
                    ban_tien: ban_tien,
                    nap_sim: nap_sim,
                    nap_tra_truoc: nap_tra_truoc,
                    nap_tra_sau: nap_tra_sau,
                    trang_thai: trang_thai,
                    auto_imei: auto_imei,
                }))
                resetState();
                props.handleClose();
            } catch (error) {
                console.log('update_error', error)
            }
        } else {
            try {
                const response = await dispatch(CauhinhgsmAction.create({ 
                    ma_nhom: ma_nhom, 
                    ten_nhom: ten_nhom, 
                    ma_may_tram: ma_may_tram,
                    gioi_han_canh_bao: edited_gioi_han_canh_bao,
                    gioi_han_off_cong: edited_gioi_han_off_cong,
                    nha_mang: values.nha_mang,
                    ban_tien: ban_tien,
                    nap_sim: nap_sim,
                    nap_tra_truoc: nap_tra_truoc,
                    nap_tra_sau: nap_tra_sau,
                    trang_thai: trang_thai,
                    auto_imei: auto_imei,
                }))
                resetState();
                props.handleClose();
            } catch (error) {
                console.log('create_error', error)
            }
        }
    }

    const resetState = () => {
        setMaNhom("")
        setTenNhom("")
        setMaMayTram("")
        setGioiHanCanhBao("0")
        setGioiHanOffCong("0")
        setBanTien(false)
        setNapSim(false)
        setNapTraTruoc(false)
        setNapTraSau(false)
        setTrangThai(false)
        setAutoImei(false)
        setValues({
            ...values,
            nha_mang: ['-99'],
        })
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    const handleChange = (event) => {
        if (event.target.name == 'nha_mang') {
            setIsOpen(false);
            let targetValues = event.target.value;
            targetValues = targetValues.filter((value) => value != '-99')
            if (targetValues.length == 0) targetValues.push('-99');
            setValues({
                ...values,
                [event.target.name]: targetValues,
            });
        } else {
            setValues({
                ...values,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleClickChonNhaMang = async () => {
        setIsOpen(true);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={props.open} onClose={props.handleClose}>
                <DialogTitle>{props.type === 'EDIT' ? "Cập nhật bản ghi" : "Tạo bản ghi mới"}</DialogTitle>
                <DialogContent style={{ width: 600, paddingTop: 20 }}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Mã nhóm"
                                name="ma_nhom"
                                onChange={(e) => setMaNhom(e.target.value)}
                                required
                                value={ma_nhom}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Tên nhóm"
                                name="ten_nhom"
                                onChange={(e) => setTenNhom(e.target.value)}
                                required
                                value={ten_nhom}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Mã máy trạm"
                                name="ma_may_tram"
                                onChange={(e) => setMaMayTram(e.target.value)}
                                required
                                value={ma_may_tram}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="GH cảnh báo"
                                name="gioi_han_canh_bao"
                                onChange={(e) => { let value = e.target.value; let raw_value = e.target.value.replace(/,/g, ''); value = numeral(value).format('0,0'); setGioiHanCanhBao(value); }}
                                required
                                value={gioi_han_canh_bao}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="GH off cổng"
                                name="gioi_han_off_cong"
                                onChange={(e) => { let value = e.target.value; let raw_value = e.target.value.replace(/,/g, ''); value = numeral(value).format('0,0'); setGioiHanOffCong(value); }}
                                required
                                value={gioi_han_off_cong}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Select1
                                style={{ height: 56, marginTop: 6, marginBottom: 6, width: "100%", marginLeft: 0 }}
                                name="nha_mang"
                                value={values.nha_mang || ['-99']}
                                onChange={handleChange}
                                onOpen={handleClickChonNhaMang}
                                variant="outlined"
                                open={isOpen}
                                multiple
                            >
                                <MenuItem value="-99">Nhà mạng...</MenuItem>
                                <MenuItem value="mobi" style={{backgroundColor: values.nha_mang.includes('mobi') ? 'yellow' : 'inherit'}}>MOBI</MenuItem>
                                <MenuItem value="vina" style={{backgroundColor: values.nha_mang.includes('vina') ? 'yellow' : 'inherit'}}>VINA</MenuItem>
                                <MenuItem value="viettel" style={{backgroundColor: values.nha_mang.includes('viettel') ? 'yellow' : 'inherit'}}>VIETTEL</MenuItem>
                            </Select1>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            style={{marginTop: 15, marginLeft: 25}}
                        >
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Typography
                                color="blue"
                                variant="body1"
                                >
                                    Dịch vụ khác
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TableCell padding="checkbox" style={{width: '30px'}}>
                                    <Checkbox
                                        checked={ban_tien ? true : false}
                                        onChange={(event) => setBanTien(ban_tien ? false : true)}
                                        value={ban_tien ? 1 : 2}
                                        style={{width: '20px'}}
                                    />
                                </TableCell>
                                <TableCell style={{padding: 0}}>
                                    Bắn tiền
                                </TableCell>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TableCell padding="checkbox" style={{width: '30px'}}>
                                    <Checkbox
                                        checked={nap_sim ? true : false}
                                        onChange={(event) => setNapSim(nap_sim ? false : true)}
                                        value={nap_sim ? 1 : 2}
                                        style={{width: '20px'}}
                                    />
                                </TableCell>
                                <TableCell style={{padding: 0}}>
                                    Nạp SIM
                                </TableCell>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TableCell padding="checkbox" style={{width: '30px'}}>
                                    <Checkbox
                                        checked={nap_tra_truoc ? true : false}
                                        onChange={(event) => setNapTraTruoc(nap_tra_truoc ? false : true)}
                                        value={nap_tra_truoc ? 1 : 2}
                                        style={{width: '20px'}}
                                    />
                                </TableCell>
                                <TableCell style={{padding: 0}}>
                                    Nạp trả trước
                                </TableCell>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TableCell padding="checkbox" style={{width: '30px'}}>
                                    <Checkbox
                                        checked={nap_tra_sau ? true : false}
                                        onChange={(event) => setNapTraSau(nap_tra_sau ? false : true)}
                                        value={nap_tra_sau ? 1 : 2}
                                        style={{width: '20px'}}
                                    />
                                </TableCell>
                                <TableCell style={{padding: 0}}>
                                    Nạp trả sau
                                </TableCell>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            style={{marginTop: 15, marginLeft: 25}}
                        >
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Typography
                                color="blue"
                                variant="body1"
                                >
                                    Cài đặt khác
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TableCell padding="checkbox" style={{width: '30px'}}>
                                    <Checkbox
                                        checked={auto_imei ? true : false}
                                        onChange={(event) => setAutoImei(auto_imei ? false : true)}
                                        value={auto_imei ? 1 : 2}
                                        style={{width: '20px'}}
                                    />
                                </TableCell>
                                <TableCell style={{padding: 0}}>
                                    AUTO IMEI
                                </TableCell>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TableCell padding="checkbox" style={{width: '30px'}}>
                                    <Checkbox
                                        checked={trang_thai ? true : false}
                                        onChange={(event) => setTrangThai(trang_thai ? false : true)}
                                        value={trang_thai ? 1 : 2}
                                        style={{width: '20px'}}
                                    />
                                </TableCell>
                                <TableCell style={{padding: 0}}>
                                    Hoạt động
                                </TableCell>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Huỷ</Button>
                    <Button onClick={onPressPositive}>{props.type === 'EDIT' ? "Cập nhật" : "Tạo"}</Button>
                </DialogActions>
            </Dialog >
        </LocalizationProvider>
    );
}

export default CreateEditDialog;