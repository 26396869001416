import { useState } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { SellingStates } from 'src/utils/constants';

import {
  Box,
  Button,
  TextField,
} from '@material-ui/core';

const InvitaionSelling = (props) => {
  const dispatch = useDispatch()
  const { selling } = useSelector(state => state.invite)

  const [values, setValues] = useState({
    package: '',
    phone: '',
    requestAt: new Date(),
    state: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <form {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <TextField
          fullWidth
          label="Gói cước"
          margin="normal"
          name="package"
          onChange={handleChange}
          required
          value={values.package}
          variant="outlined"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          label="Số điện thoại"
          margin="normal"
          name="phone"
          onChange={handleChange}
          required
          value={values.phone}
          variant="outlined"
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          label="Ngày yêu cầu"
          margin="normal"
          name="otp"
          onChange={handleChange}
          value={values.otp}
          variant="outlined"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          label="Trạng thái"
          name="status"
          onChange={handleChange}
          margin="normal"
          select
          SelectProps={{ native: true }}
          value={values.status}
          variant="outlined"
        >
          {SellingStates.map((option) => (
            <option
              key={option.code}
              value={option.name}
            >
              {option.name}
            </option>
          ))}
        </TextField>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <Button
          style={{ marginTop: 16, height: 56, width: 200 }}
          color="primary"
          variant="contained"
        >
          Đăng ký
        </Button>
      </Box>
    </form>
  );
};

export default InvitaionSelling;
