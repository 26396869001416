import { useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  matchPath,
  NavLink as RouterLink,
  useLocation,
} from 'react-router-dom';
import { isGrantPermission } from 'src/utils/utils';

import {
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import { emitter } from 'src/utils/emitter';

const NavItem = ({
  href,
  icon: Icon,
  title,
  children,
  ...rest
}) => {
  const location = useLocation();
  const active = href ? !!matchPath({
    path: href,
    end: false
  }, location.pathname) : false;
  const { permissions } = useSelector(state => state.profile)
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const addTab = (component = null) => {
    if (component != null) {
      emitter.emit("add_tab", component)
    }
  }

  if (!children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'flex',
          py: 0
        }}
        {...rest}
      >
        <Button
          component={RouterLink}
          sx={{
            color: 'text.secondary',
            fontWeight: 'medium',
            justifyContent: 'flex-start',
            letterSpacing: 0,
            py: 1.25,
            textTransform: 'none',
            width: '100%',
            ...(active && {
              color: 'primary.main'
            }),
            '& svg': {
              mr: 1
            }
          }}
          to={href}
        >
          {Icon && (
            <Icon size="20" />
          )}
          <span>
            {title}
          </span>
        </Button>
      </ListItem>
    );
  } else {
    return [
      (
        <ListItem
          disableGutters
          sx={{
            display: 'flex',
            py: 0
          }}
          {...rest}
        >
          <Button
            sx={{
              color: 'text.secondary',
              fontWeight: 'medium',
              justifyContent: 'flex-start',
              letterSpacing: 0,
              py: 1.25,
              textTransform: 'none',
              width: '100%',
              ...(active && {
                color: 'primary.main'
              }),
              '& svg': {
                mr: 1
              }
            }}
            onClick={handleClick}
          >
            {Icon && (
              <Icon size="20" />
            )}
            {/* <ListItemText primary={title} /> */}
            <span>
              {title}
            </span>
            {open ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </ListItem>
      ),
      (
        <Collapse key={`collapse-${title}`} in={open} timeout="auto" >
          <List component="div" disablePadding sx={{ pl: 2 }}>
            {children.map((item) => isGrantPermission(permissions, item.permissions) && (
              <NavItem
                href={typeof item.component !== 'undefined' ? '#' : item.href}
                onClick={() => addTab(typeof item.component !== 'undefined' ? item.component : null)}
                key={`children-${item.title}`}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </List>
        </Collapse>
      )
    ]
  }
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
