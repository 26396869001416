import {
  SYSTEMACCOUNT_COUNT_ASSIGN_SUCCESS,
  SYSTEMACCOUNT_HISTORY_LIST_SUCCESS,
  SYSTEMACCOUNT_IMPORT_SUCCESS,
  SYSTEMACCOUNT_LIST_SUCCESS,
  SYSTEMACCOUNT_NEW_CARE_SUCCESS,
  SYSTEMACCOUNT_ONE_SUCCESS,
} from 'src/actions/systemaccount.action';
import { LOOKUP_CLEANER } from 'src/actions/lookup.action';

const initState = {
  items: [],
  total: 0,
  temp: [],
  selected: undefined,
  countAssign: 0,
  history: [],
  totalHistory: 0,
  newCare: undefined,
  canCare: 0
}

const systemaccountReducer = (state = initState, action) => {
  switch (action.type) {
    case SYSTEMACCOUNT_LIST_SUCCESS: {
      return {
        ...state,
        items: action.data.items,
        total: action.data.total,
      }
    }
    case SYSTEMACCOUNT_ONE_SUCCESS: {
      return {
        ...state,
        selected: action.data
      }
    }
    case SYSTEMACCOUNT_NEW_CARE_SUCCESS: {
      return {
        ...state,
        newCare: action.data.newCare,
        canCare: action.data.canCare,
      }
    }
    case SYSTEMACCOUNT_IMPORT_SUCCESS: {
      return {
        ...state,
        temp: action.data,
      }
    }
    case SYSTEMACCOUNT_COUNT_ASSIGN_SUCCESS: {
      return {
        ...state,
        countAssign: action.data,
      }
    }
    case SYSTEMACCOUNT_HISTORY_LIST_SUCCESS: {
      return {
        ...state,
        history: action.data.items,
        totalHistory: action.data.total,
      }
    }
    case LOOKUP_CLEANER: {
      return initState
    }
    default: {
      return state;
    }
  }
}
export default systemaccountReducer