import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { NapmymobifoneAction } from 'src/actions/napmymobifone.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    Box,
    MenuItem,
    Select as Select1,
    InputAdornment,
    SvgIcon,
    InputLabel,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Table,
} from '@material-ui/core';

import {
    DatePicker,
    LocalizationProvider,
  } from '@mui/x-date-pickers';
  import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';

import numeral from 'numeral';

import { showToast } from 'src/utils/common';

const CreateEditDialogDanhSachTheNap = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { role } = useSelector(state => state.profile);
    const { items } = useSelector(state => state.permission)
    const { selected, selectedChiTiet } = useSelector(state => state.napmymobifone)
    const [isOpen, setIsOpen] = useState(false);
    const [boi_so, setBoiSo] = useState(['-99'])

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
        dispatch(PermissionAction.list(0, 100))
    }, [])

    useEffect(() => {
        // if (props.type === 'EDIT') {
        //     if (props.type === 'EDIT') {
        //         if (selected && selected.mobidanhsachdonchitiets && selected.mobidanhsachdonchitiets.length > 0) {
        //             if (selected.mobidanhsachdonchitiets[0].boi_so) {
        //                 setBoiSo(parseStringToString(selected.mobidanhsachdonchitiets[0].boi_so))
        //             }
        //         }
        //     }
        // }

        //console.log(props.danhsachthenap, selectedChiTiet.quanlythenaps)
    }, [selectedChiTiet])

    const parseStringToString = (stringBoiSo) => {
        if (!stringBoiSo.includes(',')) {
            return [stringBoiSo]
        } else {
            const str = stringBoiSo;
            const arr = str.split(", ");
            return arr;
        }
    }

    const onPressPositive = async () => {
        if (boi_so.includes('-99')) {
            return;
        }
        if (props.type === 'EDIT') {
            dispatch(NapmymobifoneAction.updateBoiSo(selected.id, { 
                boi_so: boi_so, 
            }))
            resetState();
            props.handleClose();
        }
    }

    const resetState = () => {
        setBoiSo(['-99'])
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    const handleChange = (event) => {
        if (event.target.name == 'boi_so') {
            setIsOpen(false);
            let targetValues = event.target.value;
            targetValues = targetValues.filter((value) => value != '-99')
            if (targetValues.length == 0) targetValues.push('-99');
            setBoiSo(targetValues);
        } else {
            
        }
    };

    const handleClickChonBoiSo = async () => {
        setIsOpen(true);
    }

    const parseRealPhoneNumber = (msisdn) => {
        let phone = msisdn.slice(-9);
        let firstChar = phone.substring(0, 1);
        if (firstChar != '3' && firstChar != '7' && firstChar != '8' && firstChar != '9') {
            return `1${phone}`;
        }
        return phone;
    }

    const copyToClipboard = (content) => {
        navigator.clipboard.writeText(content)
          .then(() => {
            console.log('String copied to clipboard:', content);
            //showToast('success', `Đã copy: ${content}`)
          })
          .catch((error) => {
            console.error('Unable to copy string:', error);
            showToast('error', `Lỗi không thể copy: ${content}`)
          });
      }

    const showNgayXuLy = (ngayTao, thoiGianXyLy) => {
        const datetime = new Date(ngayTao);
        datetime.setSeconds(datetime.getSeconds() + thoiGianXyLy);
        return moment(datetime).format('DD/MM/YYYY - HH:mm:ss')
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            {selectedChiTiet && <>
                <DialogTitle>
                    {`Danh sách thẻ nạp [0${parseRealPhoneNumber(selectedChiTiet.thue_bao)}]`}
                </DialogTitle>
                <DialogContent style={{ width: 600, maxWidth: '80vw', paddingTop: 20 }}>
                    <Box
                        style={{marginBottom: '25px'}}
                    >
                        <Table style={{marginTop: 15}}>
                            <TableHead>
                            <TableRow style={{background: 'red'}}>
                                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                    #
                                </TableCell>
                                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                    Mã thẻ
                                </TableCell>
                                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                    Số seri
                                </TableCell>
                                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                    Mệnh giá
                                </TableCell>
                                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                                    Ngày thực hiện
                                </TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedChiTiet.quanlythenaps && selectedChiTiet.quanlythenaps.map((item, index) => (
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <Button style={{padding: 0, color: 'blue'}} onClick={() => copyToClipboard(item.ma_the)}>
                                                {item.ma_the.substring(0, 3) + '***' + item.ma_the.substring(item.ma_the.length - 3)}
                                            </Button>
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            {item.seri}
                                        </TableCell>
                                        <TableCell style={{ fontWeight: 'bold', color: 'red', textAlign: 'center' }}>
                                            {numeral(item.gia_thuc).format('0,0')}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            {showNgayXuLy(item.createdAt, item.thoi_gian_xu_ly)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>    
                    </Box>
                </DialogContent>
            </>}
            <DialogActions>
                <Button onClick={props.handleClose}>Đóng</Button>
                {/* <Button onClick={onPressPositive}>OK</Button> */}
            </DialogActions>
        </Dialog >
    );
}

export default CreateEditDialogDanhSachTheNap;