import { NapmymobifoneService } from 'src/services/napmymobifone.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const NAPMYMOBIFONE_NEW_CARE_SUCCESS = 'NAPMYMOBIFONE:NEW_CARE_SUCCESS'
export const NAPMYMOBIFONE_LIST_SUCCESS = 'NAPMYMOBIFONE:FIND_ALL_SUCCESS'
export const NAPMYMOBIFONE_LIST_FAILD = 'NAPMYMOBIFONE:FIND_ALL_FAILD'
export const NAPMYMOBIFONE_COUNT_ASSIGN_SUCCESS = 'NAPMYMOBIFONE:COUNT_ASSIGN_SUCCESS'
export const NAPMYMOBIFONE_COUNT_ASSIGN_FAILD = 'NAPMYMOBIFONE:COUNT_ASSIGN_FAILD'

export const NAPMYMOBIFONE_CREATE_SUCCESS = 'NAPMYMOBIFONE:CREATE_SUCCESS'
export const NAPMYMOBIFONE_CREATE_FAILD = 'NAPMYMOBIFONE:CREATE_FAILD'

export const NAPMYMOBIFONECHITIET_CREATE_SUCCESS = 'NAPMYMOBIFONECHITIET:CREATE_SUCCESS'
export const NAPMYMOBIFONECHITIET_CREATE_FAILD = 'NAPMYMOBIFONECHITIET:CREATE_FAILD'

export const NAPMYMOBIFONE_IMPORT_SUCCESS = 'NAPMYMOBIFONE:IMPORT_SUCCESS'
export const NAPMYMOBIFONE_SAVE_SUCCESS = 'NAPMYMOBIFONE:SAVE_SUCCESS'
export const NAPMYMOBIFONE_SAVE_FAILD = 'NAPMYMOBIFONE:SAVE_SUCCESS'

export const NAPMYMOBIFONE_DEL_SUCCESS = 'NAPMYMOBIFONE:DEL_SUCCESS'
export const NAPMYMOBIFONE_DEL_FAILD = 'NAPMYMOBIFONE:DEL_FAILD'

export const NAPMYMOBIFONE_ONE_SUCCESS = 'NAPMYMOBIFONE:FIND_ONE_SUCCESS'
export const NAPMYMOBIFONE_ONE_FAILD = 'NAPMYMOBIFONE:FIND_ONE_FAILD'

export const NAPMYMOBIFONECHITIET_ONE_SUCCESS = 'NAPMYMOBIFONECHITIET:FIND_ONE_SUCCESS'
export const NAPMYMOBIFONECHITIET_ONE_FAILD = 'NAPMYMOBIFONECHITIET:FIND_ONE_FAILD'

export const NAPMYMOBIFONE_HISTORY_LIST_SUCCESS = 'NAPMYMOBIFONE:HISTORY_FIND_ALL_SUCCESS'
export const NAPMYMOBIFONE_HISTORY_LIST_FAILD = 'NAPMYMOBIFONE:HISTORY_FIND_ALL_FAILD'

export const NAPMYMOBIFONE_UPDATE_FILTER = 'NAPMYMOBIFONE:UPDATE_FILTER'

export const NAPMYMOBIFONE_UPDATE_FILTER_DON_CHO_HE_THONG = 'NAPMYMOBIFONE:UPDATE_FILTER_DON_CHO_HE_THONG'

export const NAPMYMOBIFONECHITIET_LIST_SUCCESS = 'NAPMYMOBIFONECHITIET:FIND_ALL_SUCCESS'
export const NAPMYMOBIFONECHITIET_LIST_FAILD = 'NAPMYMOBIFONECHITIET:FIND_ALL_FAILD'

export const NAPMYMOBIFONE_LIST_EXPORT_SUCCESS = 'NAPMYMOBIFONE:FIND_ALL_EXPORT_SUCCESS'
export const NAPMYMOBIFONE_LIST_EXPORT_FAILD = 'NAPMYMOBIFONE:FIND_ALL_EXPORT_FAILD'

export const NAPMYMOBIFONE_THONG_KE_SUCCESS = 'NAPMYMOBIFONE:THONG_KE_SUCCESS'
export const NAPMYMOBIFONE_THONG_KE_FAILD = 'NAPMYMOBIFONE:THONG_KE_FAILD'

export const NAPMYMOBIFONE_DON_CHO_HE_THONG_SUCCESS = 'NAPMYMOBIFONE:DON_CHO_HE_THONG_SUCCESS'
export const NAPMYMOBIFONE_DON_CHO_HE_THONG_FAILD = 'NAPMYMOBIFONE:DON_CHO_HE_THONG_FAILD'

export const NapmymobifoneAction = {
    doiGiaoDien,
    list,
    findAllByOrder,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    findOneChiTietDon,
    approve,
    setTrangThai,
    updateFilter,
    updateFilterDonchohethong,
    listExport,
    chayTiep,
    chayTiepChiTiet,
    daNap,
    daNapChiTiet,
    huyDon,
    huyDonChiTiet,
    tamDung,
    tamDungChiTiet,
    choNap,
    choNapChiTiet,
    dangNap,
    dangNapChiTiet,
    thatBai,
    thatBaiChiTiet,
    hoanThanh,
    batDauDon,
    batDauDonChiTiet,
    duaDon,
    duaDonChiTiet,
    thongKe,
    donChoHeThong,
    guiOtp,
    nhapOtp,
    guiOtpDonTong,
    nhapOtpDonTong,
    updateBoiSo,
    updateBoiSoChiTiet,
    handleSuaPortNhieuMuc,
}

function updateFilter(payload) {
    return { type: NAPMYMOBIFONE_UPDATE_FILTER, data: payload }
}

function updateFilterDonchohethong(payload) {
    return { type: NAPMYMOBIFONE_UPDATE_FILTER_DON_CHO_HE_THONG, data: payload }
}

function doiGiaoDien() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.doiGiaoDien({type: 'old'})
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                window.location.reload();
            });
        });
    };
}

function handleSuaPortNhieuMuc(payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { cauhinhgsm } = getState();
            
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.handleSuaPortNhieuMuc(payload)
            .then(response => {
                // Handle the successful response
                // dispatch(success(response));
                console.log(payload.yeuCauXuLy)
                dispatch(NapmymobifoneAction.list());
                resolve(response);
            })
            .catch(error => {
                // Handle the error
                // dispatch(failure(error));
                reject(error);
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_LIST_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_LIST_FAILD, error } }
}

function guiOtp(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.guiOtp(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(NapmymobifoneAction.findAllByOrder(id_don_tong));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function nhapOtp(id, id_don_tong, otp) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.nhapOtp(id, otp)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(NapmymobifoneAction.findAllByOrder(id_don_tong));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function guiOtpDonTong(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.guiOtp(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function nhapOtpDonTong(id, otp) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.nhapOtp(id, otp)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function listExport() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { napmymobifone } = getState();

            const { filter } = napmymobifone;

            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.listExport(filter)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    }
    function success(data) { return { type: NAPMYMOBIFONE_LIST_EXPORT_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_LIST_EXPORT_FAILD, error } }
}

function thongKe(operator = null) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { napmymobifone } = getState();
            const { filter } = napmymobifone;
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.thongKe({...filter, operator})
            .then(response => {
                // Handle the successful response
                dispatch(success({
                    items: response.data.values,
                }));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_THONG_KE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_THONG_KE_FAILD, error } }
}

function donChoHeThong(operator = null) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { napmymobifone } = getState();
        const { donchohethongfilter: filter } = napmymobifone;
        const page = typeof filter.donchohethongpage !== 'undefined' ? filter.donchohethongpage : 0;
        const limit = typeof filter.donchohethonglimit !== 'undefined' ? filter.donchohethonglimit : 10;
        NapmymobifoneService.donChoHeThong({...filter, operator}).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            } else {
                const oldItems = napmymobifone.donchohethongitems;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: NAPMYMOBIFONE_DON_CHO_HE_THONG_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_DON_CHO_HE_THONG_FAILD, error } }
}

function chayTiep(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.chayTiep(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function daNap(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.daNap(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function huyDon(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.huyDon(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function tamDung(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.tamDung(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function choNap(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.choNap(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function dangNap(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.dangNap(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function thatBai(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.thatBai(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function hoanThanh(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.hoanThanh(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function batDauDon(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.batDauDon(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function duaDon(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.duaDon(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function updateBoiSo(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.updateBoiSo(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function updateBoiSoChiTiet(id, id_don_tong, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.updateBoiSoChiTiet(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(NapmymobifoneAction.findAllByOrder(id_don_tong));
                dispatch(NapmymobifoneAction.list())
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function duaDonChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.duaDonChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(NapmymobifoneAction.findAllByOrder(id_don_tong));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function batDauDonChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.batDauDonChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(NapmymobifoneAction.findAllByOrder(id_don_tong));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function chayTiepChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.chayTiepChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(NapmymobifoneAction.findAllByOrder(id_don_tong));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONECHITIET_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONECHITIET_CREATE_FAILD, error } }
}

function daNapChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.daNapChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(NapmymobifoneAction.findAllByOrder(id_don_tong));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONECHITIET_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONECHITIET_CREATE_FAILD, error } }
}

function huyDonChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.huyDonChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(NapmymobifoneAction.findAllByOrder(id_don_tong));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONECHITIET_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONECHITIET_CREATE_FAILD, error } }
}

function tamDungChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.tamDungChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(NapmymobifoneAction.findAllByOrder(id_don_tong));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONECHITIET_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONECHITIET_CREATE_FAILD, error } }
}

function choNapChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.choNapChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(NapmymobifoneAction.findAllByOrder(id_don_tong));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function dangNapChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.dangNapChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(NapmymobifoneAction.findAllByOrder(id_don_tong));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function thatBaiChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.thatBaiChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                //dispatch(NapmymobifoneAction.findAllByOrder(id_don_tong));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function approve(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.approve(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function setTrangThai(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.setTrangThai(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        NapmymobifoneService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: NAPMYMOBIFONE_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { napmymobifone } = getState()
        dispatch(AppAction.appLoading(true));
        NapmymobifoneService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...napmymobifone.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: NAPMYMOBIFONE_LIST_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_LIST_FAILD, error } }
}

function findAllByOrder(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { napmymobifone } = getState();
        const page = napmymobifone && typeof napmymobifone.filter !== 'undefined' && napmymobifone.filter && typeof napmymobifone.filter.page !== 'undefined' ? napmymobifone.filter.page : 0;
        const limit = napmymobifone && typeof napmymobifone.filter !== 'undefined' && napmymobifone.filter && typeof napmymobifone.filter.limit !== 'undefined' ? napmymobifone.filter.limit : 10;
        NapmymobifoneService.findAllByOrder(id, page, limit, napmymobifone.filter).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            } else {
                const oldItems = napmymobifone.napmymobifonechitiet_items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: NAPMYMOBIFONECHITIET_LIST_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONECHITIET_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { napmymobifone } = getState();
        const page = napmymobifone && typeof napmymobifone.filter !== 'undefined' && napmymobifone.filter && typeof napmymobifone.filter.page !== 'undefined' ? napmymobifone.filter.page : 0;
        const limit = napmymobifone && typeof napmymobifone.filter !== 'undefined' && napmymobifone.filter && typeof napmymobifone.filter.limit !== 'undefined' ? napmymobifone.filter.limit : 10;
        NapmymobifoneService.findAll(page, limit, napmymobifone.filter).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            } else {
                const oldItems = napmymobifone.items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: NAPMYMOBIFONE_LIST_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: NAPMYMOBIFONE_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            NapmymobifoneService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(NapmymobifoneAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: NAPMYMOBIFONE_SAVE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_SAVE_FAILD, error } }
}

function create(payload) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            // Perform your asynchronous operation (e.g., API call)
            NapmymobifoneService.create(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(NapmymobifoneAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            });
        });
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function update(id, payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        NapmymobifoneService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(NapmymobifoneAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: NAPMYMOBIFONE_CREATE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_CREATE_FAILD, error } }
}

function del(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        NapmymobifoneService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(NapmymobifoneAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: NAPMYMOBIFONE_DEL_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        NapmymobifoneService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: NAPMYMOBIFONE_ONE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_ONE_FAILD, error } }
}

function findOneChiTietDon(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        NapmymobifoneService.findOneChiTietDon(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: NAPMYMOBIFONECHITIET_ONE_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONECHITIET_ONE_FAILD, error } }
}

function assign(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        NapmymobifoneService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(NapmymobifoneAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { napmymobifone } = getState()
        dispatch(AppAction.appLoading(true));
        NapmymobifoneService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...napmymobifone.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: NAPMYMOBIFONE_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: NAPMYMOBIFONE_HISTORY_LIST_FAILD, error } }
}