import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const ProfileService = {

  findMyBrands: () => {
    return axios.get(`/api/v1/users/me/brands`, axiosOption())
      .then((rs) => {
        return handleResponse(rs)
      }).catch((error) => {
        return handleResponse(error.response)
      })
  },

  findLeaders: (id) => {
    return axios.get(`/api/v1/users/${id}/leaders`, axiosOption())
      .then((rs) => {
        return handleResponse(rs)
      }).catch((error) => {
        return handleResponse(error.response)
      })
  },

  findSaleman: (query) => {
    return axios.get(`/api/v1/users/saleman?${toQuery(query)}`, axiosOption())
      .then((rs) => {
        return handleResponse(rs)
      }).catch((error) => {
        return handleResponse(error.response)
      })
  },
}