import {
    useEffect,
    useState,
} from 'react';

import { useDispatch } from 'react-redux';
import { AccountAction } from 'src/actions/account.acction';
import { RoleAction } from 'src/actions/role.acction';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';

const AccountResetPasswordDialog = (props) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(RoleAction.list(0, 100))
    }, [])

    const [values, setValues] = useState({
        password: '',
        rePassword: '',
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const resetData = () => {
        setValues({
            password: '',
            rePassword: '',
        })
    }

    const handleResetPassword = () => {
        dispatch(AccountAction.resetPassword(props.account.id, values))
        resetData()
        props.handleClose()
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Cấp lại mật khẩu người dùng</DialogTitle>
            <DialogContent style={{ paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <Typography>
                            {`Cấp lại mật khẩu cho: `} <strong>{`${props.account.fullname} (${props.account.username})`}</strong>
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Mật khẩu mới"
                            name="password"
                            onChange={handleChange}
                            required
                            type="password"
                            value={values.password}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Nhập lại mật khẩu"
                            name="rePassword"
                            onChange={handleChange}
                            required
                            type="password"
                            value={values.rePassword}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button
                    disabled={!values.rePassword || !values.password}
                    onClick={handleResetPassword}>Xác nhận</Button>
            </DialogActions>
        </Dialog >
    );
}

export default AccountResetPasswordDialog;