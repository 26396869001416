import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const SettingService = {

  create: (payload) => {
    return axios.post('/api/v1/setting', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  update: (key, payload) => {
    return axios.put(`/api/v1/setting/${key}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  findByKey: (key) => {
    return axios.get(`/api/v1/setting/${key}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },
}