import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import{  NapmymobifoneActionOld } from 'src/actions/napmymobifoneold.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getBlacklistStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Select as Select1,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';

import Select from 'react-select';

import { Link } from 'react-router-dom';

//import CreateEditDialog from './CreateEditDialog';

import { showToast } from 'src/utils/common';

import numeral from 'numeral';

import { Search as SearchIcon } from 'react-feather';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import CreateEditDialogBoiSo from './../NapMyMobifone-detail/CreateEditDialogBoiSo';
import CreateEditDialogSelectChannel from './CreateEditDialogSelectChannel';
import CreateEditDialogTaoDon from './CreateEditDialogTaoDon';
import CreateEditDialogThongKeUuTien from './CreateEditDialogThongKeUuTien';
import { SettingAction } from 'src/actions/setting.action';

import { useStyles } from '../RotateStyles';
import { Settings, Download, Cached } from '@material-ui/icons';
import { AccountAction } from 'src/actions/account.acction';

const NapMyMobifoneDetailOld = ({ ...rest }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [openSelectChannel, setOpenSelectChannel] = useState(false);
  const [openTaoDon, setOpenTaoDon] = useState(false);
  const [openThongKeUuTien, setOpenThongKeUuTien] = useState(false);
  const [openThongKeUuTienOperator, setOpenThongKeUuTienOperator] = useState(false);
  const [selectedBlacklistIds, setSelectedBlacklistIds] = useState([]);
  const { items, total, filter, tong_can_nap, tong_da_nap, tong_huy_nap, tong_that_bai, tong_ton_nap } = useSelector(state => state.napmymobifone);
  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const { info, permissions, role } = useSelector(state => state.profile)
  const [mucDuaCaoNhatMobi, setMucDuaCaoNhatMobi] = useState(0);
  const [mucDuaCaoNhatVina, setMucDuaCaoNhatVina] = useState(0);
  const [mucDuaCaoNhatViettel, setMucDuaCaoNhatViettel] = useState(0);
  const [giaTriMucDuaCaoNhatMobi, setGiaTriMucDuaCaoNhatMobi] = useState(0);
  const [giaTriMucDuaCaoNhatVina, setGiaTriMucDuaCaoNhatVina] = useState(0);
  const [giaTriMucDuaCaoNhatViettel, setGiaTriMucDuaCaoNhatViettel] = useState(0);
  const [openEditBoiSo, setOpenEditBoiSo] = useState(false);
  const { isLoading } = useSelector(state => state.app);
  const [otps, setOtps] = useState({});

  const [values, setValues] = useState({
    username: "",
    phone: "",
    ma_don: "",
    kenh_yeu_cau: "-99",
    nha_mang: "-99",
    trang_thai: "-99",
    startDate: filter ? dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')) : '',
    endDate: filter ? dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')) : '',
  });

  const [moduleBanTien, batTatModuleBanTienValue] = useState({});
  const { items: account_items, total: account_total } = useSelector(state => state.account)

  const allOption = { username: '', fullname: 'Tất cả', role: { priority: 0 } };
  const options = [allOption, ...account_items];
  
  useEffect(() => {
      dispatch(AccountAction.listNoLimit());
      async function fetchSettingModuleBanTien() {
          const moduleBanTien_res = await dispatch(SettingAction.findByKey('bat_tat_module_ban_tien'))
          let moduleBanTien_value = JSON.parse(moduleBanTien_res.data.value);
          moduleBanTien_value = {
            topup: moduleBanTien_value.topup == '1' ? 1 : 2,
            tkc: moduleBanTien_value.tkc == '1' ? 1 : 2, 
            dv: moduleBanTien_value.dv == '1' ? 1 : 2,
            bc: moduleBanTien_value.bc == '1' ? 1 : 2,
            napho: moduleBanTien_value.napho == '1' ? 1 : 2,
            mymobifone: moduleBanTien_value.mymobifone == '1' ? 1 : 2,
            gsmmobifone: moduleBanTien_value.gsmmobifone == '1' ? 1 : 2,

            topup_vina: moduleBanTien_value.topup_vina == '1' ? 1 : 2,
            tkc_vina: moduleBanTien_value.tkc_vina == '1' ? 1 : 2, 
            dv_vina: moduleBanTien_value.dv_vina == '1' ? 1 : 2,
            bc_vina: moduleBanTien_value.bc_vina == '1' ? 1 : 2,

            topup_viettel: moduleBanTien_value.topup_viettel == '1' ? 1 : 2,
            tkc_viettel: moduleBanTien_value.tkc_viettel == '1' ? 1 : 2, 
            dv_viettel: moduleBanTien_value.dv_viettel == '1' ? 1 : 2,
            bc_viettel: moduleBanTien_value.bc_viettel == '1' ? 1 : 2,
        }
          batTatModuleBanTienValue(moduleBanTien_value)
      }
      fetchSettingModuleBanTien();
  }, [])

  const phanTichThongKe = (thongKe) => {
    const data = thongKe.data.values;

    console.log(data);

    let hasMobifone = false;
    let hasVinaphone = false;
    let haveViettel = false;

    Object.keys(data).forEach(key => {
      if (data[key].operator == 'MOBIFONE' && !hasMobifone) {
        let showGiaTriDon = chuyenDoiSoTat(parseFloat(data[key].phi_dua_theo_muc));
        setMucDuaCaoNhatMobi(data[key].dua);
        setGiaTriMucDuaCaoNhatMobi(showGiaTriDon);
        hasMobifone = true;
      } else if (data[key].operator == 'VINAPHONE' && !hasVinaphone) {
        let showGiaTriDon = chuyenDoiSoTat(parseFloat(data[key].phi_dua_theo_muc));
        setMucDuaCaoNhatVina(data[key].dua);
        setGiaTriMucDuaCaoNhatVina(showGiaTriDon);
        hasVinaphone = true;
      } if (data[key].operator == 'VIETTEL' && !haveViettel) {
        let showGiaTriDon = chuyenDoiSoTat(parseFloat(data[key].phi_dua_theo_muc));
        setMucDuaCaoNhatViettel(data[key].dua);
        setGiaTriMucDuaCaoNhatViettel(showGiaTriDon);
        haveViettel = true;
      }
    });
  };

  const chuyenDoiSoTat = (soCanChuyen) => {
    let numGiaTriDon = soCanChuyen;
    let showGiaTriDon = '';

    if (numGiaTriDon < 1000) {
      showGiaTriDon = `${numeral(numGiaTriDon).format(numGiaTriDon % 1 === 0 ? '0,0' : '0,0.0')}`;
    } else if (numGiaTriDon < 1000000) {
      showGiaTriDon = `${numeral(numGiaTriDon / 1000).format(numGiaTriDon / 1000 % 1 === 0 ? '0,0' : '0,0.0')}K`;
    } else if (numGiaTriDon < 1000000000) {
      showGiaTriDon = `${numeral(numGiaTriDon / 1000000).format(numGiaTriDon / 1000000 % 1 === 0 ? '0,0' : '0,0.0')}M`;
    } else if (numGiaTriDon < 1000000000000) {
      showGiaTriDon = `${numeral(numGiaTriDon / 1000000000).format(numGiaTriDon / 1000000000 % 1 === 0 ? '0,0' : '0,0.0')}B`;
    } else if (numGiaTriDon < 1000000000000000) {
      showGiaTriDon = `${numeral(numGiaTriDon / 1000000000000).format(numGiaTriDon / 1000000000000 % 1 === 0 ? '0,0' : '0,0.0')}T`;
    }

    return showGiaTriDon;
  };

  useEffect(() => {
    async function fetchData() {
      const payload = {
        ...filter,
        limit: limit,
        page: page,
        username: values.username,
        phone: values.phone,
        ma_don: values.ma_don,
        kenh_yeu_cau: values.kenh_yeu_cau,
        nha_mang: values.nha_mang,
        trang_thai: values.trang_thai,
        startDate: values.startDate.format("DD/MM/YYYY"),
        endDate: values.endDate.format("DD/MM/YYYY"),
      };
      dispatch(NapmymobifoneActionOld.updateFilter(payload));
      dispatch(NapmymobifoneActionOld.list())
      const thongKe = await dispatch(NapmymobifoneActionOld.thongKe());
      phanTichThongKe(thongKe);
    }
    fetchData();
  }, [page, limit])

  const onLookup = async (immediatelyPayload = {}) => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      username: typeof immediatelyPayload.username !== 'undefined' ? immediatelyPayload.username : values.username,
      phone: typeof immediatelyPayload.phone !== 'undefined' ? immediatelyPayload.phone : values.phone,
      ma_don: typeof immediatelyPayload.ma_don !== 'undefined' ? immediatelyPayload.ma_don : values.ma_don,
      kenh_yeu_cau: typeof immediatelyPayload.kenh_yeu_cau !== 'undefined' ? immediatelyPayload.kenh_yeu_cau : values.kenh_yeu_cau,
      nha_mang: typeof immediatelyPayload.nha_mang !== 'undefined' ? immediatelyPayload.nha_mang : values.nha_mang,
      trang_thai: typeof immediatelyPayload.trang_thai !== 'undefined' ? immediatelyPayload.trang_thai : values.trang_thai,
      startDate: typeof immediatelyPayload.startDate !== 'undefined' ? immediatelyPayload.startDate.format("DD/MM/YYYY") : values.startDate.format("DD/MM/YYYY"),
      endDate: typeof immediatelyPayload.endDate !== 'undefined' ? immediatelyPayload.endDate.format("DD/MM/YYYY") :values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(NapmymobifoneActionOld.updateFilter(payload));
    dispatch(NapmymobifoneActionOld.list())
    const thongKe = await dispatch(NapmymobifoneActionOld.thongKe());
    phanTichThongKe(thongKe);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const exportExcel = async (ma_don = null) => {
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        username: values.username,
        phone: values.phone,
        ma_don: ma_don == null ? values.ma_don : ma_don,
        kenh_yeu_cau: values.kenh_yeu_cau,
        nha_mang: values.nha_mang,
        trang_thai: values.trang_thai,
        startDate: values.startDate.format("DD/MM/YYYY"),
        endDate: values.endDate.format("DD/MM/YYYY"),
      };
      dispatch(NapmymobifoneActionOld.updateFilter(payload));
      const response = await dispatch(NapmymobifoneActionOld.listExport());

      const data = [
        [
          'Mã đơn', 
          'Mã yêu cầu',
          'Username lên đơn', 
          'Tên đơn',
          'Nhà mạng',
          'Thuê bao', 
          'Loại thuê bao', 
          'SD ban đầu',
          'SD cuối',
          'Cần nạp', 
          'Đã nạp', 
          'Hủy nạp',
          'Thất bại',
          'Tồn',
          'Chiết khấu',
          'Đua',
          'Phí đua',
          'Bội số',
          'Ngày tạo đơn',
          'Kênh yêu cầu',
          'Trạng thái',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data != null && typeof response.data.values !== 'undefined' && response.data.values != null && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          let loaiThueBao = '';
          let boiSo = '';
          let nhaMang = '';
          let kenhYeuCau = ''
          let trangThai = ''

          if (element.number_type == 1) loaiThueBao = 'TRẢ TRƯỚC';
          else if (element.number_type == 2) loaiThueBao = 'TRẢ SAU';

          if (element.boi_so == 'menh_gia_bat_ky') boiSo = 'Mệnh giá bất kỳ';
          else if (element.boi_so == '10000') boiSo = '10.000';
          else if (element.boi_so == '20000') boiSo = '20.000';
          else if (element.boi_so == 'the_nho_nho_hon_50000') boiSo = 'Thẻ nhỏ (<50k)';
          else if (element.boi_so == '50000') boiSo = '50.000';
          else if (element.boi_so == '100000') boiSo = '100.000';
          else if (element.boi_so == '200000') boiSo = '200.000';
          else if (element.boi_so == '300000') boiSo = '300.000';
          else if (element.boi_so == '500000') boiSo = '500.000';
          else if (element.boi_so == '1000000') boiSo = '1.000.000';
          else boiSo = element.boi_so;

          if (element.nha_mang == 'khong_qua_api') nhaMang = 'Không qua API';
          else if (element.nha_mang == 'smart_vi') nhaMang = 'TOP-UP';
          else if (element.nha_mang == 'tkc') nhaMang = 'TKC';
          else if (element.nha_mang == 'bunca') nhaMang = 'BC';
          else if (element.nha_mang == 'dichvu') nhaMang = 'DV';
          else if (element.nha_mang == 'napho') nhaMang = 'Nạp hộ Mobifone';
          else if (element.nha_mang == 'mymobifone') nhaMang = 'My Mobifone';
          else if (element.nha_mang == 'gsmmobifone') nhaMang = 'GSM Mobifone';
          else if (element.nha_mang == 'mobifone_gsm') nhaMang = 'Mobifone GSM';
          else if (element.nha_mang == 'mobifone_otp') nhaMang = 'Mobifone (Nhập OTP)';

          if (element.kenh_yeu_cau == 'khong_qua_api') kenhYeuCau = 'Không qua API';
          else if (element.kenh_yeu_cau == 'smart_vi') kenhYeuCau = 'TOP-UP';
          else if (element.kenh_yeu_cau == 'tkc') kenhYeuCau = 'TKC';
          else if (element.kenh_yeu_cau == 'bunca') kenhYeuCau = 'BC';
          else if (element.kenh_yeu_cau == 'dichvu') kenhYeuCau = 'DV';
          else if (element.kenh_yeu_cau == 'napho') kenhYeuCau = 'Nạp hộ Mobifone';
          else if (element.kenh_yeu_cau == 'mymobifone') kenhYeuCau = 'My Mobifone';
          else if (element.kenh_yeu_cau == 'gsmmobifone') kenhYeuCau = 'GSM Mobifone';

          if (element.trang_thai == 'chay_tiep') trangThai = 'CHẠY TIẾP';
          else if (element.trang_thai == 'tam_dung') trangThai = 'TẠM DỪNG';
          else if (element.trang_thai == 'da_huy') trangThai = 'HỦY ĐƠN';
          else if (element.trang_thai == 'cho_nap') trangThai = 'CHỜ NẠP';
          else if (element.trang_thai == 'dang_nap') trangThai = 'ĐANG NẠP';
          else if (element.trang_thai == 'da_nap') trangThai = 'ĐÃ NẠP';
          else if (element.trang_thai == 'that_bai') trangThai = 'HOÀN TIỀN';

          data.push([
            element.ma_don,
            element.ma_yeu_cau,
            element.username_len_don,
            element.ten_don,
            element.operator,
            parseRealPhoneNumber(element.thue_bao),
            loaiThueBao,
            info.lay_so_du_khi_nhan_don_crm == '1' && role.priority < 3 ? element.so_du_ban_dau : '',
            info.lay_so_du_khi_nhan_don_crm == '1' && role.priority < 3 ? element.so_du_cuoi : '',
            element.can_nap,
            element.da_nap,
            element.huy_nap,
            element.that_bai,
            element.ton_nap,
            element.chiet_khau,
            element.dua,
            element.phi_dua,
            boiSo,
            moment(element.created_at).format('DD-MM-YYYY HH:mm:ss'),
            kenhYeuCau,
            trangThai,
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Danh-sach-don-nap-tien.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  };

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });

    onLookup({startDate: date});
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });

    onLookup({endDate: date});
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    onLookup({[event.target.name]: event.target.value});
  };

  const handleUsernameChange1 = (name, newValue) => {
    setValues({
      ...values,
      [name]: newValue
    });

    onLookup({[name]: newValue});
  };

  const handleSelectAll = (event) => {
    let newSelectedBlacklistIds;

    if (event.target.checked) {
      newSelectedBlacklistIds = items.map((napmymobifone) => napmymobifone.id);
    } else {
      newSelectedBlacklistIds = [];
    }

    setSelectedBlacklistIds(newSelectedBlacklistIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedBlacklistIds.indexOf(id);
    let newSelectedBlacklistIds = [];

    if (selectedIndex === -1) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds, id);
    } else if (selectedIndex === 0) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds.slice(1));
    } else if (selectedIndex === selectedBlacklistIds.length - 1) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(
        selectedBlacklistIds.slice(0, selectedIndex),
        selectedBlacklistIds.slice(selectedIndex + 1)
      );
    }

    setSelectedBlacklistIds(newSelectedBlacklistIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, napmymobifone) => {
    setSelected(napmymobifone);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(NapmymobifoneActionOld.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(NapmymobifoneActionOld.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const chayTiep = async (id) => {
    let question = "Bạn có muốn chạy tiếp đơn?";
    if (window.confirm(question)) {
      try {
        showToast('success', `Chạy tiếp đơn '${id}'`);
        dispatch(NapmymobifoneActionOld.chayTiep(id));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const daNap = async (id) => {
    let question = `Bạn có muốn chuyển trạng thái đơn này sang ĐÃ NẠP?`;
    if (window.confirm(question)) {
      try {
        showToast('success', `Đổi trạng thái đơn '${id}' sang ĐÃ NẠP`);
        dispatch(NapmymobifoneActionOld.daNap(id));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const huyDon = async (id) => {
    let question = "Bạn có muốn hủy đơn này?";
    if (window.confirm(question)) {
      try {
        showToast('success', `Đang hủy đơn '${id}'`);
        dispatch(NapmymobifoneActionOld.huyDon(id));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const tamDung = async (id) => {
    let question = "Bạn có muốn tạm dừng đơn này?";
    if (window.confirm(question)) {
      try {
        showToast('success', `Đã tạm dừng đơn '${id}'`);
        dispatch(NapmymobifoneActionOld.tamDung(id));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const choNap = async (id) => {
    let question = "Bạn có muốn đổi trạng thái đơn này sang CHỜ NẠP?";
    if (window.confirm(question)) {
      try {
        showToast('success', `Đổi trạng thái đơn '${id}' sang CHỜ NẠP`);
        dispatch(NapmymobifoneActionOld.choNap(id));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const dangNap = async (id) => {
    let question = "Bạn có muốn đổi trạng thái đơn này sang ĐANG NẠP?";
    if (window.confirm(question)) {
      try {
        showToast('success', `Đổi trạng thái đơn '${id}' sang ĐANG NẠP`);
        dispatch(NapmymobifoneActionOld.dangNap(id));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const thatBai = async (id) => {
    let question = "Bạn có muốn đổi trạng thái đơn này sang HOÀN TIỀN?";
    if (window.confirm(question)) {
      try {
        showToast('success', `Đổi trạng thái đơn '${id}' sang HOÀN TIỀN`);
        dispatch(NapmymobifoneActionOld.thatBai(id));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const hoanThanh = async (id) => {
    let question = "Bạn có muốn đổi trạng thái đơn này sang HOÀN THÀNH?";
    if (window.confirm(question)) {
      try {
        showToast('success', `Đổi trạng thái đơn '${id}' sang HOÀN THÀNH`);
        dispatch(NapmymobifoneActionOld.hoanThanh(id));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  const batDauDon = async (id) => {
    dispatch(NapmymobifoneActionOld.batDauDon(id));

    // let question = "Bạn có muốn BẮT ĐẦU CHẠY đơn này?";
    // if (window.confirm(question)) {
    //   try {
    //     showToast('success', `Đã BẮT ĐẦU CHẠY đơn '${id}'`);
    //     dispatch(NapmymobifoneActionOld.batDauDon(id));
    //   } catch (error) {
        
    //   }
    // } else {
      
    // }
  };

  const duaDon = async (id) => {
    dispatch(AppAction.appLoading(true))
    const res = await dispatch(NapmymobifoneActionOld.duaDon(id));
    if (res.data.result) {
      if (res.data.result == 'success') {
        showToast('success', res.data.msg);
      } else {
        showToast('failed', res.data.msg);
      }
      dispatch(AppAction.appLoading(true))
    }
  };

  const handleClickOpenSelectChannel = () => {
    setOpenSelectChannel(true);
  };

  const handleCloseSelectChannel = () => {
    setOpenSelectChannel(false);
  };

  const handleClickOpenTaoDon = () => {
    setOpenTaoDon(true);
  };

  const handleClickCloseTaoDon = () => {
    setOpenTaoDon(false);
  };

  const handleClickOpenThongKeUuTien = async (operator) => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      username: values.username,
      phone: values.phone,
      ma_don: values.ma_don,
      kenh_yeu_cau: values.kenh_yeu_cau,
      nha_mang: values.nha_mang,
      trang_thai: values.trang_thai,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(NapmymobifoneActionOld.updateFilter(payload));
    const thongKe = await dispatch(NapmymobifoneActionOld.thongKe());
    phanTichThongKe(thongKe);
    setOpenThongKeUuTienOperator(operator);
    setOpenThongKeUuTien(true);
  };

  const handleClickCloseThongKeUuTien = () => {
    setOpenThongKeUuTien(false);
  };

  const parseRealPhoneNumber = (msisdn) => {
    let phone = msisdn.slice(-9);
    let firstChar = phone.substring(0, 1);
    if (firstChar == '2') {
        return `1${phone}`;
    }
    return phone;
  }

  const hienThiLyDoHoanTien = (lyDo) => {
    alert(`Lý do hoàn tiền:\n${lyDo ? lyDo : ''}`);
  }

  const guiOtp = async (napmymobifonechitiet_id) => {
    dispatch(AppAction.appLoading(true))
    const res = await dispatch(NapmymobifoneActionOld.guiOtpDonTong(napmymobifonechitiet_id));
    if (res.result) {
      if (res.result == 'success') {
        showToast('success', res.msg);
      } else {
        showToast('failed', res.msg);
      }
      dispatch(AppAction.appLoading(true))
    }
  }

  const nhapOtp = async (id, napmymobifonechitiet_id) => {
    dispatch(AppAction.appLoading(true))
    const res = await dispatch(NapmymobifoneActionOld.nhapOtpDonTong(napmymobifonechitiet_id, otps[`otp_${id}`] || ''));
    if (res.result) {
      if (res.result == 'success') {
        showToast('success', res.msg);
      } else {
        showToast('failed', res.msg);
      }
      dispatch(AppAction.appLoading(true))
    }
  }

  const nhapOtpChange = async (event) => {
    setOtps({
      ...otps,
      [event.target.name]: event.target.value.trim()
    });
  }

  const nhapOtpKeyDown = (event, id, napmymobifonechitiet_id) => {
    if (event.key === 'Enter') {
      nhapOtp(id, napmymobifonechitiet_id);
    }
  };

  const handleEditBoiSo = (id) => {
    dispatch(NapmymobifoneActionOld.findOne(id));
    setOpenEditBoiSo(true);
  }

  const handleCloseEditBoiSo = () => {
    setOpenEditBoiSo(false);
  }

  const hienThiBoiSo = (boi_so) => {
    let boiSo = '';
    if (boi_so == 'menh_gia_bat_ky') boiSo = 'Mệnh giá bất kỳ';
    else if (boi_so == '10000') boiSo = '10.000';
    else if (boi_so == '20000') boiSo = '20.000';
    else if (boi_so == 'the_nho_nho_hon_50000') boiSo = 'Thẻ nhỏ (<50k)';
    else if (boi_so == '50000') boiSo = '50.000';
    else if (boi_so == '100000') boiSo = '100.000';
    else if (boi_so == '200000') boiSo = '200.000';
    else if (boi_so == '300000') boiSo = '300.000';
    else if (boi_so == '500000') boiSo = '500.000';
    else if (boi_so == '1000000') boiSo = '1.000.000';
    else boiSo = boi_so;
    return boiSo;
  }

  const xuLyChucNang = async (value, id) => {
    if (value == 'chay_tiep') await chayTiep(id);
    else if (value == 'tam_dung') await tamDung(id);
    else if (value == 'da_nap') await daNap(id);
    else if (value == 'da_huy') await huyDon(id);
    else if (value == 'cho_nap') await choNap(id);
    else if (value == 'dang_nap') await dangNap(id);
    else if (value == 'that_bai') await thatBai(id);
    else if (value == 'hoan_thanh') await hoanThanh(id);
    else if (value == 'bat_dau_don') await batDauDon(id);
    else if (value == 'dua_don') await duaDon(id);
  };

  const colorByStatus = (status) => {
    if (status == "chay_tiep") return '#1338be';
    else if (status == "tam_dung") return 'orange';
    else if (status == "da_huy") return 'red';
    else if (status == "cho_nap") return '#65350f';
    else if (status == "dang_nap") return '#ff0099';
    else if (status == "da_nap") return 'green';
    else if (status == "that_bai") return 'red';
    else if (status == "hoan_thanh") return 'green';
    else return '';
  }

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.log('String copied to clipboard:', content);
        showToast('success', `Đã copy: ${content}`)
      })
      .catch((error) => {
        console.error('Unable to copy string:', error);
        showToast('error', `Lỗi không thể copy: ${content}`)
      });
  }

  const doiGiaoDien = () => {
    dispatch(NapmymobifoneActionOld.doiGiaoDien())
  }

  return (
    <><Card className="filter_card">
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <PerfectScrollbar>
            <Box sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: 1050,
            }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="phone"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={values.phone}
                placeholder="Tìm SĐT"
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
              />
              <div style={{ width: '250px', zIndex: '10' }} className="full-width-select">
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: 56,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    }),
                  }}
                  options={options}
                  getOptionLabel={(item) => `${item.role.priority == 4 ? '--' : (item.role.priority == 5 ? '----' : '')} ${item.username != '' ? (item.username + ' - ') : ''}${item.fullname}`}
                  placeholder={values.username || "Tìm user..."}
                  components={{
                    DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                    IndicatorSeparator: null,
                  }}
                  value={values.username || null}
                  onChange={(newValue) => handleUsernameChange1('username', newValue.username)}
                  isSearchable
                />
              </div>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="ma_don"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={values.ma_don}
                placeholder="Tìm theo mã đơn"
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
              />
              <Select1
                style={{ height: 56, marginTop: 6, width: "250px", marginLeft: 0 }}
                name="nha_mang"
                value={values.nha_mang || -99}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="-99">Nhà mạng...</MenuItem>
                <MenuItem value="MOBIFONE">MOBIFONE</MenuItem>
                <MenuItem value="VINAPHONE">VINAPHONE</MenuItem>
                <MenuItem value="VIETTEL">VIETTEL</MenuItem>
              </Select1>
              <Select1
                style={{ height: 56, marginTop: 6, width: "250px", marginLeft: 0 }}
                name="kenh_yeu_cau"
                value={values.kenh_yeu_cau || -99}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="-99">Kênh yêu cầu...</MenuItem>
                {(role.priority < 3 || role.priority > 2 && moduleBanTien.topup == 1) && <MenuItem value="smart_vi">TOP-UP</MenuItem>}
                {(role.priority < 3 || role.priority > 2 && moduleBanTien.napho == 1) && <MenuItem value="napho">Nạp hộ Mobifone</MenuItem>}
                {(role.priority < 3 || role.priority > 2 && moduleBanTien.mymobifone == 1) && <MenuItem value="mymobifone">My Mobifone</MenuItem>}
                {(role.priority < 3 || role.priority > 2 && moduleBanTien.gsmmobifone == 1) && <MenuItem value="gsmmobifone">GSM Mobifone</MenuItem>}
              </Select1>
              <Select1
                style={{ height: 56, marginTop: 6, width: "250px", marginLeft: 0 }}
                name="trang_thai"
                value={values.trang_thai || -99}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="-99">Trạng thái...</MenuItem>
                <MenuItem value="chay_tiep" style={{color: colorByStatus('chay_tiep')}}>CHẠY TIẾP</MenuItem>
                <MenuItem value="tam_dung" style={{color: colorByStatus('tam_dung')}}>TẠM DỪNG</MenuItem>
                <MenuItem value="da_huy" style={{color: colorByStatus('da_huy')}}>HỦY ĐƠN</MenuItem>
                <MenuItem value="cho_nap" style={{color: colorByStatus('cho_nap')}}>CHỜ NẠP</MenuItem>
                <MenuItem value="dang_nap" style={{color: colorByStatus('dang_nap')}}>ĐANG NẠP</MenuItem>
                <MenuItem value="da_nap" style={{color: colorByStatus('da_nap')}}>ĐÃ NẠP</MenuItem>
                <MenuItem value="that_bai" style={{color: colorByStatus('that_bai')}}>HOÀN TIỀN</MenuItem>
                <MenuItem value="hoan_thanh" style={{color: colorByStatus('hoan_thanh')}}>HOÀN THÀNH</MenuItem>
              </Select1>
              <DatePicker
                label="Thời gian bắt đầu"
                style={{ width: '200px' }}
                format='DD/MM/YYYY'
                value={values.startDate}
                onChange={(newValue) => handleStartDateChange(newValue)}
                />
              <DatePicker
                label="Thời gian kết thúc"
                style={{ width: '200px' }}
                value={values.endDate}
                format='DD/MM/YYYY'
                onChange={(newValue) => handleEndDateChange(newValue)}
              />
            </Box>
          </PerfectScrollbar>
        </LocalizationProvider>
      </CardContent>
    </Card>
    <Card {...rest}>
      <div style={{width: '300px', margin: 'auto'}}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpenTaoDon}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "90px", minWidth: '20px', marginLeft: '0', backgroundColor: 'orange', fontWeight: 'normal' }}
          >
          Tạo đơn
        </Button>
        {role.priority < 3 && <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpenSelectChannel}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "20px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px', border: '1px solid orange' }}
          >
          <Settings className={classes.rotateIcon} style={{ color: "black" }} />
        </Button>}
        <Button
          color="primary"
          variant="contained"
          onClick={() => exportExcel()}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "20px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px', border: '1px solid orange' }}
          >
          <Download style={{ color: "#3498db" }} />
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={doiGiaoDien}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "120px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'green', fontWeight: 'normal' }}
          >
          Giao diện mới
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onLookup}
          style={{ background: !isLoading ? 'transparent' : 'url(/static/images/loading.gif) no-repeat center center / contain', height: '20px', padding: 0, marginTop: 0, width: "20px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px', border: '1px solid orange' }}
          >
          {!isLoading ? <Cached style={{ color: "#3498db" }} /> : ''}
        </Button>
      </div>
      <div style={{width: '330px', margin: '15px auto 0'}}>
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => handleClickOpenThongKeUuTien('mobifone')}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "100px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal' }}
          >
          <span style={{color: '#3c8dbc', fontWeight: 'bold'}}>MB</span>
          <span style={{color: 'red'}}>↓</span>
          <span style={{color: 'red', fontWeight: 'bold'}}>{mucDuaCaoNhatMobi}</span>
          <span style={{color: 'red', marginLeft: '3px'}}>({giaTriMucDuaCaoNhatMobi})</span>
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => handleClickOpenThongKeUuTien('vinaphone')}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "100px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal' }}
          >
          <span style={{color: '#3c8dbc', fontWeight: 'bold'}}>VN</span>
          <span style={{color: 'red'}}>↓</span>
          <span style={{color: 'red', fontWeight: 'bold'}}>{mucDuaCaoNhatVina}</span>
          <span style={{color: 'red', marginLeft: '3px'}}>({giaTriMucDuaCaoNhatVina})</span>
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => handleClickOpenThongKeUuTien('viettel')}
          style={{ height: '20px', padding: 0, marginTop: 0, width: "100px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal' }}
          >
          <span style={{color: '#3c8dbc', fontWeight: 'bold'}}>VT</span>
          <span style={{color: 'red'}}>↓</span>
          <span style={{color: 'red', fontWeight: 'bold'}}>{mucDuaCaoNhatViettel}</span>
          <span style={{color: 'red', marginLeft: '3px'}}>({giaTriMucDuaCaoNhatViettel})</span>
        </Button>
      </div>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table style={{marginTop: 15}}>
            <TableHead>
              <TableRow style={{background: 'red'}}>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  #
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Mã đơn
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Username lên đơn
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Tên đơn
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Nhà mạng
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Thuê bao
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Loại thuê bao
                </TableCell>
                {info.lay_so_du_khi_nhan_don_crm == '1' && role.priority < 3 && <>
                  <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                    SD ban đầu
                  </TableCell>
                  <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                    SD cuối
                  </TableCell>
                </>}
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Cần nạp
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Đã nạp
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Hủy / Thất bại
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Tồn
                </TableCell>
                <TableCell colSpan='2' style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Bội số
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Ngày tạo đơn
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Kênh yêu cầu
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Chiết khấu
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Đua
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Phí đua
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Thực trả
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Chức năng
                </TableCell>
                <TableCell style={{color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white'}}>
                  Trạng thái
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.slice(page * limit, page * limit + limit).map((napmymobifone, index) => (
                <TableRow
                  hover
                  key={napmymobifone.id}
                  selected={selectedBlacklistIds.indexOf(napmymobifone.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      <a
                        href='#'
                        target="_blank"
                        onClick={(e) => {
                          e.preventDefault();
                          /* eslint-disable no-restricted-globals */
                          const winWidth = screen.availWidth;
                          const winHeight = screen.availHeight;
                          /* eslint-enable no-restricted-globals */
                          window.open(
                            `${window.location.origin}/app/mobifone/napmymobifonechitiet/${napmymobifone.id}`,
                            `${napmymobifone.id}`,
                            `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${winWidth},height=${winHeight}`
                          );
                        }}
                      >
                        {napmymobifone.id.substring(0, 3) + '***' + napmymobifone.id.substring(napmymobifone.id.length - 3)}
                      </a>
                      <Button style={{padding: 0, color: 'blue', fontSize: 20, width: '20px', minWidth: 0}} onClick={() => copyToClipboard(napmymobifone.id)}>
                        📋
                      </Button>
                      {/* <br></br>
                      <Button style={{margin: "15px 0 0 0", padding: 0, minWidth: 'auto', color: 'green'}} onClick={() => exportExcel(napmymobifone.id)}>
                        Xuất
                      </Button> */}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {napmymobifone.sender_username} - {napmymobifone.full_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {napmymobifone.ten_don.length <= 15 ? napmymobifone.ten_don : (napmymobifone.ten_don.substr(0,15) + '...')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={napmymobifone.operator == 'MOBIFONE' ? 'green' : (napmymobifone.operator == 'VINAPHONE' ? 'blue' : 'red')}
                      variant="body1"
                    >
                      {napmymobifone.operator}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {napmymobifone.loai_don == 'nap_le' && <Button style={{padding: 0, color: 'blue', fontSize: 20, width: 'auto', minWidth: 0}} onClick={() => copyToClipboard(parseRealPhoneNumber(napmymobifone.thue_bao))}>
                        {parseRealPhoneNumber(napmymobifone.thue_bao)}
                      </Button>}
                      {napmymobifone.loai_don == 'nap_don' && <span style={{color: '#ff0099'}}>NẠP LÔ</span>}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={napmymobifone.number_type == 1 ? 'green' : 'blue'}
                      variant="body1"
                    >
                      {(napmymobifone.number_type == 1 ? 'TRẢ TRƯỚC' : (napmymobifone.number_type == 2 ? 'TRẢ SAU' : ''))}
                    </Typography>
                  </TableCell>
                  {info.lay_so_du_khi_nhan_don_crm == '1' && role.priority < 3 && <>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {numeral(napmymobifone.so_du_ban_dau).format('0,0')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {numeral(napmymobifone.so_du_cuoi).format('0,0')}
                      </Typography>
                    </TableCell>
                  </>}
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(napmymobifone.can_nap).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(napmymobifone.da_nap).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(napmymobifone.huy_nap + napmymobifone.that_bai).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(napmymobifone.ton_nap).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {hienThiBoiSo(napmymobifone.boi_so)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {(napmymobifone.kenh_yeu_cau == 'napho' || napmymobifone.kenh_yeu_cau == 'mymobifone') && <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleEditBoiSo(napmymobifone.id)}
                        style={{ height: '20px', padding: 0, marginTop: 0, width: "20px", minWidth: '20px', marginLeft: '5px', backgroundColor: 'orange', fontWeight: 'normal' }}
                        >
                        ✎
                      </Button>}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {moment(napmymobifone.created_at).format('DD-MM-YYYY HH:mm:ss')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {napmymobifone.kenh_yeu_cau == 'khong_qua_api' && "Không qua API"}
                      {napmymobifone.kenh_yeu_cau == 'smart_vi' && "TOP-UP"}
                      {napmymobifone.kenh_yeu_cau == 'tkc' && "TKC"}
                      {napmymobifone.kenh_yeu_cau == 'dichvu' && "DV"}
                      {napmymobifone.kenh_yeu_cau == 'bunca' && "BC"}
                      {napmymobifone.kenh_yeu_cau == 'napho' && "Nạp hộ Mobifone"}
                      {napmymobifone.kenh_yeu_cau == 'mymobifone' && "My Mobifone"}
                      {napmymobifone.kenh_yeu_cau == 'gsmmobifone' && "GSM Mobifone"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(napmymobifone.chiet_khau).format('0,0.0')}%
                    </Typography>
                  </TableCell>
                  <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        <span>{parseFloat(napmymobifone.dua) > 0 ? numeral(napmymobifone.dua).format('0,0.0') : numeral(napmymobifone.dua).format('0,0.0')}</span>
                      </Typography>
                      {parseFloat(napmymobifone.dua) + 1 <= parseFloat(napmymobifone.chiet_khau) && <Button
                        color="primary"
                        variant="contained"
                        onClick={(e) => xuLyChucNang('dua_don', napmymobifone.id)}
                        style={{ height: '20px', padding: 0, marginTop: 0, width: "20px", minWidth: '20px', marginLeft: 0, backgroundColor: 'red' }}
                        >
                        +
                      </Button>}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(napmymobifone.phi_dua).format('0,0.0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {parseFloat(napmymobifone.tien_sau_chiet_khau) > 0 ? numeral(napmymobifone.tien_sau_chiet_khau).format('0,0.0') : numeral(napmymobifone.can_nap).format('0,0.0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {(napmymobifone.trang_thai == 'cho_nap' || napmymobifone.trang_thai == 'dang_nap') && <Typography
                      color="textPrimary"
                      variant="body1" 
                      style={{width: '120px', color: (napmymobifone.trang_thai == 'da_nap' ? 'green' : (napmymobifone.trang_thai == 'da_huy' ? 'red' : 'black'))}}
                    >
                      {(napmymobifone.loai_don == 'nap_don' || napmymobifone.kenh_yeu_cau != 'mymobifone' || napmymobifone.kenh_yeu_cau == 'mymobifone' && !napmymobifone.is_expired) && <>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(e) => xuLyChucNang('bat_dau_don', napmymobifone.id)}
                          style={{ height: '20px', padding: 0, marginTop: 0, width: "20px", minWidth: '20px', marginLeft: 0, backgroundColor: 'green' }}
                          >
                          ▶
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(e) => xuLyChucNang('tam_dung', napmymobifone.id)}
                          style={{ height: '20px', padding: 0, marginTop: 0, width: "20px", minWidth: '20px', marginLeft: '10px', backgroundColor: 'orange' }}
                          >
                          ||
                        </Button>
                      </>}
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={(e) => xuLyChucNang('da_huy', napmymobifone.id)}
                        style={{ height: '20px', padding: 0, marginTop: 0, width: "20px", minWidth: '20px', marginLeft: '10px', backgroundColor: 'red' }}
                        >
                        X
                      </Button>
                    </Typography>}
                  </TableCell>
                  <TableCell style={{minWidth: '100px'}}>
                    {napmymobifone.loai_don == 'nap_le' && napmymobifone.trang_thai == 'cho_nap' && napmymobifone.kenh_yeu_cau == 'mymobifone' && napmymobifone.is_expired && <div style={{width: '100%'}}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={(e) => guiOtp(napmymobifone.napmymobifonechitiet_id)}
                        style={{ height: '20px', padding: 0, marginTop: 0, width: "50px", minWidth: '50px', marginLeft: '10px', backgroundColor: 'blue', fontSize: '10px', color: 'white' }}
                        >
                        Gửi OTP
                      </Button>
                      <TextField
                        name={`otp_${napmymobifone.id}`}
                        onChange={nhapOtpChange}
                        onKeyDown={(e) => nhapOtpKeyDown(e, napmymobifone.id, napmymobifone.napmymobifonechitiet_id)}
                        value={otps[`otp_${napmymobifone.id}`] || ''}
                        placeholder="OTP"
                        variant="outlined"
                        style={{ height: 56, marginTop: 0, width: "50%", marginLeft: '10px' }}
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={(e) => nhapOtp(napmymobifone.id, napmymobifone.napmymobifonechitiet_id)}
                        style={{ height: 56, padding: 0, marginTop: 0, width: "20px", minWidth: '20px', marginLeft: '10px', backgroundColor: 'transparent', fontSize: '20px' }}
                        >
                        ✅
                      </Button>
                    </div>}
                    {(napmymobifone.loai_don == 'nap_don' || napmymobifone.kenh_yeu_cau != 'mymobifone' || napmymobifone.kenh_yeu_cau == 'mymobifone' && !napmymobifone.is_expired || napmymobifone.kenh_yeu_cau == 'mymobifone' && napmymobifone.trang_thai != 'cho_nap') && <>
                      <div style={{width: '80%', display:'inline-block'}}>
                        {role.priority < 3 && <Select1 className='select_trang_thai' style={{ fontSize: "16px", width: "100%", height: '20px', marginBottom: '0', color: colorByStatus(napmymobifone.trang_thai) }} variant="outlined" value={napmymobifone.trang_thai} onChange={(e) => xuLyChucNang(e.target.value, napmymobifone.id)}>
                          <MenuItem value="-99"></MenuItem>
                          <MenuItem value="chay_tiep" style={{color: colorByStatus('chay_tiep')}}>CHẠY TIẾP</MenuItem>
                          <MenuItem value="tam_dung" style={{color: colorByStatus('tam_dung')}}>TẠM DỪNG</MenuItem>
                          <MenuItem value="da_huy" style={{color: colorByStatus('da_huy')}}>HỦY ĐƠN</MenuItem>
                          <MenuItem value="cho_nap" style={{color: colorByStatus('cho_nap')}}>CHỜ NẠP</MenuItem>
                          <MenuItem value="dang_nap" style={{color: colorByStatus('dang_nap')}}>ĐANG NẠP</MenuItem>
                          <MenuItem value="da_nap" style={{color: colorByStatus('da_nap')}}>ĐÃ NẠP</MenuItem>
                          <MenuItem value="that_bai" style={{color: colorByStatus('that_bai')}}>HOÀN TIỀN</MenuItem>
                          <MenuItem value="hoan_thanh" style={{color: colorByStatus('hoan_thanh')}}>HOÀN THÀNH</MenuItem>
                        </Select1>}
                        {role.priority > 2 && <>
                          {napmymobifone.trang_thai == 'chay_tiep' && <span style={{color: colorByStatus('chay_tiep')}}>CHẠY TIẾP</span>}
                          {napmymobifone.trang_thai == 'tam_dung' && <span style={{color: colorByStatus('tam_dung')}}>TẠM DỪNG</span>}
                          {napmymobifone.trang_thai == 'da_huy' && <span style={{color: colorByStatus('da_huy')}}>HỦY ĐƠN</span>}
                          {napmymobifone.trang_thai == 'cho_nap' && <span style={{color: colorByStatus('cho_nap')}}>CHỜ NẠP</span>}
                          {napmymobifone.trang_thai == 'dang_nap' && <span style={{color: colorByStatus('dang_nap')}}>ĐANG NẠP</span>}
                          {napmymobifone.trang_thai == 'da_nap' && <span style={{color: colorByStatus('da_nap')}}>ĐÃ NẠP</span>}
                          {napmymobifone.trang_thai == 'that_bai' && <span style={{color: colorByStatus('that_bai')}}>HOÀN TIỀN</span>}
                          {napmymobifone.trang_thai == 'hoan_thanh' && <span style={{color: colorByStatus('hoan_thanh')}}>HOÀN THÀNH</span>}
                        </>}
                      </div>
                      {napmymobifone.loai_don == 'nap_le' && napmymobifone.trang_thai == 'that_bai' && <div style={{display:'inline-block'}}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(e) => hienThiLyDoHoanTien(napmymobifone.ly_do_hoan_tien)}
                          style={{ height: '20px', padding: 0, marginTop: 0, width: "20px", minWidth: '20px', marginLeft: '10px', backgroundColor: 'transparent', fontSize: '20px' }}
                          >
                          📋
                        </Button>
                      </div>}
                    </>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <PerfectScrollbar>
        <div style={{margin: 15, minWidth: 1050}}>
          <span style={{marginRight: 15}}>Tổng cần nạp: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(tong_can_nap).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Tổng đã nạp: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(tong_da_nap).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Tổng hủy: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(tong_huy_nap).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Tổng thất bại: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(tong_that_bai).format('0,0')}</span></span>
          |
          <span style={{marginLeft: 15, marginRight: 15}}>Tổng tồn: <span style={{color: 'blue', fontWeight: 'bold'}}>{numeral(tong_ton_nap).format('0,0')}</span></span>
        </div>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      {/* <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} /> */}
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
      <CreateEditDialogBoiSo open={openEditBoiSo} handleClose={handleCloseEditBoiSo} type={"EDIT"} />
      <CreateEditDialogSelectChannel open={openSelectChannel} handleClose={handleCloseSelectChannel} type={"SELECT_CHANNEL"} />
      <CreateEditDialogTaoDon open={openTaoDon} handleClose={handleClickCloseTaoDon} type={"TAO_DON"} />
      <CreateEditDialogThongKeUuTien open={openThongKeUuTien} handleClose={handleClickCloseThongKeUuTien} type={"THONG_KE_UU_TIEN"} operator={openThongKeUuTienOperator} />
    </Card></>
  );
};

export default NapMyMobifoneDetailOld;
