import React, { useState } from 'react';

import { Search as SearchIcon } from 'react-feather';
import { useDispatch } from 'react-redux';
import { AppAction } from 'src/actions/app.action';
import { CustomerAction } from 'src/actions/customer.action';
import { UploadAction } from 'src/actions/upload.acction';
import * as XLSX from 'xlsx';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';

import UploadPreivewDialog from './UploadPreivewDialog';

const UploadListToolbar = (props) => {
  const dispatch = useDispatch()
  const [preview, setPreview] = useState(false);
  const [assign, setAssign] = useState(false);

  const fileInput = React.useRef();

  const handleClosePreview = () => {
    fileInput.current.value = null;
    setPreview(false)
  }

  const handleOpenAssign = () => {
    dispatch(CustomerAction.countAssign())
    setAssign(true)
  }

  const handleImportFile = (e) => {
    e.preventDefault();
    dispatch(AppAction.appLoading(true))
    const files = e.target.files, f = files[0];
    console.log(files[0].name)
    dispatch(UploadAction.createTemp({ filename: files[0].name }))
    const reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const temp = dataParse.splice(1).map((c) => ({
        phone: c[1],
        type: c[2],
        package: c[3],
        registerAt: c[4],
        expiredAt: c[5],
        packageCycle: c[6],
        state: c[7],
        status: "NORMAL",
        packageCanRegister: c[8]
      }));

      dispatch(CustomerAction.importData(temp));
      dispatch(AppAction.appLoading(false))
      setPreview(true)
      e.target.value = null;
    };
    reader.readAsBinaryString(f)
  }

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <input
          ref={fileInput}
          type="file"
          onChange={(e) => handleImportFile(e)}
          style={{ display: 'none' }}
        />

        <Button onClick={handleOpenAssign}>
          Phân dữ liệu
        </Button>

        <Button sx={{ mx: 1 }} onClick={() => fileInput.current.click()}>
          Tải dữ liệu
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search product"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <UploadPreivewDialog open={preview} handleClose={handleClosePreview} />
    </Box>
  )
};

export default UploadListToolbar;
