import 'react-perfect-scrollbar/dist/css/styles.css';

import { ConfirmProvider } from 'material-ui-confirm';
import { useRoutes } from 'react-router-dom';

import {
  StyledEngineProvider,
  ThemeProvider,
} from '@material-ui/core';

import GlobalStyles from './components/GlobalStyles';
import routes from './routes';
import theme from './theme';

const App = () => {
  const content = useRoutes(routes);

  return (
    <ConfirmProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {content}
        </ThemeProvider>
      </StyledEngineProvider>
    </ConfirmProvider>
  );
};

export default App;
