import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { CauhinhgsmAction } from 'src/actions/cauhinhgsm.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    Box,
    MenuItem,
    Select as Select1,
    InputAdornment,
    SvgIcon,
    InputLabel,
    TableCell,
} from '@material-ui/core';

import {
    DatePicker,
    LocalizationProvider,
  } from '@mui/x-date-pickers';
  import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';

import numeral from 'numeral';

import { showToast } from 'src/utils/common';

const SuaNhieuMucDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { role } = useSelector(state => state.profile);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.cauhinhgsm)
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenBoiSo, setIsOpenBoiSo] = useState(false);
    const [ma_nhom, setMaNhom] = useState("")
    const [ten_nhom, setTenNhom] = useState("")
    const [ma_may_tram, setMaMayTram] = useState("")
    const [gioi_han_canh_bao, setGioiHanCanhBao] = useState("0")
    const [gioi_han_off_cong, setGioiHanOffCong] = useState("0")
    const [ban_tien, setBanTien] = useState(false);
    const [nap_sim, setNapSim] = useState(false);
    const [nap_tra_truoc, setNapTraTruoc] = useState(false);
    const [nap_tra_sau, setNapTraSau] = useState(false);
    const [trang_thai, setTrangThai] = useState(false);
    const [auto_imei, setAutoImei] = useState(false);
    const [boi_so, setBoiSo] = useState(['-99'])
    const [uu_tien, setUuTien] = useState("")
    const [gioi_han_so_tien, setGioiHanSoTien] = useState("")
    const [gioi_han_so_lan, setGioiHanSoLan] = useState("")
    const [dau_so_nap, setDauSoNap] = useState("")
    const [values, setValues] = useState({
        nha_mang: ['-99'],
    });

    useEffect(() => {
        // if (items.length > 0) {
        //     return;
        // }
        // dispatch(PermissionAction.list(0, 100))

        resetState();
    }, [])

    const onPressPositive = async () => {
        const id = props.idgsm;
        const yeuCauXuLy = props.type;
        const selectedItems = props.mucDaChon;

        console.log(yeuCauXuLy, selectedItems);

        if (yeuCauXuLy == 'sua_uu_tien') {
            dispatch(CauhinhgsmAction.handleSuaPortNhieuMuc(id, {yeuCauXuLy, selectedItems, data: {uu_tien}}))
            showToast('success', `Cập nhật thành công`);
            resetState();
            props.handleClose();
        } else if (yeuCauXuLy == 'sua_gioi_han') {
            dispatch(CauhinhgsmAction.handleSuaPortNhieuMuc(id, {yeuCauXuLy, selectedItems, data: {gioi_han_so_lan, gioi_han_so_tien: gioi_han_so_tien.replace(/,/g, '')}}))
            showToast('success', `Cập nhật thành công`);
            resetState();
            props.handleClose();
        } else if (yeuCauXuLy == 'sua_menh_gia') {
            dispatch(CauhinhgsmAction.handleSuaPortNhieuMuc(id, {yeuCauXuLy, selectedItems, data: {boi_so}}))
            showToast('success', `Cập nhật thành công`);
            resetState();
            props.handleClose();
        } else if (yeuCauXuLy == 'sua_dau_so_nap') {
            dispatch(CauhinhgsmAction.handleSuaPortNhieuMuc(id, {yeuCauXuLy, selectedItems, data: {dau_so_nap}}))
            showToast('success', `Cập nhật thành công`);
            resetState();
            props.handleClose();
        }
    }

    const resetState = () => {
        setBoiSo(['-99'])
        setUuTien("")
        setGioiHanSoTien("")
        setGioiHanSoLan("")
        setDauSoNap("")
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    const handleChange = (event) => {
        if (event.target.name == 'boi_so') {
            setIsOpenBoiSo(false);
            let targetValues = event.target.value;
            targetValues = targetValues.filter((value) => value != '-99')
            if (targetValues.length == 0) targetValues.push('-99');
            setBoiSo(targetValues);
        } else if (event.target.name == 'nha_mang') {
            setIsOpen(false);
            let targetValues = event.target.value;
            targetValues = targetValues.filter((value) => value != '-99')
            if (targetValues.length == 0) targetValues.push('-99');
            setValues({
                ...values,
                [event.target.name]: targetValues,
            });
        } else {
            setValues({
                ...values,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleClickChonNhaMang = async () => {
        setIsOpen(true);
    }

    const handleClickChonBoiSo = async () => {
        setIsOpenBoiSo(true);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={props.open} onClose={props.handleClose}>
                {props.type == 'sua_uu_tien' && <DialogTitle>Sửa ưu tiên nhiều cổng</DialogTitle>}
                {props.type == 'sua_gioi_han' && <DialogTitle>Sửa giới hạn nhiều cổng</DialogTitle>}
                {props.type == 'sua_menh_gia' && <DialogTitle>Sửa mệnh giá nhiều cổng</DialogTitle>}
                {props.type == 'sua_dau_so_nap' && <DialogTitle>Sửa đầu số nạp nhiều cổng</DialogTitle>}
                <DialogContent style={{ width: 600, paddingTop: 20 }}>
                    <Grid
                        container
                        spacing={3}
                    >
                        {props.type == 'sua_uu_tien' && <>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Ưu tiên"
                                    name="uu_tien"
                                    onChange={(e) => setUuTien(e.target.value)}
                                    required
                                    value={uu_tien}
                                    variant="outlined"
                                />
                            </Grid>
                        </>}
                        {props.type == 'sua_gioi_han' && <>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Số tiền"
                                    name="gioi_han_so_tien"
                                    onChange={(e) => setGioiHanSoTien(numeral(e.target.value).format('0,0'))}
                                    required
                                    value={gioi_han_so_tien}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Số lần/tháng"
                                    name="gioi_han_so_lan"
                                    onChange={(e) => setGioiHanSoLan(e.target.value)}
                                    required
                                    value={gioi_han_so_lan}
                                    variant="outlined"
                                />
                            </Grid>
                        </>}
                        {props.type == 'sua_dau_so_nap' && <>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Đầu số nạp"
                                    name="dau_so_nap"
                                    onChange={(e) => setDauSoNap(e.target.value)}
                                    required
                                    value={dau_so_nap}
                                    variant="outlined"
                                />
                            </Grid>
                        </>}
                        {props.type == 'sua_menh_gia' && <>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Select1
                                    style={{ height: 56, marginTop: 0, marginBottom: 0, width: "100%", marginLeft: 0 }}
                                    name="boi_so"
                                    value={boi_so || ['-99']}
                                    onChange={handleChange}
                                    onOpen={handleClickChonBoiSo}
                                    variant="outlined"
                                    open={isOpenBoiSo}
                                    multiple
                                >
                                    <MenuItem value="-99">Chọn mệnh giá...</MenuItem>
                                    <MenuItem value="10" style={{backgroundColor: boi_so.includes('10') ? 'yellow' : 'inherit'}}>10</MenuItem>
                                    <MenuItem value="20" style={{backgroundColor: boi_so.includes('20') ? 'yellow' : 'inherit'}}>20</MenuItem>
                                    <MenuItem value="30" style={{backgroundColor: boi_so.includes('30') ? 'yellow' : 'inherit'}}>30</MenuItem>
                                    <MenuItem value="50" style={{backgroundColor: boi_so.includes('50') ? 'yellow' : 'inherit'}}>50</MenuItem>
                                    <MenuItem value="100" style={{backgroundColor: boi_so.includes('100') ? 'yellow' : 'inherit'}}>100</MenuItem>
                                    <MenuItem value="200" style={{backgroundColor: boi_so.includes('200') ? 'yellow' : 'inherit'}}>200</MenuItem>
                                    <MenuItem value="300" style={{backgroundColor: boi_so.includes('300') ? 'yellow' : 'inherit'}}>300</MenuItem>
                                    <MenuItem value="500" style={{backgroundColor: boi_so.includes('500') ? 'yellow' : 'inherit'}}>500</MenuItem>
                                    <MenuItem value="1000" style={{backgroundColor: boi_so.includes('1000') ? 'yellow' : 'inherit'}}>1.000</MenuItem>
                                </Select1>
                            </Grid>
                        </>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Huỷ</Button>
                    <Button onClick={onPressPositive}>Lưu</Button>
                </DialogActions>
            </Dialog >
        </LocalizationProvider>
    );
}

export default SuaNhieuMucDialog;