import { LookupService } from 'src/services/lookup.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const LOOKUP_PACKAGE_SUCCESS = 'LOOKUP:PACKAGE_SUCCESS'
export const LOOKUP_PACKAGE_FAILD = 'LOOKUP:PACKAGE_FAILD'
export const LOOKUP_INFO_SUCCESS = 'LOOKUP:INFO_SUCCESS'
export const LOOKUP_INFO_FAILD = 'LOOKUP:INFO_FAILD'
export const LOOKUP_BALANCE_SUCCESS = 'LOOKUP:BALANCE_SUCCESS'
export const LOOKUP_BALANCE_FAILD = 'LOOKUP:BALANCE_FAILD'

export const LOOKUP_CLEANER = 'LOOKUP:CLEANER'

export const LookupAction = {
    lookupPackage,
    lookupInfo,
    lookupBalance,
    clearLookup
}


function clearLookup() {
    return { type: LOOKUP_CLEANER }
}

function lookupPackage(phone) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        LookupService.lookupPackage(phone).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
            if (error) {
                showToast(NOTI_TYPES.ERROR, error.msg)
            }
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LOOKUP_PACKAGE_SUCCESS, data } }
    function failure(error) { return { type: LOOKUP_PACKAGE_FAILD, error } }
}

function lookupInfo(phone, type) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        LookupService.lookupInfo(phone, type).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
            if (error) {
                showToast(NOTI_TYPES.ERROR, error.msg)
            }
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LOOKUP_INFO_SUCCESS, data } }
    function failure(error) { return { type: LOOKUP_INFO_FAILD, error } }
}

function lookupBalance(phone, not_show_error = false) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        LookupService.lookupBalance(phone).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
            if (error && not_show_error == false) {
                showToast(NOTI_TYPES.ERROR, error.msg)
            }
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LOOKUP_BALANCE_SUCCESS, data } }
    function failure(error) { return { type: LOOKUP_BALANCE_FAILD, error } }
}
