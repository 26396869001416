import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { GiahoantienAction } from 'src/actions/giahoantien.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getGiahoantienStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
} from '@material-ui/core';
import CreateEditDialog from './CreateEditDialog';

import numeral from 'numeral';

const GiahoantienLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedGiahoantienIds, setSelectedGiahoantienIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { items, total, filter } = useSelector(state => state.giahoantien);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    dispatch(GiahoantienAction.updateFilter(payload));
    dispatch(GiahoantienAction.list())
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedGiahoantienIds;

    if (event.target.checked) {
      newSelectedGiahoantienIds = items.map((giahoantien) => giahoantien.id);
    } else {
      newSelectedGiahoantienIds = [];
    }

    setSelectedGiahoantienIds(newSelectedGiahoantienIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedGiahoantienIds.indexOf(id);
    let newSelectedGiahoantienIds = [];

    if (selectedIndex === -1) {
      newSelectedGiahoantienIds = newSelectedGiahoantienIds.concat(selectedGiahoantienIds, id);
    } else if (selectedIndex === 0) {
      newSelectedGiahoantienIds = newSelectedGiahoantienIds.concat(selectedGiahoantienIds.slice(1));
    } else if (selectedIndex === selectedGiahoantienIds.length - 1) {
      newSelectedGiahoantienIds = newSelectedGiahoantienIds.concat(selectedGiahoantienIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedGiahoantienIds = newSelectedGiahoantienIds.concat(
        selectedGiahoantienIds.slice(0, selectedIndex),
        selectedGiahoantienIds.slice(selectedIndex + 1)
      );
    }

    setSelectedGiahoantienIds(newSelectedGiahoantienIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, giahoantien) => {
    setSelected(giahoantien);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(GiahoantienAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(GiahoantienAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Tên gói cước
                </TableCell>
                <TableCell>
                  Giá gia hạn
                </TableCell>
                <TableCell>
                  Giá nâng cấp T1
                </TableCell>
                <TableCell>
                  Giá nâng cấp T2
                </TableCell>
                <TableCell>
                  Giá nâng cấp T3
                </TableCell>
                <TableCell>
                  Giá gói
                </TableCell>
                <TableCell>
                  Chức năng
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((giahoantien, index) => (
                <TableRow
                  hover
                  key={giahoantien.id}
                  selected={selectedGiahoantienIds.indexOf(giahoantien.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {giahoantien.ten_goi_cuoc}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {numeral(giahoantien.gia_gia_han).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {numeral(giahoantien.gia_nang_cap).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {numeral(giahoantien.gia_nang_cap_t2).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {numeral(giahoantien.gia_nang_cap_t3).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {numeral(giahoantien.gia_goi).format('0,0')}
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="edit" onClick={() => onSelected(giahoantien.id)}>
                      <Edit3Icon color='green' />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleConfirmOpen(CONFIRM_TYPES.DELETE, giahoantien)}>
                      <Trash2Icon color='red' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default GiahoantienLookupDetail;
