import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { RequestrefundAction } from 'src/actions/requestrefund.action';
import { SettingAction } from 'src/actions/setting.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    Select,
    MenuItem,
    InputLabel,
} from '@material-ui/core';

const CreateEditDialogSelectChannel = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.requestrefund)
    const [kenh_hoan_tien, setKenhHoanTien] = useState("")
    const [kenh_hoan_tien_tra_sau, setKenhHoanTienTraSau] = useState("")
    const [ti_le_chiet_khau, setTiLeChietKhau] = useState("")
    const [ti_le_chiet_khau_tra_sau, setTiLeChietKhauTraSau] = useState("")

    useEffect(() => {
      async function fetchData() {
        const kenh_hoan_tien_res = await dispatch(SettingAction.findByKey('kenh_hoan_tien'))
        setKenhHoanTien(kenh_hoan_tien_res.data.value)

        const kenh_hoan_tien_tra_sau_res = await dispatch(SettingAction.findByKey('kenh_hoan_tien_tra_sau'))
        setKenhHoanTienTraSau(kenh_hoan_tien_tra_sau_res.data.value)

        const ti_le_chiet_khau_res = await dispatch(SettingAction.findByKey('ti_le_chiet_khau'))
        setTiLeChietKhau(ti_le_chiet_khau_res.data.value)

        const ti_le_chiet_khau_tra_sau_res = await dispatch(SettingAction.findByKey('ti_le_chiet_khau_tra_sau'))
        setTiLeChietKhauTraSau(ti_le_chiet_khau_tra_sau_res.data.value)
      }
      fetchData();
    }, [])

    const onPressPositive = async () => {
        if (kenh_hoan_tien === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }
        
        try {
            var response = await dispatch(SettingAction.update("kenh_hoan_tien", { value: kenh_hoan_tien }));
            response = await dispatch(SettingAction.update("kenh_hoan_tien_tra_sau", { value: kenh_hoan_tien_tra_sau }));
            response = await dispatch(SettingAction.update("ti_le_chiet_khau", { value: ti_le_chiet_khau }));
            response = await dispatch(SettingAction.update("ti_le_chiet_khau_tra_sau", { value: ti_le_chiet_khau_tra_sau }));
            console.log(response)
            if (typeof response.status !== 'undefined' && response.status == 'success') alert("Cập nhật cài đặt thành công");
            else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
            resetState();
            props.handleClose();
        } catch (error) {
            if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
        }
    }

    const resetState = () => {
        //setKenhHoanTien("")
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Cài đặt</DialogTitle>
            <DialogContent style={{ width: 600, paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <Typography
                        color="textPrimary"
                        variant="body1"
                        >
                        Kênh hoàn tiền trả trước
                        </Typography>
                        <Select fullWidth variant="outlined" name="kenh_hoan_tien" value={kenh_hoan_tien} onChange={(e) => setKenhHoanTien(e.target.value)}>
                            <MenuItem value="khong_qua_api" selected>Không qua API</MenuItem>
                            <MenuItem value="dichvu24h.pro">dichvu24h.pro</MenuItem>
                            <MenuItem value="bunca.pro">bunca.pro</MenuItem>
                            <MenuItem value="mobifone_money">mobifone_money</MenuItem>
                        </Select>

                        <Typography
                        color="textPrimary"
                        variant="body1"
                        >
                        Kênh hoàn tiền trả sau
                        </Typography>
                        <Select fullWidth variant="outlined" name="kenh_hoan_tien_tra_sau" value={kenh_hoan_tien_tra_sau} onChange={(e) => setKenhHoanTienTraSau(e.target.value)}>
                            <MenuItem value="khong_qua_api" selected>Không qua API</MenuItem>
                            <MenuItem value="dichvu24h.pro">dichvu24h.pro</MenuItem>
                            <MenuItem value="bunca.pro">bunca.pro</MenuItem>
                            <MenuItem value="mobifone_money">mobifone_money</MenuItem>
                        </Select>

                        <TextField
                            style={{marginTop: 15}}
                            fullWidth
                            label="Tỉ lệ chiết khấu trả trước (%)"
                            name="ti_le_chiet_khau"
                            onChange={(e) => setTiLeChietKhau(e.target.value)}
                            value={ti_le_chiet_khau}
                            variant="outlined"
                        />

                        <TextField
                            style={{marginTop: 15}}
                            fullWidth
                            label="Tỉ lệ chiết khấu trả sau (%)"
                            name="ti_le_chiet_khau_tra_sau"
                            onChange={(e) => setTiLeChietKhauTraSau(e.target.value)}
                            value={ti_le_chiet_khau_tra_sau}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={onPressPositive}>OK</Button>
            </DialogActions>
        </Dialog >
    );
}

export default CreateEditDialogSelectChannel;