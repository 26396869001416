import { combineReducers } from 'redux';
import { APP_RESET } from 'src/actions/app.action';

import account from './account.reducer';
import app from './app.reducer';
import auth from './auth.reducer';
import bank from './bank.reducer';
import customer from './customer.reducer';
import division from './division.reducer';
import giahoantien from './giahoantien.reducer';
import invite from './invite.reducer';
import lookup from './lookup.reducer';
import permission from './permission.reducer';
import profile from './profile.reducer';
import report from './report.reducer';
import report90849332 from './report90849332.reducer';
import requestrefund from './requestrefund.reducer';
import requestrefundreport from './requestrefundreport.reducer';
import role from './role.reducer';
import systemaccount from './systemaccount.reducer';
import quetdata from './quetdata.reducer';
import quetdtct from './quetdtct.reducer';
import quetdatakhonggoi from './quetdatakhonggoi.reducer';
import uploadchiadata from './uploadchiadata.reducer';
import upload from './upload.reducer';
import khr from './khr.reducer';
import customermessage9084 from './customermessage9084.reducer';
import blacklist from './blacklist.reducer';
import reportstaffrevenue from './reportstaffrevenue.reducer';
import reportbranchrevenue from './reportbranchrevenue.reducer';
import tinhluong from './tinhluong.reducer';
import dsdttinhluong from './dsdttinhluong.reducer';
import lstracuu from './lstracuu.reducer';
import baodo from './baodo.reducer';
import mobidanhsachdon from './mobidanhsachdon.reducer';
import napmymobifone from './napmymobifone.reducer';
import danhsachcom from './danhsachcom.reducer';
import lsnaptien from './lsnaptien.reducer';
import lssodu from './lssodu.reducer';
import cauhinhgsm from './cauhinhgsm.reducer';
import taikhoanmymobi from './taikhoanmymobi.reducer';
import thenap from './thenap.reducer';
import quanlythenap from './quanlythenap.reducer';
import quanlynapcuoc from './quanlynapcuoc.reducer';
import thamso from './thamso.reducer';
import thongkethegio from './thongkethegio.reducer';
import thongkethengay from './thongkethengay.reducer';
import dongtien from './dongtien.reducer';
import machuyentien from './machuyentien.reducer';
import lichsuhoatdong from './lichsuhoatdong.reducer';

const rootReducer = combineReducers({
  auth,
  app,
  permission,
  role,
  account,
  customer,
  bank,
  profile,
  lookup,
  invite,
  giahoantien,
  requestrefund,
  requestrefundreport,
  report,
  report90849332,
  systemaccount,
  quetdata,
  quetdtct,
  quetdatakhonggoi,
  upload,
  division,
  uploadchiadata,
  khr,
  customermessage9084,
  blacklist,
  reportstaffrevenue,
  reportbranchrevenue,
  tinhluong,
  dsdttinhluong,
  lstracuu,
  baodo,
  mobidanhsachdon,
  napmymobifone,
  danhsachcom,
  lsnaptien,
  lssodu,
  cauhinhgsm,
  taikhoanmymobi,
  thenap,
  quanlythenap,
  quanlynapcuoc,
  thamso,
  thongkethegio,
  thongkethengay,
  dongtien,
  machuyentien,
  lichsuhoatdong,
})

const resettableRootReducer = (state, action) => {
  if (action.type === APP_RESET) {
    return rootReducer(undefined, action);
  };
  return rootReducer(state, action);
};

export default resettableRootReducer;
