import {
  DIVISION_COUNT,
  DIVISION_DATA,
} from 'src/actions/division.acction';

const initState = {
  selected: undefined,
  countDivision: 0
}

const divisionReducer = (state = initState, action) => {
  switch (action.type) {
    case DIVISION_DATA: {
      return {
        ...state,
        selected: action.data,
      }
    }
    case DIVISION_COUNT: {
      return {
        ...state,
        countDivision: action.data
      }
    }
    default: {
      return state;
    }
  }
}
export default divisionReducer