import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const CustomerService = {

  create: (payload) => {
    return axios.post('/api/v1/customer', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  save: (payload) => {
    return axios.post('/api/v1/customer/import', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  update: (id, payload) => {
    return axios.put(`/api/v1/customer/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  findAll: (page, count) => {
    return axios.get(`/api/v1/customer?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAssign: (page, count) => {
    return axios.get(`/api/v1/customer/assign?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  newCare: () => {
    return axios.get(`/api/v1/customer/new-care`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/customer/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  countAssign: (id) => {
    return axios.get(`/api/v1/customer/count-assign/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/customer/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  assign: (payload) => {
    return axios.post(`/api/v1/customer/assign`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  care: (id, payload) => {
    return axios.post(`/api/v1/customer/${id}/care`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  history: (page, count, payload) => {
    let url = `/api/v1/care/history?page=${page}&count=${count}`;

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.status) {
      url += `&status=${payload.status}`
    };

    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  setStatus: (id, payload) => {
    return axios.put(`/api/v1/care/history/setStatus/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setAppointmentAt: (id, payload) => {
    return axios.put(`/api/v1/care/history/setAppointmentAt/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },
}