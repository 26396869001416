import React, { useEffect, useState } from 'react';

import { Search as SearchIcon } from 'react-feather';
import { useDispatch } from 'react-redux';
import { AppAction } from 'src/actions/app.action';
import { GiahoantienAction } from 'src/actions/giahoantien.action';
import * as XLSX from 'xlsx';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';

import CreateEditDialog from './CreateEditDialog';

import { saveAs } from 'file-saver';

const GiahoantienToolbar = (props) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState(false);
  const [assign, setAssign] = useState(false);

  const fileInput = React.useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClosePreview = () => {
    fileInput.current.value = null;
    setPreview(false)
  }

  const handleCloseAssign = () => {
    fileInput.current.value = null;
    setAssign(false)
  }

  const handleOpenAssign = () => {
    dispatch(GiahoantienAction.countAssign())
    setAssign(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      ten_goi_cuoc: "",
    };
    dispatch(GiahoantienAction.updateFilter(payload));
  }, []);

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      ten_goi_cuoc: values.ten_goi_cuoc,
    };
    dispatch(GiahoantienAction.updateFilter(payload));
    dispatch(GiahoantienAction.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const handleTengoicuocChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const [values, setValues] = useState({
    ten_goi_cuoc: "",
  });

  const handleImportFile = (e) => {
    e.preventDefault();
    dispatch(AppAction.appLoading(true))
    const files = e.target.files, f = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const temp = dataParse.splice(0).map((c) => ({
        ten_goi_cuoc: c[0],
        gia_gia_han: c[1],
        gia_nang_cap: c[2],
        gia_nang_cap_t2: c[3],
        gia_nang_cap_t3: c[4],
        gia_goi: c[5],
      }));
      let temp1 = {}
      if (temp != null && temp.length > 0) {
        temp.forEach(function (element) {
          temp1[element.ten_goi_cuoc] = element
        })
      }
      temp1 = Object.values(temp1)
      dispatch(GiahoantienAction.saveDataUpload(temp1));
      setPreview(true)
      e.target.value = null;
      alert("Tải file thành công: \"" + files[0].name + "\" (" + temp1.length + " gói)");
      dispatch(GiahoantienAction.list());
    };
    reader.readAsBinaryString(f)
  }

  const exportExcel = async () => {
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        ten_goi_cuoc: values.ten_goi_cuoc,
      };
      dispatch(GiahoantienAction.updateFilter(payload));
      const response = await dispatch(GiahoantienAction.listExport());

      const data = [
        // [
        //   '#', 
        //   'Tên gói cước', 
        //   'Giá gia hạn',
        //   'Giá nâng cấp T1', 
        //   'Giá nâng cấp T2', 
        //   'Giá nâng cấp T3', 
        //   'Giá gói',
        // ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data != null && typeof response.data.values !== 'undefined' && response.data.values != null && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          data.push([
            //count,
            element.ten_goi_cuoc,
            element.gia_gia_han,
            element.gia_nang_cap,
            element.gia_nang_cap_t2,
            element.gia_nang_cap_t3,
            element.gia_goi,
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Gia-hoan-tien.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
    }
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <input
          ref={fileInput}
          type="file"
          onChange={(e) => handleImportFile(e)}
          style={{ display: 'none' }}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={() => fileInput.current.click()}
        >
          Tải dữ liệu
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={exportExcel}
          style={{ marginLeft: 15 }}
          >
          Xuất file
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpen}
          style={{marginLeft: 15}}
        >
          Tạo bản ghi
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="ten_goi_cuoc"
                onChange={handleTengoicuocChange}
                onKeyDown={handleKeyDown}
                value={values.ten_goi_cuoc}
                placeholder="Tìm gói cước"
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={onLookup}
                style={{ height: 56, marginTop: 6, width: "20%", marginLeft: 15 }}
                >
                Tra cứu
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <CreateEditDialog open={open} handleClose={handleClose} type={"CREATE"} />
    </Box>
  )
};

export default GiahoantienToolbar;
