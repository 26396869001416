import React, { useEffect } from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { DivisionAction } from 'src/actions/division.acction';
import { UploadAction } from 'src/actions/upload.acction';

import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
} from '@material-ui/core';

const DivisionListToolbar = (props) => {
  const dispatch = useDispatch()
  const { items } = useSelector(state => state.upload)
  const { countDivision } = useSelector(state => state.division)

  useEffect(() => {
    dispatch(UploadAction.list(1, 1000))
  }, [])

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >

        <Typography variant="h4">
          Chia data cho các chi nhánh
        </Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <Autocomplete
                disablePortal
                onChange={(event, newValue) => {
                  dispatch(DivisionAction.divisionData(newValue))
                }}
                options={items}
                getOptionLabel={(option) => option.filename}
                fullWidth
                renderInput={(params) => <TextField
                  {...params}
                  label="Chọn dữ liệu"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />}
              />
            </Box>
          </CardContent>
        </Card>
        {countDivision >= 0 && <Box sx={{
          mt: 2,
        }}>
          <p>Số lượng khách hàng có thể chia: <strong>{countDivision}</strong></p>
        </Box>}
      </Box>
    </Box>
  )
};

export default DivisionListToolbar;
