import { Helmet } from 'react-helmet';
import CustomerCareHistoryListResults
  from 'src/components/customer-care-history/CustomerCareHistoryListResults';
import CustomerCareHistoryListToolbar
  from 'src/components/customer-care-history/CustomerCareHistoryListToolbar';

import {
  Box,
  Container,
} from '@material-ui/core';

const CustomerCareHistoryList = () => (
  <>
    <Helmet>
      <title>Khách hàng mới</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <CustomerCareHistoryListToolbar />
        <Box sx={{ pt: 3 }}>
          <CustomerCareHistoryListResults />
        </Box>
      </Container>
    </Box>
  </>
);

export default CustomerCareHistoryList;
