import React from 'react';

import { showToast } from 'src/utils/common';

import moment from 'moment';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';

import { LookupAction } from 'src/actions/lookup.action';

const CustomerCareNewInfo = (props) => {
  const dispatch = useDispatch()

  const { newCare } = useSelector(state => state.customer)
  const { balance, pkg } = useSelector(state => state.lookup)

  const onLookupPackage = (phone) => {
    dispatch(LookupAction.lookupPackage(phone))
  }

  const onLookup = (phone) => {
    dispatch(LookupAction.lookupBalance(phone))
  }

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.log('String copied to clipboard:', content);
        showToast('success', `Đã copy: ${content}`)
      })
      .catch((error) => {
        console.error('Unable to copy string:', error);
        showToast('error', `Lỗi không thể copy: ${content}`)
      });
  }

  if (!newCare) {
    return <></>
  }

  return (
    <Card>
      <CardHeader
        title="Thông tin khách hàng"
      />
      <Divider />

      {newCare && <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          p: 2
        }}
      >
        <Table>
          <TableBody>
            <TableRow
              hover
            >
              <TableCell width="40%">
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Thuê bao
                </Typography>
              </TableCell>
              <TableCell>
                <Button style={{padding: 0}} onClick={() => copyToClipboard(newCare.phone.length == 9 ? '0' + newCare.phone : newCare.phone)}>
                  {newCare.phone.length == 9 ? '0' + newCare.phone : newCare.phone}
                </Button>
              </TableCell>
            </TableRow>
            <TableRow
              hover
            >
              <TableCell width="40%">
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Tài khoản chính
                </Typography>
              </TableCell>
              <TableCell>
                {balance ? balance.balance : "Nhấp 'Tra cứu' để lấy số dư"}
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => onLookup(newCare.phone)}
                  style={{ height: 32, width: 100, marginLeft: 15 }}
                >
                  Tra cứu
                </Button>
              </TableCell>
            </TableRow>
            <TableRow
              hover
            >
              <TableCell width="40%">
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Loại thuê bao
                </Typography>
              </TableCell>
              <TableCell>
                {newCare.type}
              </TableCell>
            </TableRow>
            <TableRow
              hover
            >
              <TableCell width="40%">
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Gói cước hiện tại
                </Typography>
              </TableCell>
              <TableCell>
                {newCare.package}
              </TableCell>
            </TableRow>
            <TableRow
              hover
            >
              <TableCell width="40%">
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Ngày đăng ký
                </Typography>
              </TableCell>
              <TableCell>
                {moment(newCare.packageRegisterAt).format("DD-MM-YYYY HH:mm:ss")}
              </TableCell>
            </TableRow>
            <TableRow
              hover
            >
              <TableCell width="40%">
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Ngày hết hạn
                </Typography>
              </TableCell>
              <TableCell style={{ alignItems: 'center' }}>
                {moment(newCare.packageExpiredAt).format("DD-MM-YYYY HH:mm:ss")}
              </TableCell>
            </TableRow>
            <TableRow
              hover
            >
              <TableCell width="40%">
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Trạng thái
                </Typography>
              </TableCell>
              <TableCell>
                {newCare.state}
              </TableCell>
            </TableRow>
            <TableRow
              hover
            >
              <TableCell width="40%">
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Chu kỳ hiện tại
                </Typography>
              </TableCell>
              <TableCell>
                {newCare.packageCycle}
              </TableCell>
            </TableRow>
            <TableRow
              hover
            >
              <TableCell width="40%">
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Số chu kỳ miễn phí còn lại
                </Typography>
              </TableCell>
              <TableCell>
                {newCare.so_chu_ky_mien_phi_con_lai}
              </TableCell>
            </TableRow>
            <TableRow
              hover
            >
              <TableCell >
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Gói cước có thể đăng ký
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color="textPrimary"
                  variant="body1"
                  style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
                >
                  {newCare.packagesCanRegister}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          p: 2
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={props.onCare}
        >
          Kết quả chăm sóc
        </Button>

        {1 < 0 && <Button sx={{ mx: 1 }} onClick={props.onAlert} color="error">
          Báo xấu
        </Button>}
      </Box>
    </Card>
  )
};

export default CustomerCareNewInfo;
