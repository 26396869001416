import { CustomerStatus } from './constants';

export const getCustomerStatus = (status) => {

  let filter = CustomerStatus.filter((st) => st.code === status);
  if (filter.length > 0) {
    return filter[0].name
  }
  return "Bình thường"

}

export const toQuery = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}


export const isGrantPermission = (allows, permissions) => {
  const granted = allows.filter((per) => permissions.includes(per.code)).length > 0
  return granted;
}