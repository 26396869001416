import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const LssoduService = {

  approve: (id, payload) => {
    return axios.put(`/api/v1/lssodu/approve/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setTrangThai: (id, payload) => {
    return axios.put(`/api/v1/lssodu/setTrangThai/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  create: (payload) => {
    return axios.post('/api/v1/lssodu', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  save: (payload) => {
    return axios.post('/api/v1/lssodu/import', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  update: (id, payload) => {
    return axios.put(`/api/v1/lssodu/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  findAll: (page, count, payload) => {
    let url = `/api/v1/lssodu?page=${page}&count=${count}`;

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.so_dien_thoai) {
      url += `&so_dien_thoai=${payload.so_dien_thoai}`
    };

    if (payload.loai_giao_dich) {
      url += `&loai_giao_dich=${payload.loai_giao_dich}`
    };

    if (payload.reason) {
      url += `&reason=${payload.reason}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAllByOrder: (id, page, count, payload) => {
    let url = `/api/v1/lssodu/findAllByOrder/${id}?page=${page}&count=${count}`;

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAssign: (page, count) => {
    return axios.get(`/api/v1/lssodu/assign?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/lssodu/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findPosition: (position) => {
    return axios.get(`/api/v1/lssodu/findPosition/${position}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  countAssign: () => {
    return axios.get(`/api/v1/lssodu/count-assign`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/lssodu/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  assign: (payload) => {
    return axios.post(`/api/v1/lssodu/assign`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  history: (payload) => {
    return axios.get(`/api/v1/care/history?${toQuery(payload)}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  }
}