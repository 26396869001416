import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { RequestrefundAction } from 'src/actions/requestrefund.action';
import { AccountAction } from 'src/actions/account.acction';
import { GiahoantienAction } from 'src/actions/giahoantien.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    Select,
    MenuItem,
    InputLabel,
} from '@material-ui/core';

import md5 from 'md5';

import numeral from 'numeral';

const CreateEditDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.requestrefund)
    const { info, role } = useSelector(state => state.profile)
    const [number_phone, setThueBao] = useState("")
    const [number_type, setLoaiThueBao] = useState("")
    const [package_code, setTenGoiCuoc] = useState("")
    const [request_type, setYeuCau] = useState("")
    const [request_method, setHinhThuc] = useState("")
    const [other_refund, setHoanThem] = useState("")
    const [other_reason, setLyDoHoanThem] = useState("")
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [prices, setPrices] = useState({
        gia_gia_han: 0,
        gia_nang_cap: 0,
        gia_nang_cap_t2: 0,
        gia_nang_cap_t3: 0,
    });
    const isQCMBorHNIMB = info.username.trim().startsWith('QCMB') || info.username.trim().startsWith('HNIMB') || info.username.trim().startsWith('HNI1MB');

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
        dispatch(PermissionAction.list(0, 100))
    }, [])

    const onPressPositive = async () => {
        const edited_other_refund = other_refund.replace(/,/g, '');

        if (number_phone === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }
        if (number_type === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }
        if (package_code === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }
        if (request_type === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }
        if (request_method === "") {
            alert('[Lỗi]\nCó trường bắt buộc chưa được nhập hoặc chưa được chọn')
            return;
        }

        setIsButtonDisabled(true);

        try {
            const currentOriginUrl = window.location.origin;
            const response = await dispatch(RequestrefundAction.createHoanTienGoiCuoc({ number_phone, number_type, package_code, request_type, request_method, other_refund: edited_other_refund, other_reason, currentOriginUrl }));
            
            // if (typeof response !== 'undefined' && response != null && response.status != null && response.status == "success" && response.data != null)
            // {
            //     const responseCreate24hOrder = await dispatch(RequestrefundAction.create24hOrder({ refund_price: response.data.refund_price, request_id: response.data.id, number_phone: number_phone, number_type: number_type }));
            //     console.log(responseCreate24hOrder);
            // }

            if (response) setIsButtonDisabled(false);

            if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
            else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
            
            resetState();
            props.handleClose();
        } catch (error) {
            setIsButtonDisabled(false);
            if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
        }
    }

    const resetState = () => {
        setThueBao("")
        setLoaiThueBao("")
        setTenGoiCuoc("")
        setYeuCau("")
        setHinhThuc("")
        setHoanThem("")
        setLyDoHoanThem("")
    }

    const setTenGoiCuoc1 = async (ten_goi_cuoc) => {
        setTenGoiCuoc(ten_goi_cuoc)
        const giaHoanTien = await dispatch(GiahoantienAction.findPackage(ten_goi_cuoc));
        if (giaHoanTien && typeof giaHoanTien.data !== 'undefined' && giaHoanTien.data) {
            setPrices({
                gia_gia_han: giaHoanTien.data.gia_gia_han,
                gia_nang_cap: giaHoanTien.data.gia_nang_cap,
                gia_nang_cap_t2: giaHoanTien.data.gia_nang_cap_t2,
                gia_nang_cap_t3: giaHoanTien.data.gia_nang_cap_t3,
            });
        }
        console.log(info)
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Hoàn tiền gói cước</DialogTitle>
            <DialogContent style={{ width: 600, paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Thuê bao"
                            name="number_phone"
                            onChange={(e) => setThueBao(e.target.value)}
                            value={number_phone}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Select fullWidth variant="outlined" name="number_type" value={number_type || -99} onChange={(e) => setLoaiThueBao(e.target.value)}>
                            <MenuItem value="-99" selected>Chọn loại thuê bao...</MenuItem>
                            <MenuItem value="1">Trả trước</MenuItem>
                            <MenuItem value="2">Trả sau</MenuItem>
                        </Select>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Tên gói cước"
                            name="package_code"
                            onChange={(e) => setTenGoiCuoc1(e.target.value)}
                            value={package_code}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Select fullWidth variant="outlined" name="request_type" value={request_type || -99} onChange={(e) => setYeuCau(e.target.value)}>
                            <MenuItem value="-99" selected>Chọn yêu cầu...</MenuItem>
                            <MenuItem value="1">Hoàn trước</MenuItem>
                            <MenuItem value="2">Hoàn sau</MenuItem>
                            {1 < 0 && <MenuItem value="3">Không hoàn</MenuItem>}
                            <MenuItem value="4">Hoàn thêm</MenuItem>
                        </Select>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Select fullWidth variant="outlined" name="request_method" value={request_method || -99} onChange={(e) => setHinhThuc(e.target.value)}>
                            <MenuItem value="-99" selected>Chọn hình thức...</MenuItem>
                            <MenuItem value="1">Gia hạn ({numeral(prices.gia_gia_han).format('0,0')})</MenuItem>
                            {isQCMBorHNIMB && <MenuItem value="3">Nâng cấp ({numeral(prices.gia_nang_cap_t2).format('0,0')})</MenuItem>}
                            {!isQCMBorHNIMB && <MenuItem value="2">Nâng cấp T1 ({numeral(prices.gia_nang_cap).format('0,0')})</MenuItem>}
                            {!isQCMBorHNIMB && <MenuItem value="3">Nâng cấp T2 ({numeral(prices.gia_nang_cap_t2).format('0,0')})</MenuItem>}
                            {!isQCMBorHNIMB && <MenuItem value="4">Nâng cấp T3 ({numeral(prices.gia_nang_cap_t3).format('0,0')})</MenuItem>}
                        </Select>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Hoàn thêm"
                            name="other_refund"
                            onChange={(e) => {let value = e.target.value; value = numeral(value).format('0,0'); setHoanThem(value)}}
                            value={other_refund}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Lý do hoàn thêm"
                            name="other_reason"
                            onChange={(e) => setLyDoHoanThem(e.target.value)}
                            value={other_reason}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={onPressPositive} disabled={isButtonDisabled}>Gửi yêu cầu</Button>
            </DialogActions>
        </Dialog >
    );
}

export default CreateEditDialog;