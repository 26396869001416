import {
  MOBIDANHSACHDON_COUNT_ASSIGN_SUCCESS,
  MOBIDANHSACHDON_HISTORY_LIST_SUCCESS,
  MOBIDANHSACHDON_IMPORT_SUCCESS,
  MOBIDANHSACHDON_LIST_SUCCESS,
  MOBIDANHSACHDON_NEW_CARE_SUCCESS,
  MOBIDANHSACHDON_ONE_SUCCESS,
  MOBIDANHSACHDONCHITIET_ONE_SUCCESS,
  MOBIDANHSACHDON_UPDATE_FILTER,
  MOBIDANHSACHDONCHITIET_LIST_SUCCESS,
  MOBIDANHSACHDON_THONG_KE_SUCCESS,
  MOBIDANHSACHDON_DON_CHO_HE_THONG_SUCCESS,
  MOBIDANHSACHDON_UPDATE_FILTER_DON_CHO_HE_THONG,
} from 'src/actions/mobidanhsachdon.action';
import { LOOKUP_CLEANER } from 'src/actions/lookup.action';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import dayjs from 'dayjs';

const today = new Date();

const initState = {
  filter: {
    message: '',
    startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)),
    endDate: dayjs(today),
    page: 0,
    limit: DEFAULT_ROW_PER_PAGE,
  },
  donchohethongfilter: {
    donchohethongpage: 0,
    donchohethonglimit: DEFAULT_ROW_PER_PAGE,
  },
  items: [],
  thongkeitems: [],
  donchohethongitems: [],
  donchohethongtotal: 0,
  total: 0,
  mobidanhsachdonchitiet_items: [],
  mobidanhsachdonchitiet_total: 0,
  temp: [],
  selected: undefined,
  selectedChiTiet: undefined,
  countAssign: 0,
  history: [],
  totalHistory: 0,
  newCare: undefined,
  canCare: 0
}

const mobidanhsachdonReducer = (state = initState, action) => {
  switch (action.type) {
    case MOBIDANHSACHDON_UPDATE_FILTER:  {
      if (action.data && typeof action.data !== 'undefined' && action.data && typeof state.filter !== 'undefined' && state.filter) {
        if (action.data.page == 'no_update' && typeof state.filter.page !== 'undefined') action.data.page = state.filter.page;
        if (action.data.limit == 'no_update' && typeof state.filter.limit !== 'undefined') action.data.limit = state.filter.limit;
      }
      return {
        ...state,
        filter: action.data,
      }
    }
    case MOBIDANHSACHDON_UPDATE_FILTER_DON_CHO_HE_THONG:  {
      if (action.data && typeof action.data !== 'undefined' && action.data && typeof state.donchohethongfilter !== 'undefined' && state.donchohethongfilter) {
        if (action.data.donchohethongpage == 'no_update' && typeof state.donchohethongfilter.donchohethongpage !== 'undefined') action.data.donchohethongpage = state.donchohethongfilter.donchohethongpage;
        if (action.data.donchohethonglimit == 'no_update' && typeof state.donchohethongfilter.donchohethonglimit !== 'undefined') action.data.donchohethonglimit = state.donchohethongfilter.donchohethonglimit;
      }
      return {
        ...state,
        donchohethongfilter: action.data,
      }
    }
    case MOBIDANHSACHDONCHITIET_LIST_SUCCESS: {
      return {
        ...state,
        mobidanhsachdonchitiet_items: action.data.items,
        mobidanhsachdonchitiet_total: action.data.total,
        mobidanhsachdonchitiet_tong_can_nap: action.data.tong_can_nap,
        mobidanhsachdonchitiet_tong_da_nap: action.data.tong_da_nap,
        mobidanhsachdonchitiet_tong_huy_nap: action.data.tong_huy_nap,
        mobidanhsachdonchitiet_tong_that_bai: action.data.tong_that_bai,
        mobidanhsachdonchitiet_tong_ton_nap: action.data.tong_ton_nap,
      }
    }
    case MOBIDANHSACHDON_LIST_SUCCESS: {
      return {
        ...state,
        items: action.data.items,
        total: action.data.total,
        tong_can_nap: action.data.tong_can_nap,
        tong_da_nap: action.data.tong_da_nap,
        tong_huy_nap: action.data.tong_huy_nap,
        tong_that_bai: action.data.tong_that_bai,
        tong_ton_nap: action.data.tong_ton_nap,
      }
    }
    case MOBIDANHSACHDON_THONG_KE_SUCCESS: {
      return {
        ...state,
        thongkeitems: action.data.items,
      }
    }
    case MOBIDANHSACHDON_DON_CHO_HE_THONG_SUCCESS: {
      return {
        ...state,
        donchohethongitems: action.data.items,
        donchohethongtotal: action.data.total,
        donchohethong_tong_can_nap: action.data.tong_can_nap,
        donchohethong_tong_da_nap: action.data.tong_da_nap,
        donchohethong_tong_huy_nap: action.data.tong_huy_nap,
        donchohethong_tong_that_bai: action.data.tong_that_bai,
        donchohethong_tong_ton_nap: action.data.tong_ton_nap,
      }
    }
    case MOBIDANHSACHDON_ONE_SUCCESS: {
      return {
        ...state,
        selected: action.data
      }
    }
    case MOBIDANHSACHDONCHITIET_ONE_SUCCESS: {
      return {
        ...state,
        selectedChiTiet: action.data
      }
    }
    case MOBIDANHSACHDON_NEW_CARE_SUCCESS: {
      return {
        ...state,
        newCare: action.data.newCare,
        canCare: action.data.canCare,
      }
    }
    case MOBIDANHSACHDON_IMPORT_SUCCESS: {
      return {
        ...state,
        temp: action.data,
      }
    }
    case MOBIDANHSACHDON_COUNT_ASSIGN_SUCCESS: {
      return {
        ...state,
        countAssign: action.data,
      }
    }
    case MOBIDANHSACHDON_HISTORY_LIST_SUCCESS: {
      return {
        ...state,
        history: action.data.items,
        totalHistory: action.data.total,
      }
    }
    case LOOKUP_CLEANER: {
      return initState
    }
    default: {
      return state;
    }
  }
}
export default mobidanhsachdonReducer