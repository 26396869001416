import { Helmet } from 'react-helmet';
import MobifoneNapDonDetail
  from 'src/components/MobifoneNapDon-detail/MobifoneNapDonDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

//import MobifoneNapDonToolbar from '../components/MobifoneNapDon-detail/MobifoneNapDonToolbar';

const MobifoneNapDon = () => (
  <>
    <Helmet>
      <title>Mobifone - Nạp đơn</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "500px", width: "100%"}}>
        {/* <MobifoneNapDonToolbar /> */}
        <Box sx={{ pt: 3 }}>
          <MobifoneNapDonDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default MobifoneNapDon;
