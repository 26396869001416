import { MobidanhsachdonService } from 'src/services/mobidanhsachdon.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const MOBIDANHSACHDON_NEW_CARE_SUCCESS = 'MOBIDANHSACHDON:NEW_CARE_SUCCESS'
export const MOBIDANHSACHDON_LIST_SUCCESS = 'MOBIDANHSACHDON:FIND_ALL_SUCCESS'
export const MOBIDANHSACHDON_LIST_FAILD = 'MOBIDANHSACHDON:FIND_ALL_FAILD'
export const MOBIDANHSACHDON_COUNT_ASSIGN_SUCCESS = 'MOBIDANHSACHDON:COUNT_ASSIGN_SUCCESS'
export const MOBIDANHSACHDON_COUNT_ASSIGN_FAILD = 'MOBIDANHSACHDON:COUNT_ASSIGN_FAILD'

export const MOBIDANHSACHDON_CREATE_SUCCESS = 'MOBIDANHSACHDON:CREATE_SUCCESS'
export const MOBIDANHSACHDON_CREATE_FAILD = 'MOBIDANHSACHDON:CREATE_FAILD'

export const MOBIDANHSACHDONCHITIET_CREATE_SUCCESS = 'MOBIDANHSACHDONCHITIET:CREATE_SUCCESS'
export const MOBIDANHSACHDONCHITIET_CREATE_FAILD = 'MOBIDANHSACHDONCHITIET:CREATE_FAILD'

export const MOBIDANHSACHDON_IMPORT_SUCCESS = 'MOBIDANHSACHDON:IMPORT_SUCCESS'
export const MOBIDANHSACHDON_SAVE_SUCCESS = 'MOBIDANHSACHDON:SAVE_SUCCESS'
export const MOBIDANHSACHDON_SAVE_FAILD = 'MOBIDANHSACHDON:SAVE_SUCCESS'

export const MOBIDANHSACHDON_DEL_SUCCESS = 'MOBIDANHSACHDON:DEL_SUCCESS'
export const MOBIDANHSACHDON_DEL_FAILD = 'MOBIDANHSACHDON:DEL_FAILD'

export const MOBIDANHSACHDON_ONE_SUCCESS = 'MOBIDANHSACHDON:FIND_ONE_SUCCESS'
export const MOBIDANHSACHDON_ONE_FAILD = 'MOBIDANHSACHDON:FIND_ONE_FAILD'

export const MOBIDANHSACHDONCHITIET_ONE_SUCCESS = 'MOBIDANHSACHDONCHITIET:FIND_ONE_SUCCESS'
export const MOBIDANHSACHDONCHITIET_ONE_FAILD = 'MOBIDANHSACHDONCHITIET:FIND_ONE_FAILD'

export const MOBIDANHSACHDON_HISTORY_LIST_SUCCESS = 'MOBIDANHSACHDON:HISTORY_FIND_ALL_SUCCESS'
export const MOBIDANHSACHDON_HISTORY_LIST_FAILD = 'MOBIDANHSACHDON:HISTORY_FIND_ALL_FAILD'

export const MOBIDANHSACHDON_UPDATE_FILTER = 'MOBIDANHSACHDON:UPDATE_FILTER'

export const MOBIDANHSACHDON_UPDATE_FILTER_DON_CHO_HE_THONG = 'MOBIDANHSACHDON:UPDATE_FILTER_DON_CHO_HE_THONG'

export const MOBIDANHSACHDONCHITIET_LIST_SUCCESS = 'MOBIDANHSACHDONCHITIET:FIND_ALL_SUCCESS'
export const MOBIDANHSACHDONCHITIET_LIST_FAILD = 'MOBIDANHSACHDONCHITIET:FIND_ALL_FAILD'

export const MOBIDANHSACHDON_LIST_EXPORT_SUCCESS = 'MOBIDANHSACHDON:FIND_ALL_EXPORT_SUCCESS'
export const MOBIDANHSACHDON_LIST_EXPORT_FAILD = 'MOBIDANHSACHDON:FIND_ALL_EXPORT_FAILD'

export const MOBIDANHSACHDON_THONG_KE_SUCCESS = 'MOBIDANHSACHDON:THONG_KE_SUCCESS'
export const MOBIDANHSACHDON_THONG_KE_FAILD = 'MOBIDANHSACHDON:THONG_KE_FAILD'

export const MOBIDANHSACHDON_DON_CHO_HE_THONG_SUCCESS = 'MOBIDANHSACHDON:DON_CHO_HE_THONG_SUCCESS'
export const MOBIDANHSACHDON_DON_CHO_HE_THONG_FAILD = 'MOBIDANHSACHDON:DON_CHO_HE_THONG_FAILD'

export const MobidanhsachdonAction = {
    list,
    findAllByOrder,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
    findOneChiTietDon,
    approve,
    setTrangThai,
    updateFilter,
    updateFilterDonchohethong,
    listExport,
    chayTiep,
    chayTiepChiTiet,
    daNap,
    daNapChiTiet,
    huyDon,
    huyDonChiTiet,
    tamDung,
    tamDungChiTiet,
    choNap,
    choNapChiTiet,
    dangNap,
    dangNapChiTiet,
    thatBai,
    thatBaiChiTiet,
    hoanThanh,
    batDauDon,
    batDauDonChiTiet,
    duaDon,
    duaDonChiTiet,
    thongKe,
    donChoHeThong,
    updateBoiSo,
    updateBoiSoChiTiet,
}

function updateFilter(payload) {
    return { type: MOBIDANHSACHDON_UPDATE_FILTER, data: payload }
}

function updateFilterDonchohethong(payload) {
    return { type: MOBIDANHSACHDON_UPDATE_FILTER_DON_CHO_HE_THONG, data: payload }
}

function listExport() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { mobidanhsachdon } = getState();

            const { filter } = mobidanhsachdon;

            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.listExport(filter)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    }
    function success(data) { return { type: MOBIDANHSACHDON_LIST_EXPORT_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_LIST_EXPORT_FAILD, error } }
}

function thongKe(operator = null) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const { mobidanhsachdon } = getState();
            const { filter } = mobidanhsachdon;
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.thongKe({...filter, operator})
            .then(response => {
                // Handle the successful response
                dispatch(success({
                    items: response.data.values,
                }));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_THONG_KE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_THONG_KE_FAILD, error } }
}

function donChoHeThong(operator = null) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { mobidanhsachdon } = getState();
        const { donchohethongfilter: filter } = mobidanhsachdon;
        const page = typeof filter.donchohethongpage !== 'undefined' ? filter.donchohethongpage : 0;
        const limit = typeof filter.donchohethonglimit !== 'undefined' ? filter.donchohethonglimit : 10;
        MobidanhsachdonService.donChoHeThong({...filter, operator}).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            } else {
                const oldItems = mobidanhsachdon.donchohethongitems;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MOBIDANHSACHDON_DON_CHO_HE_THONG_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_DON_CHO_HE_THONG_FAILD, error } }
}

function chayTiep(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.chayTiep(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function daNap(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.daNap(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function huyDon(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.huyDon(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function tamDung(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.tamDung(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function choNap(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.choNap(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function dangNap(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.dangNap(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function thatBai(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.thatBai(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function hoanThanh(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.hoanThanh(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function batDauDon(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.batDauDon(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function duaDon(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.duaDon(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function updateBoiSo(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.updateBoiSo(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function updateBoiSoChiTiet(id, id_don_tong, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.updateBoiSoChiTiet(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.findAllByOrder(id_don_tong));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function duaDonChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.duaDonChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.findAllByOrder(id_don_tong));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function batDauDonChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.batDauDonChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.findAllByOrder(id_don_tong));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function chayTiepChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.chayTiepChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.findAllByOrder(id_don_tong));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDONCHITIET_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDONCHITIET_CREATE_FAILD, error } }
}

function daNapChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.daNapChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.findAllByOrder(id_don_tong));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDONCHITIET_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDONCHITIET_CREATE_FAILD, error } }
}

function huyDonChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.huyDonChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.findAllByOrder(id_don_tong));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDONCHITIET_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDONCHITIET_CREATE_FAILD, error } }
}

function tamDungChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.tamDungChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.findAllByOrder(id_don_tong));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDONCHITIET_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDONCHITIET_CREATE_FAILD, error } }
}

function choNapChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.choNapChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.findAllByOrder(id_don_tong));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function dangNapChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.dangNapChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.findAllByOrder(id_don_tong));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function thatBaiChiTiet(id, id_don_tong) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.thatBaiChiTiet(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.findAllByOrder(id_don_tong));
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function approve(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.approve(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function setTrangThai(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.setTrangThai(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        MobidanhsachdonService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MOBIDANHSACHDON_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { mobidanhsachdon } = getState()
        dispatch(AppAction.appLoading(true));
        MobidanhsachdonService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...mobidanhsachdon.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MOBIDANHSACHDON_LIST_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_LIST_FAILD, error } }
}

function findAllByOrder(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { mobidanhsachdon } = getState();
        const page = mobidanhsachdon && typeof mobidanhsachdon.filter !== 'undefined' && mobidanhsachdon.filter && typeof mobidanhsachdon.filter.page !== 'undefined' ? mobidanhsachdon.filter.page : 0;
        const limit = mobidanhsachdon && typeof mobidanhsachdon.filter !== 'undefined' && mobidanhsachdon.filter && typeof mobidanhsachdon.filter.limit !== 'undefined' ? mobidanhsachdon.filter.limit : 10;
        MobidanhsachdonService.findAllByOrder(id, page, limit, mobidanhsachdon.filter).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            } else {
                const oldItems = mobidanhsachdon.mobidanhsachdonchitiet_items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MOBIDANHSACHDONCHITIET_LIST_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDONCHITIET_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { mobidanhsachdon } = getState();
        const page = mobidanhsachdon && typeof mobidanhsachdon.filter !== 'undefined' && mobidanhsachdon.filter && typeof mobidanhsachdon.filter.page !== 'undefined' ? mobidanhsachdon.filter.page : 0;
        const limit = mobidanhsachdon && typeof mobidanhsachdon.filter !== 'undefined' && mobidanhsachdon.filter && typeof mobidanhsachdon.filter.limit !== 'undefined' ? mobidanhsachdon.filter.limit : 10;
        MobidanhsachdonService.findAll(page, limit, mobidanhsachdon.filter).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            } else {
                const oldItems = mobidanhsachdon.items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count,
                    tong_can_nap: rs.data.tong_can_nap,
                    tong_da_nap: rs.data.tong_da_nap,
                    tong_huy_nap: rs.data.tong_huy_nap,
                    tong_that_bai: rs.data.tong_that_bai,
                    tong_ton_nap: rs.data.tong_ton_nap,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MOBIDANHSACHDON_LIST_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: MOBIDANHSACHDON_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            MobidanhsachdonService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(MobidanhsachdonAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: MOBIDANHSACHDON_SAVE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_SAVE_FAILD, error } }
}

function create(payload) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(AppAction.appLoading(true));
            // Perform your asynchronous operation (e.g., API call)
            MobidanhsachdonService.create(payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(MobidanhsachdonAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            }).finally(() => {
                dispatch(AppAction.appLoading(false));
            });
        });
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function update(id, payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        MobidanhsachdonService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(MobidanhsachdonAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MOBIDANHSACHDON_CREATE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_CREATE_FAILD, error } }
}

function del(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        MobidanhsachdonService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(MobidanhsachdonAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MOBIDANHSACHDON_DEL_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        MobidanhsachdonService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MOBIDANHSACHDON_ONE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_ONE_FAILD, error } }
}

function findOneChiTietDon(id) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        MobidanhsachdonService.findOneChiTietDon(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MOBIDANHSACHDONCHITIET_ONE_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDONCHITIET_ONE_FAILD, error } }
}

function assign(payload) {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        MobidanhsachdonService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(MobidanhsachdonAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { mobidanhsachdon } = getState()
        dispatch(AppAction.appLoading(true));
        MobidanhsachdonService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...mobidanhsachdon.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: MOBIDANHSACHDON_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: MOBIDANHSACHDON_HISTORY_LIST_FAILD, error } }
}