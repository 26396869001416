import { toQuery } from 'src/utils/utils';

import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const MobidanhsachdonService = {

  thongKe: (payload) => {
    let url = `/api/v1/mobidanhsachdon/thongKe?`;

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.ma_don) {
      url += `&ma_don=${payload.ma_don}`
    };

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.kenh_yeu_cau) {
      url += `&kenh_yeu_cau=${payload.kenh_yeu_cau}`
    };

    if (payload.nha_mang) {
      url += `&nha_mang=${payload.nha_mang}`
    };

    if (payload.trang_thai) {
      url += `&trang_thai=${payload.trang_thai}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  donChoHeThong: (payload) => {
    let url = `/api/v1/mobidanhsachdon/donChoHeThong?page=${payload.donchohethongpage}&count=${payload.donchohethonglimit}`;

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.ma_don) {
      url += `&ma_don=${payload.ma_don}`
    };

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.kenh_yeu_cau) {
      url += `&kenh_yeu_cau=${payload.kenh_yeu_cau}`
    };

    if (payload.nha_mang) {
      //url += `&nha_mang=${payload.nha_mang}`
      url += `&nha_mang=${payload.operator.toUpperCase()}`
    };

    if (payload.boi_so) {
      url += `&boi_so=${payload.boi_so}`
    };

    if (payload.operator) {
      url += `&operator=${payload.operator}`
    };

    if (payload.trang_thai) {
      url += `&trang_thai=${payload.trang_thai}`
    };

    if (payload.dua) {
      url += `&dua=${payload.dua}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  chayTiep: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/chayTiep/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  chayTiepChiTiet: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/chayTiepChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  daNap: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/daNap/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  daNapChiTiet: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/daNapChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  huyDon: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/huyDon/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  huyDonChiTiet: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/huyDonChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  tamDung: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/tamDung/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  tamDungChiTiet: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/tamDungChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  choNap: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/choNap/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  choNapChiTiet: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/choNapChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  dangNap: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/dangNap/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  dangNapChiTiet: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/dangNapChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  thatBai: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/thatBai/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  thatBaiChiTiet: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/thatBaiChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  hoanThanh: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/hoanThanh/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  batDauDon: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/batDauDon/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  batDauDonChiTiet: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/batDauDonChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  duaDon: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/duaDon/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  duaDonChiTiet: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/duaDonChiTiet/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  updateBoiSo: (id, payload) => {
    return axios.post(`/api/v1/mobidanhsachdon/updateBoiSo/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  updateBoiSoChiTiet: (id, payload) => {
    return axios.post(`/api/v1/mobidanhsachdon/updateBoiSoChiTiet/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  approve: (id, payload) => {
    return axios.put(`/api/v1/mobidanhsachdon/approve/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  setTrangThai: (id, payload) => {
    return axios.put(`/api/v1/mobidanhsachdon/setTrangThai/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  create: (payload) => {
    return axios.post('/api/v1/mobidanhsachdon', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  save: (payload) => {
    return axios.post('/api/v1/mobidanhsachdon/import', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  update: (id, payload) => {
    return axios.put(`/api/v1/mobidanhsachdon/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  findAll: (page, count, payload) => {
    let url = `/api/v1/mobidanhsachdon?page=${page}&count=${count}`;

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.ma_don) {
      url += `&ma_don=${payload.ma_don}`
    };

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.kenh_yeu_cau) {
      url += `&kenh_yeu_cau=${payload.kenh_yeu_cau}`
    };

    if (payload.nha_mang) {
      url += `&nha_mang=${payload.nha_mang}`
    };

    if (payload.trang_thai) {
      url += `&trang_thai=${payload.trang_thai}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAllByOrder: (id, page, count, payload) => {
    let url = `/api/v1/mobidanhsachdon/findAllByOrder/${id}?page=${page}&count=${count}`;

    if (payload.username) {
      url += `&username=${payload.username}`
    };

    if (payload.ma_don) {
      url += `&ma_don=${payload.ma_don}`
    };

    if (payload.phone) {
      url += `&phone=${payload.phone}`
    };

    if (payload.kenh_yeu_cau) {
      url += `&kenh_yeu_cau=${payload.kenh_yeu_cau}`
    };

    if (payload.nha_mang) {
      url += `&nha_mang=${payload.nha_mang}`
    };

    if (payload.trang_thai) {
      url += `&trang_thai=${payload.trang_thai}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  listExport: (payload) => {
    let url = `/api/v1/mobidanhsachdon/listExport?page=${payload.page}&count=${payload.limit}`;

    if (payload.ma_don) {
      url += `&ma_don=${payload.ma_don}`
    };
    
    if (payload.message) {
      url += `&phone=${payload.phone}`
    };

    if (payload.startDate && payload.endDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`
    }
    
    return axios.get(url, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findAssign: (page, count) => {
    return axios.get(`/api/v1/mobidanhsachdon/assign?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findOneChiTietDon: (id) => {
    return axios.get(`/api/v1/mobidanhsachdon/findOneChiTietDon/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  findPosition: (position) => {
    return axios.get(`/api/v1/mobidanhsachdon/findPosition/${position}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  countAssign: () => {
    return axios.get(`/api/v1/mobidanhsachdon/count-assign`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/mobidanhsachdon/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  assign: (payload) => {
    return axios.post(`/api/v1/mobidanhsachdon/assign`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  history: (payload) => {
    return axios.get(`/api/v1/care/history?${toQuery(payload)}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  }
}