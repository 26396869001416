import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { AccountAction } from 'src/actions/account.acction';
import { ProfileAction } from 'src/actions/profile.action';
import { RoleAction } from 'src/actions/role.acction';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Select,
    MenuItem,
} from '@material-ui/core';
import { SettingAction } from 'src/actions/setting.action';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';
import numeral from 'numeral';

const AccountEditSoDuDialog = (props) => {
    const dispatch = useDispatch()
    const { items } = useSelector(state => state.role)
    const { leaders, brands, info, role } = useSelector(state => state.profile)
    const { selected } = useSelector(state => state.account)
    const [showLeader, setShowLeader] = useState(false);
    const [showBrand, setShowBrand] = useState(false);
    const [selectedRole, setSelectedRole] = useState(undefined)

    const [nha_mang_dang_chon, setNhaMangDangChon] = useState("mobi")

    const [chiet_khau_smart_vi, setChietKhauSmartVi] = useState("")
    const [chiet_khau_tkc, setChietKhauTkc] = useState("")
    const [chiet_khau_dichvu, setChietKhauDichVu] = useState("")
    const [chiet_khau_bunca, setChietKhauBunCa] = useState("")
    const [chiet_khau_napho, setChietKhauNapHo] = useState("")
    const [chiet_khau_mymobifone, setChietKhauMymobifone] = useState("")
    const [chiet_khau_gsmmobifone, setChietKhauGsmmobifone] = useState("")

    const [chiet_khau_smart_vi_vina, setChietKhauSmartVi_vina] = useState("")
    const [chiet_khau_tkc_vina, setChietKhauTkc_vina] = useState("")
    const [chiet_khau_dichvu_vina, setChietKhauDichVu_vina] = useState("")
    const [chiet_khau_bunca_vina, setChietKhauBunCa_vina] = useState("")
    const [chiet_khau_napho_vina, setChietKhauNapHo_vina] = useState("")
    const [chiet_khau_mymobifone_vina, setChietKhauMymobifone_vina] = useState("")
    const [chiet_khau_gsmmobifone_vina, setChietKhauGsmmobifone_vina] = useState("")

    const [chiet_khau_smart_vi_viettel, setChietKhauSmartVi_viettel] = useState("")
    const [chiet_khau_tkc_viettel, setChietKhauTkc_viettel] = useState("")
    const [chiet_khau_dichvu_viettel, setChietKhauDichVu_viettel] = useState("")
    const [chiet_khau_bunca_viettel, setChietKhauBunCa_viettel] = useState("")
    const [chiet_khau_napho_viettel, setChietKhauNapHo_viettel] = useState("")
    const [chiet_khau_mymobifone_viettel, setChietKhauMymobifone_viettel] = useState("")
    const [chiet_khau_gsmmobifone_viettel, setChietKhauGsmmobifone_viettel] = useState("")

    const [values, setValues] = useState({
        so_du: "",

        chiet_khau_smart_vi: "0",
        chiet_khau_tkc: "0",
        chiet_khau_dichvu: "0",
        chiet_khau_bunca: "0",
        chiet_khau_napho: "0",
        chiet_khau_mymobifone: "0",
        chiet_khau_gsmmobifone: "0",

        chiet_khau_smart_vi_vina: "0",
        chiet_khau_tkc_vina: "0",
        chiet_khau_dichvu_vina: "0",
        chiet_khau_bunca_vina: "0",
        chiet_khau_napho_vina: "0",
        chiet_khau_mymobifone_vina: "0",
        chiet_khau_gsmmobifone_vina: "0",

        chiet_khau_smart_vi_viettel: "0",
        chiet_khau_tkc_viettel: "0",
        chiet_khau_dichvu_viettel: "0",
        chiet_khau_bunca_viettel: "0",
        chiet_khau_napho_viettel: "0",
        chiet_khau_mymobifone_viettel: "0",
        chiet_khau_gsmmobifone_viettel: "0",
    });

    const [chietKhauCuaBan, setChietKhauCuaBan] = useState({
        chiet_khau_smart_vi: "0",
        chiet_khau_tkc: "0",
        chiet_khau_dichvu: "0",
        chiet_khau_bunca: "0",
        chiet_khau_napho: "0",
        chiet_khau_mymobifone: "0",
        chiet_khau_gsmmobifone: "0",

        chiet_khau_smart_vi_vina: "0",
        chiet_khau_tkc_vina: "0",
        chiet_khau_dichvu_vina: "0",
        chiet_khau_bunca_vina: "0",
        chiet_khau_napho_vina: "0",
        chiet_khau_mymobifone_vina: "0",
        chiet_khau_gsmmobifone_vina: "0",

        chiet_khau_smart_vi_viettel: "0",
        chiet_khau_tkc_viettel: "0",
        chiet_khau_dichvu_viettel: "0",
        chiet_khau_bunca_viettel: "0",
        chiet_khau_napho_viettel: "0",
        chiet_khau_mymobifone_viettel: "0",
        chiet_khau_gsmmobifone_viettel: "0",
    });

    const [moduleBanTien, batTatModuleBanTienValue] = useState({});

    useEffect(() => {
        async function fetchSettingModuleBanTien() {
            const moduleBanTien_res = await dispatch(SettingAction.findByKey('bat_tat_module_ban_tien'))
            let moduleBanTien_value = JSON.parse(moduleBanTien_res.data.value);
            moduleBanTien_value = {
                topup: moduleBanTien_value.topup == '1' ? 1 : 2,
                tkc: moduleBanTien_value.tkc == '1' ? 1 : 2, 
                dv: moduleBanTien_value.dv == '1' ? 1 : 2,
                bc: moduleBanTien_value.bc == '1' ? 1 : 2,
                napho: moduleBanTien_value.napho == '1' ? 1 : 2,
                mymobifone: moduleBanTien_value.mymobifone == '1' ? 1 : 2,
                gsmmobifone: moduleBanTien_value.gsmmobifone == '1' ? 1 : 2,

                topup_vina: moduleBanTien_value.topup_vina == '1' ? 1 : 2,
                tkc_vina: moduleBanTien_value.tkc_vina == '1' ? 1 : 2, 
                dv_vina: moduleBanTien_value.dv_vina == '1' ? 1 : 2,
                bc_vina: moduleBanTien_value.bc_vina == '1' ? 1 : 2,

                topup_viettel: moduleBanTien_value.topup_viettel == '1' ? 1 : 2,
                tkc_viettel: moduleBanTien_value.tkc_viettel == '1' ? 1 : 2, 
                dv_viettel: moduleBanTien_value.dv_viettel == '1' ? 1 : 2,
                bc_viettel: moduleBanTien_value.bc_viettel == '1' ? 1 : 2,
            }
            batTatModuleBanTienValue(moduleBanTien_value)
        }
        fetchSettingModuleBanTien();
    }, [])
    
    useEffect(() => {
        async function fetchData() {
            setNhaMangDangChon('mobi');

            console.log(info);

            // const chiet_khau_smart_vi_res = await dispatch(SettingAction.findByKey(`${selected.id}_chiet_khau_smart_vi`))
            // setChietKhauSmartVi(chiet_khau_smart_vi_res.data.value)
    
            // const chiet_khau_tkc_res = await dispatch(SettingAction.findByKey(`${selected.id}_chiet_khau_tkc`))
            // setChietKhauTkc(chiet_khau_tkc_res.data.value)
    
            // const chiet_khau_dichvu_res = await dispatch(SettingAction.findByKey(`${selected.id}_chiet_khau_dich_vu`))
            // setChietKhauDichVu(chiet_khau_dichvu_res.data.value)
    
            // const chiet_khau_bunca_res = await dispatch(SettingAction.findByKey(`${selected.id}_chiet_khau_bunca`))
            // setChietKhauBunCa(chiet_khau_bunca_res.data.value)

            // const chiet_khau_napho_res = await dispatch(SettingAction.findByKey(`${selected.id}_chiet_khau_napho`))
            // setChietKhauNapHo(chiet_khau_napho_res.data.value)

            if (info && info.cai_dat_chiet_khau) {
                try {
                    const jsonObject = JSON.parse(info.cai_dat_chiet_khau);
                    setChietKhauCuaBan({
                        ...values,
                        chiet_khau_smart_vi: typeof jsonObject.chiet_khau_smart_vi !== 'undefined' ? jsonObject.chiet_khau_smart_vi : '0',
                        chiet_khau_tkc: typeof jsonObject.chiet_khau_tkc !== 'undefined' ? jsonObject.chiet_khau_tkc : '0',
                        chiet_khau_dichvu: typeof jsonObject.chiet_khau_dichvu !== 'undefined' ? jsonObject.chiet_khau_dichvu : '0',
                        chiet_khau_bunca: typeof jsonObject.chiet_khau_bunca !== 'undefined' ? jsonObject.chiet_khau_bunca : '0',
                        chiet_khau_napho: typeof jsonObject.chiet_khau_napho !== 'undefined' ? jsonObject.chiet_khau_napho : '0',
                        chiet_khau_mymobifone: typeof jsonObject.chiet_khau_mymobifone !== 'undefined' ? jsonObject.chiet_khau_mymobifone : '0',
                        chiet_khau_gsmmobifone: typeof jsonObject.chiet_khau_gsmmobifone !== 'undefined' ? jsonObject.chiet_khau_gsmmobifone : '0',

                        chiet_khau_smart_vi_vina: typeof jsonObject.chiet_khau_smart_vi_vina !== 'undefined' ? jsonObject.chiet_khau_smart_vi_vina : '0',
                        chiet_khau_tkc_vina: typeof jsonObject.chiet_khau_tkc_vina !== 'undefined' ? jsonObject.chiet_khau_tkc_vina : '0',
                        chiet_khau_dichvu_vina: typeof jsonObject.chiet_khau_dichvu_vina !== 'undefined' ? jsonObject.chiet_khau_dichvu_vina : '0',
                        chiet_khau_bunca_vina: typeof jsonObject.chiet_khau_bunca_vina !== 'undefined' ? jsonObject.chiet_khau_bunca_vina : '0',
                        chiet_khau_napho_vina: typeof jsonObject.chiet_khau_napho_vina !== 'undefined' ? jsonObject.chiet_khau_napho_vina : '0',
                        chiet_khau_mymobifone_vina: typeof jsonObject.chiet_khau_mymobifone_vina !== 'undefined' ? jsonObject.chiet_khau_mymobifone_vina : '0',
                        chiet_khau_gsmmobifone_vina: typeof jsonObject.chiet_khau_gsmmobifone_vina !== 'undefined' ? jsonObject.chiet_khau_gsmmobifone_vina : '0',

                        chiet_khau_smart_vi_viettel: typeof jsonObject.chiet_khau_smart_vi_viettel !== 'undefined' ? jsonObject.chiet_khau_smart_vi_viettel : '0',
                        chiet_khau_tkc_viettel: typeof jsonObject.chiet_khau_tkc_viettel !== 'undefined' ? jsonObject.chiet_khau_tkc_viettel : '0',
                        chiet_khau_dichvu_viettel: typeof jsonObject.chiet_khau_dichvu_viettel !== 'undefined' ? jsonObject.chiet_khau_dichvu_viettel : '0',
                        chiet_khau_bunca_viettel: typeof jsonObject.chiet_khau_bunca_viettel !== 'undefined' ? jsonObject.chiet_khau_bunca_viettel : '0',
                        chiet_khau_napho_viettel: typeof jsonObject.chiet_khau_napho_viettel !== 'undefined' ? jsonObject.chiet_khau_napho_viettel : '0',
                        chiet_khau_mymobifone_viettel: typeof jsonObject.chiet_khau_mymobifone_viettel !== 'undefined' ? jsonObject.chiet_khau_mymobifone_viettel : '0',
                        chiet_khau_gsmmobifone_viettel: typeof jsonObject.chiet_khau_gsmmobifone_viettel !== 'undefined' ? jsonObject.chiet_khau_gsmmobifone_viettel : '0',
                    });
                } catch (error) {
                    console.log(error);
                }
            }

            if (selected && selected.cai_dat_chiet_khau) {
                try {
                    const jsonObject = JSON.parse(selected.cai_dat_chiet_khau);
                    setValues({
                        ...values,
                        chiet_khau_smart_vi: typeof jsonObject.chiet_khau_smart_vi !== 'undefined' ? jsonObject.chiet_khau_smart_vi : '0',
                        chiet_khau_tkc: typeof jsonObject.chiet_khau_tkc !== 'undefined' ? jsonObject.chiet_khau_tkc : '0',
                        chiet_khau_dichvu: typeof jsonObject.chiet_khau_dichvu !== 'undefined' ? jsonObject.chiet_khau_dichvu : '0',
                        chiet_khau_bunca: typeof jsonObject.chiet_khau_bunca !== 'undefined' ? jsonObject.chiet_khau_bunca : '0',
                        chiet_khau_napho: typeof jsonObject.chiet_khau_napho !== 'undefined' ? jsonObject.chiet_khau_napho : '0',
                        chiet_khau_mymobifone: typeof jsonObject.chiet_khau_mymobifone !== 'undefined' ? jsonObject.chiet_khau_mymobifone : '0',
                        chiet_khau_gsmmobifone: typeof jsonObject.chiet_khau_gsmmobifone !== 'undefined' ? jsonObject.chiet_khau_gsmmobifone : '0',

                        chiet_khau_smart_vi_vina: typeof jsonObject.chiet_khau_smart_vi_vina !== 'undefined' ? jsonObject.chiet_khau_smart_vi_vina : '0',
                        chiet_khau_tkc_vina: typeof jsonObject.chiet_khau_tkc_vina !== 'undefined' ? jsonObject.chiet_khau_tkc_vina : '0',
                        chiet_khau_dichvu_vina: typeof jsonObject.chiet_khau_dichvu_vina !== 'undefined' ? jsonObject.chiet_khau_dichvu_vina : '0',
                        chiet_khau_bunca_vina: typeof jsonObject.chiet_khau_bunca_vina !== 'undefined' ? jsonObject.chiet_khau_bunca_vina : '0',
                        chiet_khau_napho_vina: typeof jsonObject.chiet_khau_napho_vina !== 'undefined' ? jsonObject.chiet_khau_napho_vina : '0',
                        chiet_khau_mymobifone_vina: typeof jsonObject.chiet_khau_mymobifone_vina !== 'undefined' ? jsonObject.chiet_khau_mymobifone_vina : '0',
                        chiet_khau_gsmmobifone_vina: typeof jsonObject.chiet_khau_gsmmobifone_vina !== 'undefined' ? jsonObject.chiet_khau_gsmmobifone_vina : '0',

                        chiet_khau_smart_vi_viettel: typeof jsonObject.chiet_khau_smart_vi_viettel !== 'undefined' ? jsonObject.chiet_khau_smart_vi_viettel : '0',
                        chiet_khau_tkc_viettel: typeof jsonObject.chiet_khau_tkc_viettel !== 'undefined' ? jsonObject.chiet_khau_tkc_viettel : '0',
                        chiet_khau_dichvu_viettel: typeof jsonObject.chiet_khau_dichvu_viettel !== 'undefined' ? jsonObject.chiet_khau_dichvu_viettel : '0',
                        chiet_khau_bunca_viettel: typeof jsonObject.chiet_khau_bunca_viettel !== 'undefined' ? jsonObject.chiet_khau_bunca_viettel : '0',
                        chiet_khau_napho_viettel: typeof jsonObject.chiet_khau_napho_viettel !== 'undefined' ? jsonObject.chiet_khau_napho_viettel : '0',
                        chiet_khau_mymobifone_viettel: typeof jsonObject.chiet_khau_mymobifone_viettel !== 'undefined' ? jsonObject.chiet_khau_mymobifone_viettel : '0',
                        chiet_khau_gsmmobifone_viettel: typeof jsonObject.chiet_khau_gsmmobifone_viettel !== 'undefined' ? jsonObject.chiet_khau_gsmmobifone_viettel : '0',
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        }
        fetchData();
    }, [selected])

    const onPressPositive = async () => {
        try {
            let response = await dispatch(AccountAction.updateBalance(selected.id, {so_du: parseInt(values.so_du.trim().replace(/,/g, ''))}))
            
            await props.getBalance(selected.username);

            response = await dispatch(AccountAction.update(selected.id, { 
                cai_dat_chiet_khau: {
                    chiet_khau_smart_vi: values.chiet_khau_smart_vi,
                    chiet_khau_tkc: values.chiet_khau_tkc,
                    chiet_khau_dichvu: values.chiet_khau_dichvu,
                    chiet_khau_bunca: values.chiet_khau_bunca,
                    chiet_khau_napho: values.chiet_khau_napho,
                    chiet_khau_mymobifone: values.chiet_khau_mymobifone,
                    chiet_khau_gsmmobifone: values.chiet_khau_gsmmobifone,

                    chiet_khau_smart_vi_vina: values.chiet_khau_smart_vi_vina,
                    chiet_khau_tkc_vina: values.chiet_khau_tkc_vina,
                    chiet_khau_dichvu_vina: values.chiet_khau_dichvu_vina,
                    chiet_khau_bunca_vina: values.chiet_khau_bunca_vina,
                    chiet_khau_napho_vina: values.chiet_khau_napho_vina,
                    chiet_khau_mymobifone_vina: values.chiet_khau_mymobifone_vina,
                    chiet_khau_gsmmobifone_vina: values.chiet_khau_gsmmobifone_vina,

                    chiet_khau_smart_vi_viettel: values.chiet_khau_smart_vi_viettel,
                    chiet_khau_tkc_viettel: values.chiet_khau_tkc_viettel,
                    chiet_khau_dichvu_viettel: values.chiet_khau_dichvu_viettel,
                    chiet_khau_bunca_viettel: values.chiet_khau_bunca_viettel,
                    chiet_khau_napho_viettel: values.chiet_khau_napho_viettel,
                    chiet_khau_mymobifone_viettel: values.chiet_khau_mymobifone_viettel,
                    chiet_khau_gsmmobifone_viettel: values.chiet_khau_gsmmobifone_viettel,
                }
            }));
            
            console.log(response)
            if (typeof response.status !== 'undefined' && response.status == 'success') {
                //showToast(NOTI_TYPES.SUCCESS, "Cập nhật cài đặt thành công");
            } else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') {
                showToast(NOTI_TYPES.ERROR, "[Lỗi] "+response.msg);
            }
            resetState();
            props.handleClose();
        } catch (error) {
            if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') {
                showToast(NOTI_TYPES.ERROR, "[Lỗi] "+error.msg)
            } else {
                showToast(NOTI_TYPES.ERROR, "[Lỗi] Không thể lưu cài đặt")
            }
        }
    }

    const handleChange = (event) => {
        if (event.target.name == 'nha_mang_dang_chon') {
            setNhaMangDangChon(event.target.value);
            return;
        }
        if (event.target.name == 'so_du' && event.target.value != '-') event.target.value = numeral(event.target.value).format('0,0')
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const resetState = () => {
        setValues({
            so_du: "",
            chiet_khau_smart_vi: "0",
            chiet_khau_tkc: "0",
            chiet_khau_dichvu: "0",
            chiet_khau_bunca: "0",
            chiet_khau_napho: "0",
            chiet_khau_mymobifone: "0",
            chiet_khau_gsmmobifone: "0",

            chiet_khau_smart_vi_vina: "0",
            chiet_khau_tkc_vina: "0",
            chiet_khau_dichvu_vina: "0",
            chiet_khau_bunca_vina: "0",
            chiet_khau_napho_vina: "0",
            chiet_khau_mymobifone_vina: "0",
            chiet_khau_gsmmobifone_vina: "0",

            chiet_khau_smart_vi_viettel: "0",
            chiet_khau_tkc_viettel: "0",
            chiet_khau_dichvu_viettel: "0",
            chiet_khau_bunca_viettel: "0",
            chiet_khau_napho_viettel: "0",
            chiet_khau_mymobifone_viettel: "0",
            chiet_khau_gsmmobifone_viettel: "0",
        });
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Cập nhật số dư và chiết khấu</DialogTitle>
            <DialogContent style={{ paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Cộng số dư"
                            name="so_du"
                            onChange={handleChange}
                            required
                            value={values.so_du || ''}
                            variant="outlined"
                            placeholder='100000 (nạp) hoặc -100000 (thu hồi)'
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <span style={{color: 'red'}}>* LƯU Ý: CHIẾT KHẤU CỦA NHÂN VIÊN KHÔNG ĐƯỢC PHÉP CAO HƠN CHIẾT KHẤU CỦA BẠN, MỤC NÀO NHẬP KHÔNG ĐÚNG YÊU CẦU SẼ BỊ TRẢ VỀ 0, ĐỒNG NGHĨA VỚI VIỆC LÊN ĐƠN Ở KÊNH ĐÓ SẼ KHÔNG ĐƯỢC CHIẾT KHẤU</span>
                        <br></br><br></br>
                        <span style={{color: 'blue'}}>* Chiết khấu của bạn:</span>
                        <br></br>
                        {(role.priority < 3 || role.priority > 2 && moduleBanTien.topup == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- TOP-UP: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{chietKhauCuaBan.chiet_khau_smart_vi} %, <span style={{color: 'blue'}}>VINA: </span>{chietKhauCuaBan.chiet_khau_smart_vi_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{chietKhauCuaBan.chiet_khau_smart_vi_viettel} %</span><br></br></span>}
                        {(role.priority < 3 || role.priority > 2 && moduleBanTien.tkc == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- TKC: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{chietKhauCuaBan.chiet_khau_tkc} %, <span style={{color: 'blue'}}>VINA: </span>{chietKhauCuaBan.chiet_khau_tkc_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{chietKhauCuaBan.chiet_khau_tkc_viettel} %</span><br></br></span>}
                        {/* {(role.priority < 3 || role.priority > 2 && moduleBanTien.dv == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- DV: <span style={{color: 'brown'}}><span style={{color: 'green'}}>MOBI: </span>{chietKhauCuaBan.chiet_khau_dichvu} %, <span style={{color: 'blue'}}>VINA: </span>{chietKhauCuaBan.chiet_khau_dichvu_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{chietKhauCuaBan.chiet_khau_dichvu_viettel} %</span><br></br></span>} */}
                        {/* {(role.priority < 3 || role.priority > 2 && moduleBanTien.bc == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- BC: <span style={{color: 'brown'}}><span style={{color: 'green'}}>MOBI: </span>{chietKhauCuaBan.chiet_khau_bunca} %, <span style={{color: 'blue'}}>VINA: </span>{chietKhauCuaBan.chiet_khau_bunca_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{chietKhauCuaBan.chiet_khau_bunca_viettel} %</span><br></br></span>} */}
                        {(role.priority < 3 || role.priority > 2 && moduleBanTien.napho == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- Nạp hộ: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{chietKhauCuaBan.chiet_khau_napho} %, <span style={{color: 'blue'}}>VINA: </span>{chietKhauCuaBan.chiet_khau_napho_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{chietKhauCuaBan.chiet_khau_napho_viettel} %</span><br></br></span>}
                        {(role.priority < 3 || role.priority > 2 && moduleBanTien.mymobifone == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- Nạp My: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{chietKhauCuaBan.chiet_khau_mymobifone} %, <span style={{color: 'blue'}}>VINA: </span>{chietKhauCuaBan.chiet_khau_mymobifone_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{chietKhauCuaBan.chiet_khau_mymobifone_viettel} %</span><br></br></span>}
                        {(role.priority < 3 || role.priority > 2 && moduleBanTien.gsmmobifone == 1) && <span style={{color: 'black', fontWeight: 'bold'}}>- Nạp GSM: <span style={{color: 'black'}}><span style={{color: 'green'}}>MOBI: </span>{chietKhauCuaBan.chiet_khau_gsmmobifone} %, <span style={{color: 'blue'}}>VINA: </span>{chietKhauCuaBan.chiet_khau_gsmmobifone_vina} %, <span style={{color: 'red'}}>VIETTEL: </span>{chietKhauCuaBan.chiet_khau_gsmmobifone_viettel} %</span><br></br></span>}
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <Select
                            style={{ height: 56, width: "100%", marginLeft: 0 }}
                            name="nha_mang_dang_chon"
                            value={nha_mang_dang_chon || 'mobi'}
                            onChange={handleChange}
                            variant="outlined"
                        >
                            <MenuItem value="mobi">MOBIFONE</MenuItem>
                            <MenuItem value="vina">VINAPHONE</MenuItem>
                            <MenuItem value="viettel">VIETTEL</MenuItem>
                        </Select>
                    </Grid>
                    {(role.priority < 3 || role.priority > 2 && moduleBanTien.topup == 1) && <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="CK TOP-UP (%)"
                            name="chiet_khau_smart_vi"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_smart_vi'] || chiet_khau_smart_vi}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="CK TOP-UP (%)"
                            name="chiet_khau_smart_vi_vina"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_smart_vi_vina'] || chiet_khau_smart_vi_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="CK TOP-UP (%)"
                            name="chiet_khau_smart_vi_viettel"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_smart_vi_viettel'] || chiet_khau_smart_vi_viettel}
                            variant="outlined"
                        />}
                    </Grid>}
                    {(role.priority < 3 || role.priority > 2 && moduleBanTien.tkc == 1) && <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="CK TKC (%)"
                            name="chiet_khau_tkc"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_tkc'] || chiet_khau_tkc}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="CK TKC (%)"
                            name="chiet_khau_tkc_vina"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_tkc_vina'] || chiet_khau_tkc_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="CK TKC (%)"
                            name="chiet_khau_tkc_viettel"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_tkc_viettel'] || chiet_khau_tkc_viettel}
                            variant="outlined"
                        />}
                    </Grid>}
                    {(role.priority < 3 || role.priority > 2 && moduleBanTien.dv == 1) && <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="CK DV (%)"
                            name="chiet_khau_dichvu"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_dichvu'] || chiet_khau_dichvu}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="CK DV (%)"
                            name="chiet_khau_dichvu_vina"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_dichvu_vina'] || chiet_khau_dichvu_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="CK DV (%)"
                            name="chiet_khau_dichvu_viettel"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_dichvu_viettel'] || chiet_khau_dichvu_viettel}
                            variant="outlined"
                        />}
                    </Grid>}
                    {(role.priority < 3 || role.priority > 2 && moduleBanTien.bc == 1) && <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="CK BC (%)"
                            name="chiet_khau_bunca"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_bunca'] || chiet_khau_bunca}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="CK BC (%)"
                            name="chiet_khau_bunca_vina"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_bunca_vina'] || chiet_khau_bunca_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="CK BC (%)"
                            name="chiet_khau_bunca_viettel"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_bunca_viettel'] || chiet_khau_bunca_viettel}
                            variant="outlined"
                        />}
                    </Grid>}
                    {(role.priority < 3 || role.priority > 2 && moduleBanTien.napho == 1) && <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="CK Nạp hộ Mobifone (%)"
                            name="chiet_khau_napho"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_napho'] || chiet_khau_napho}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="CK Nạp hộ Mobifone (%)"
                            name="chiet_khau_napho_vina"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_napho_vina'] || chiet_khau_napho_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="CK Nạp hộ Mobifone (%)"
                            name="chiet_khau_napho_viettel"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_napho_viettel'] || chiet_khau_napho_viettel}
                            variant="outlined"
                        />}
                    </Grid>}
                    {(role.priority < 3 || role.priority > 2 && moduleBanTien.mymobifone == 1) && <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="CK My Mobifone (%)"
                            name="chiet_khau_mymobifone"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_mymobifone'] || chiet_khau_mymobifone}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="CK My Mobifone (%)"
                            name="chiet_khau_mymobifone_vina"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_mymobifone_vina'] || chiet_khau_mymobifone_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="CK My Mobifone (%)"
                            name="chiet_khau_mymobifone_viettel"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_mymobifone_viettel'] || chiet_khau_mymobifone_viettel}
                            variant="outlined"
                        />}
                    </Grid>}
                    {(role.priority < 3 || role.priority > 2 && moduleBanTien.gsmmobifone == 1) && <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        {nha_mang_dang_chon == 'mobi' && <TextField
                            fullWidth
                            label="CK GSM Mobifone (%)"
                            name="chiet_khau_gsmmobifone"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_gsmmobifone'] || chiet_khau_gsmmobifone}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'vina' && <TextField
                            fullWidth
                            label="CK GSM Mobifone (%)"
                            name="chiet_khau_gsmmobifone_vina"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_gsmmobifone_vina'] || chiet_khau_gsmmobifone_vina}
                            variant="outlined"
                        />}

                        {nha_mang_dang_chon == 'viettel' && <TextField
                            fullWidth
                            label="CK GSM Mobifone (%)"
                            name="chiet_khau_gsmmobifone_viettel"
                            onChange={handleChange}
                            required
                            value={values['chiet_khau_gsmmobifone_viettel'] || chiet_khau_gsmmobifone_viettel}
                            variant="outlined"
                        />}
                    </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={onPressPositive}>Cập nhật</Button>
            </DialogActions>
        </Dialog >
    );
}

export default AccountEditSoDuDialog;