import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const InviteService = {

  invite: (payload) => {
    return axios.post(`/api/v1/vas/mobifone/invite`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },
}