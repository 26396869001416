import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { NapmymobifoneAction } from 'src/actions/napmymobifone.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    Box,
    MenuItem,
    Select as Select1,
    InputAdornment,
    SvgIcon,
    InputLabel,
    TableCell,
} from '@material-ui/core';

import {
    DatePicker,
    LocalizationProvider,
  } from '@mui/x-date-pickers';
  import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';

import numeral from 'numeral';

const CreateEditDialogBoiSo = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { role } = useSelector(state => state.profile);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.napmymobifone)
    const [isOpen, setIsOpen] = useState(false);
    const [boi_so, setBoiSo] = useState(['-99'])

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
        dispatch(PermissionAction.list(0, 100))
    }, [])

    useEffect(() => {
        if (props.type === 'EDIT') {
            if (props.type === 'EDIT') {
                if (selected && selected.mobidanhsachdonchitiets && selected.mobidanhsachdonchitiets.length > 0) {
                    if (selected.mobidanhsachdonchitiets[0].boi_so) {
                        setBoiSo(parseStringToString(selected.mobidanhsachdonchitiets[0].boi_so))
                    }
                }
            }
        }
    }, [selected])

    const parseStringToString = (stringBoiSo) => {
        if (!stringBoiSo.includes(',')) {
            return [stringBoiSo]
        } else {
            const str = stringBoiSo;
            const arr = str.split(", ");
            return arr;
        }
    }

    const onPressPositive = async () => {
        if (boi_so.includes('-99')) {
            return;
        }
        if (props.type === 'EDIT') {
            dispatch(NapmymobifoneAction.updateBoiSo(selected.id, { 
                boi_so: boi_so, 
            }))
            resetState();
            props.handleClose();
        }
    }

    const resetState = () => {
        setBoiSo(['-99'])
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    const handleChange = (event) => {
        if (event.target.name == 'boi_so') {
            setIsOpen(false);
            let targetValues = event.target.value;
            targetValues = targetValues.filter((value) => value != '-99')
            if (targetValues.length == 0) targetValues.push('-99');
            setBoiSo(targetValues);
        } else {
            
        }
    };

    const handleClickChonBoiSo = async () => {
        setIsOpen(true);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog open={props.open} onClose={props.handleClose}>
                <DialogTitle style={{paddingTop: 5, paddingBottom: 5}}>{props.type === 'EDIT' ? "Cập nhật bội" : "Tạo bội mới"}</DialogTitle>
                <DialogContent style={{ width: 300, paddingTop: 0, paddingBottom: 5 }}>
                    <Grid
                        container
                        spacing={0}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <Select1
                                style={{ height: 40, marginTop: 0, marginBottom: 0, width: "100%", marginLeft: 0, fontSize: 12 }}
                                name="boi_so"
                                value={boi_so || ['-99']}
                                onChange={handleChange}
                                onOpen={handleClickChonBoiSo}
                                variant="outlined"
                                open={isOpen}
                                multiple
                            >
                                <MenuItem value="-99">Chọn bội...</MenuItem>
                                <MenuItem value="10" style={{backgroundColor: boi_so.includes('10') ? 'yellow' : 'inherit'}}>10</MenuItem>
                                <MenuItem value="20" style={{backgroundColor: boi_so.includes('20') ? 'yellow' : 'inherit'}}>20</MenuItem>
                                <MenuItem value="30" style={{backgroundColor: boi_so.includes('30') ? 'yellow' : 'inherit'}}>30</MenuItem>
                                <MenuItem value="50" style={{backgroundColor: boi_so.includes('50') ? 'yellow' : 'inherit'}}>50</MenuItem>
                                <MenuItem value="100" style={{backgroundColor: boi_so.includes('100') ? 'yellow' : 'inherit'}}>100</MenuItem>
                                <MenuItem value="200" style={{backgroundColor: boi_so.includes('200') ? 'yellow' : 'inherit'}}>200</MenuItem>
                                <MenuItem value="300" style={{backgroundColor: boi_so.includes('300') ? 'yellow' : 'inherit'}}>300</MenuItem>
                                <MenuItem value="500" style={{backgroundColor: boi_so.includes('500') ? 'yellow' : 'inherit'}}>500</MenuItem>
                                <MenuItem value="1000" style={{backgroundColor: boi_so.includes('1000') ? 'yellow' : 'inherit'}}>1.000</MenuItem>
                            </Select1>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{height: 30}}>
                    <Button style={{height: 30, fontSize: 12}} onClick={props.handleClose}>Huỷ</Button>
                    <Button style={{height: 30, fontSize: 12}} onClick={onPressPositive}>{props.type === 'EDIT' ? "Cập nhật" : "Tạo"}</Button>
                </DialogActions>
            </Dialog >
        </LocalizationProvider>
    );
}

export default CreateEditDialogBoiSo;