import {
  useEffect,
  useState,
} from 'react';

import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import {
  Edit3 as Edit3Icon,
  Trash2 as Trash2Icon,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { UploadAction } from 'src/actions/upload.acction';

import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

const UploadListResults = (props) => {
  const confirm = useConfirm();

  const dispatch = useDispatch()
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const { items, total } = useSelector(state => state.upload)

  useEffect(() => {
    dispatch(UploadAction.list(page, limit))
  }, [page, limit])

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = (item) => {
    confirm({
      title: 'Xoá dữ liệu upload?',
      content: <>Toàn bộ dữ liệu khách hàng trong file <strong>{item.filename}</strong> sẽ bị xoá.</>,
      confirmationText: 'Xác nhận',
      cancellationText: 'Quay lại'
    }).then(() => dispatch(UploadAction.del(item.id)))
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  #
                </TableCell>
                <TableCell>
                  Người upload
                </TableCell>
                <TableCell>
                  Ngày upload
                </TableCell>
                <TableCell>
                  Tên danh sách
                </TableCell>
                <TableCell>
                  (SL)Sử dụng/Còn/Tổng
                </TableCell>
                <TableCell>
                  Hành động
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items && items.slice((page - 1) * limit, page * limit).map((item, idx) => (
                <TableRow
                  hover
                  key={item.id}
                >
                  <TableCell >
                    {idx + 1}
                  </TableCell>
                  <TableCell>
                    {item.created_by && item.created_by.fullname}
                  </TableCell>
                  <TableCell>
                    <p>{moment(item.createdAt).format("HH:mm:ss")}</p>
                    {moment(item.createdAt).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    {item.filename}
                  </TableCell>
                  <TableCell >
                    <Button>
                      Xem
                    </Button>/
                    <Button>
                      Xem
                    </Button>/
                    {`${item.totalCustomers}`}
                  </TableCell>
                  <TableCell >
                    <IconButton aria-label="edit" >
                      <Edit3Icon color='green' />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDelete(item)}>
                      <Trash2Icon color='red' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page - 1}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 20, 50]}
      />
    </Card>
  );
};

export default UploadListResults;
