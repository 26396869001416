import React, { useEffect, useState } from 'react';

import { Search as SearchIcon } from 'react-feather';
import dayjs from 'dayjs';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
  Select as Select1,
  MenuItem,
} from '@material-ui/core';

import Select from 'react-select';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { BankAction } from 'src/actions/bank.action';

import moment from 'moment';
import { AccountAction } from 'src/actions/account.acction';

const BankListToolbar = (props) => {
  const dispatch = useDispatch();
  const { filter } = useSelector(state => state.bank)
  const { items: account_items, total: account_total } = useSelector(state => state.account)

  const allOption = { username: '', fullname: 'Tất cả', role: { priority: 0 } };
  const options = [allOption, ...account_items];
  
  const [values, setValues] = useState({
    username: "",
    message: "",
    is_read: "",
    startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
    endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
  });

  const handleStartDateChange = (date) => {
    setValues({
      ...values,
      startDate: date
    });

    onLookup({startDate: date});
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });

    onLookup({endDate: date});
  };

  const handleMessageChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    onLookup({[event.target.name]: event.target.value});
  };

  const handleUsernameChange1 = (name, newValue) => {
    setValues({
      ...values,
      [name]: newValue
    });

    onLookup({[name]: newValue});
  };

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      username: "",
      message: "",
      is_read: "",
      startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format("DD/MM/YYYY"),
      endDate: dayjs(today).format("DD/MM/YYYY"),
    };
    dispatch(BankAction.updateFilter(payload));
    dispatch(AccountAction.listNoLimit());
  }, []);

  const onLookup = (immediatelyPayload = {}) => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      username: typeof immediatelyPayload.username !== 'undefined' ? immediatelyPayload.username : values.username,
      message: typeof immediatelyPayload.message !== 'undefined' ? immediatelyPayload.message : values.message,
      is_read: typeof immediatelyPayload.is_read !== 'undefined' ? immediatelyPayload.is_read : values.is_read,
      startDate: typeof immediatelyPayload.startDate !== 'undefined' ? immediatelyPayload.startDate.format("DD/MM/YYYY") : values.startDate.format("DD/MM/YYYY"),
      endDate: typeof immediatelyPayload.endDate !== 'undefined' ? immediatelyPayload.endDate.format("DD/MM/YYYY") :values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(BankAction.updateFilter(payload));
    dispatch(BankAction.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  return (
    <Box {...props}>
      <Box sx={{ mt: 3 }}>
        <Card className="filter_card">
          <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <div style={{ width: '250px', zIndex: '10' }} className="full-width-select">
                  <Select
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 56,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      }),
                    }}
                    options={options}
                    getOptionLabel={(item) => `${item.role.priority == 4 ? '--' : (item.role.priority == 5 ? '----' : '')} ${item.username != '' ? (item.username + ' - ') : ''}${item.fullname}`}
                    placeholder={values.username || "Tìm user..."}
                    components={{
                      DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                      IndicatorSeparator: null,
                    }}
                    value={values.username || null}
                    onChange={(newValue) => handleUsernameChange1('username', newValue.username)}
                    isSearchable
                  />
                </div>
                <DatePicker
                  label="Thời gian bắt đầu"
                  style={{ width: '100%', marginRight: 24 }}
                  format='DD/MM/YYYY'
                  value={values.startDate}
                  onChange={(newValue) => handleStartDateChange(newValue)}
                  />
                <DatePicker
                  label="Thời gian kết thúc"
                  style={{ width: '100%', marginLeft: 24 }}
                  value={values.endDate}
                  format='DD/MM/YYYY'
                  onChange={(newValue) => handleEndDateChange(newValue)}
                />
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                          >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  name="message"
                  onChange={handleMessageChange}
                  onKeyDown={handleKeyDown}
                  value={values.message}
                  placeholder="Tìm tin nhắn"
                  variant="outlined"
                />
                <Select1
                  style={{ height: 56, width: "200px", marginLeft: 0, color: values.is_read == 1 ? 'green' : (values.is_read == 2 ? 'red' : 'black') }}
                  name="is_read"
                  value={values.is_read || -99}
                  onChange={(newValue) => handleMessageChange(newValue)}
                  variant="outlined"
                  startAdornment={(
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )}
                >
                  <MenuItem value="-99">Trạng thái xử lý...</MenuItem>
                  <MenuItem value="1" style={{color: 'green'}}>Đã xử lý</MenuItem>
                  <MenuItem value="2" style={{color: 'red'}}>Chưa xử lý</MenuItem>
                </Select1>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onLookup}
                  style={{ height: 56, marginTop: 0, width: 200, marginLeft: 24 }}
                  >
                  Tra cứu
                </Button>
              </Box>
            </LocalizationProvider>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
};

export default BankListToolbar;
