import React, { useEffect, useState } from 'react';

import { Search as SearchIcon } from 'react-feather';
import dayjs from 'dayjs';

import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
  MenuItem,
  Select as Select1,
} from '@material-ui/core';

import Select from 'react-select';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { Customermessage9084Action } from 'src/actions/customermessage9084.action';
import { AccountAction } from 'src/actions/account.acction';

import moment from 'moment';

const Customermessage9084ListToolbar = (props) => {
  const dispatch = useDispatch();
  const { filter } = useSelector(state => state.customermessage9084)
  const { items, total } = useSelector(state => state.account)
  const { info, role } = useSelector(state => state.profile)
  const [values, setValues] = useState({
    subagent: "0",
    username: "",
    message: "",
    startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
    endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
  });

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  const handleMessageChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUsernameChange1 = (name, newValue) => {
    setValues({
      ...values,
      [name]: newValue
    });
    console.log(name, newValue);
  };

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      subagent: "0",
      username: "",
      message: "",
      startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format("DD/MM/YYYY"),
      endDate: dayjs(today).format("DD/MM/YYYY"),
    };
    dispatch(Customermessage9084Action.updateFilter(payload));
    dispatch(AccountAction.listNoLimit());
  }, []);

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      subagent: values.subagent,
      username: values.username,
      message: values.message,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(Customermessage9084Action.updateFilter(payload));
    dispatch(Customermessage9084Action.list())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  return (
    <Box {...props}>
      <Box sx={{ mt: 3 }}>
        <Card style={{overflow: 'visible'}}>
          <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <div style={{ width: '100%', zIndex: '10' }} className="full-width-select">
                  <Select
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 56,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      }),
                    }}
                    options={items}
                    getOptionLabel={(item) => `${item.role.priority == 4 ? '--' : (item.role.priority == 5 ? '----' : '')} ${item.username} - ${item.fullname}`}
                    placeholder={values.username || "Chọn nhân viên..."}
                    components={{
                      DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                      IndicatorSeparator: null,
                    }}
                    value={values.username || null}
                    onChange={(newValue) => handleUsernameChange1('username', newValue.username)}
                    isSearchable
                  />
                </div>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                          >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  name="message"
                  onChange={handleMessageChange}
                  onKeyDown={handleKeyDown}
                  value={values.message}
                  placeholder="Tìm SĐT"
                  variant="outlined"
                  style={{ height: 56, width: 250, marginLeft: 24 }}
                  />
              </Box>
            </LocalizationProvider>
          </CardContent>
          <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <DatePicker
                  label="Thời gian bắt đầu"
                  style={{ width: '100%', marginRight: 24 }}
                  format='DD/MM/YYYY'
                  value={values.startDate}
                  onChange={(newValue) => handleStartDateChange(newValue)}
                  />
                <DatePicker
                  label="Thời gian kết thúc"
                  style={{ width: '100%', marginLeft: 24 }}
                  value={values.endDate}
                  format='DD/MM/YYYY'
                  onChange={(newValue) => handleEndDateChange(newValue)}
                />
                <Select1
                  style={{ height: 56, marginTop: 6, width: "250px", marginLeft: 0 }}
                  name="subagent"
                  value={values.subagent || 0}
                  onChange={(newValue) => handleUsernameChange1('subagent', newValue.target.value)}
                  variant="outlined"
                  startAdornment={(
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )}
                >
                  <MenuItem value="0">Chọn kênh...</MenuItem>
                  {(role.priority < 3 || !info.username.trim().startsWith('QCMB') && !info.username.trim().startsWith('HNIMB')) && <MenuItem value="d1">d1</MenuItem>}
                  {role.priority < 3 && <MenuItem value="d9">d9</MenuItem>}
                  {(role.priority < 3 || info.username.trim().startsWith('QCMB')) && <MenuItem value="ga22">ga22</MenuItem>}
                  {(role.priority < 3 || info.username.trim().startsWith('HNIMB')) && <MenuItem value="ga23">ga23</MenuItem>}
                  {role.priority < 3 && <MenuItem value="ga24">ga24</MenuItem>}
                  {role.priority < 3 && <MenuItem value="tr8">tr8</MenuItem>}
                  {<MenuItem value="vg8">vg8</MenuItem>}
                </Select1>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onLookup}
                  style={{ height: 56, marginTop: 6, width: 200, marginLeft: 24 }}
                  >
                  Tra cứu
                </Button>
              </Box>
            </LocalizationProvider>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
};

export default Customermessage9084ListToolbar;
