import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { emitter } from 'src/utils/emitter';

import {
  Box,
  Tab,
  Tabs,
  AppBar,
  Container,
  makeStyles,
  useTheme,
  Typography
} from '@material-ui/core';

import { Cancel } from '@material-ui/icons';

import KeepAlive from 'react-activation'

import Machuyentien from './Machuyentien';
import Dashboard from './Dashboard';
import MobifoneDanhSachDon from './MobifoneDanhSachDon';
import NapMyMobifone from './NapMyMobifone';
import Quanlynapcuoc from './Quanlynapcuoc';
import Lssodu from './Lssodu';
import Dongtien from './Dongtien';
import BankLookup from './BankLookup';
import AccountList from './AccountList';
import Thamso from './Thamso';
import Thenap from './Thenap';
import Quanlythenap from './Quanlythenap';
import Thongkethegio from './Thongkethegio';
import Thongkethengay from './Thongkethengay';
import Taikhoanmymobi from './Taikhoanmymobi';
import Cauhinhgsm from './Cauhinhgsm';
import PermissionList from './PermissionList';
import RoleList from './RoleList';
import Systemaccount from './Systemaccount';
import LichsuhoatdongLookup from './LichsuhoatdongLookup';

const componentList = [
  {
    name: "Autobank",
    rawName: "Machuyentien",
    component: Machuyentien
  },
  {
    name: "Trang chủ",
    rawName: "Dashboard",
    component: Dashboard
  },
  {
    name: "Bắn tiền",
    rawName: "MobifoneDanhSachDon",
    component: MobifoneDanhSachDon
  },
  {
    name: "Nạp cước",
    rawName: "NapMyMobifone",
    component: NapMyMobifone
  },
  {
    name: "QL nạp cước",
    rawName: "Quanlynapcuoc",
    component: Quanlynapcuoc
  },
  {
    name: "Lịch sử số dư",
    rawName: "Lssodu",
    component: Lssodu
  },
  {
    name: "Dòng tiền",
    rawName: "Dongtien",
    component: Dongtien
  },
  {
    name: "Bank",
    rawName: "BankLookup",
    component: BankLookup
  },
  {
    name: "Quản lý đại lý",
    rawName: "AccountList",
    component: AccountList
  },
  {
    name: "Tham số",
    rawName: "Thamso",
    component: Thamso
  },
  {
    name: "Thẻ nạp",
    rawName: "Thenap",
    component: Thenap
  },
  {
    name: "Quản lý thẻ nạp",
    rawName: "Quanlythenap",
    component: Quanlythenap
  },
  {
    name: "Thống kê thẻ theo giờ",
    rawName: "Thongkethegio",
    component: Thongkethegio
  },
  {
    name: "Thống kê thẻ theo ngày",
    rawName: "Thongkethengay",
    component: Thongkethengay
  },
  {
    name: "My Mobifone",
    rawName: "Taikhoanmymobi",
    component: Taikhoanmymobi
  },
  {
    name: "Cấu hình GSM",
    rawName: "Cauhinhgsm",
    component: Cauhinhgsm
  },
  {
    name: "Danh sách quyền",
    rawName: "PermissionList",
    component: PermissionList
  },
  {
    name: "Phân quyền theo vai trò",
    rawName: "RoleList",
    component: RoleList
  },
  {
    name: "Tài khoản hệ thống",
    rawName: "Systemaccount",
    component: Systemaccount
  },
  {
    name: "Lịch sử hoạt động",
    rawName: "LichsuhoatdongLookup",
    component: LichsuhoatdongLookup
  },
]

var tabListProxy = []

const _HomePage = () => {
  const [tabList, setTabList] = useState([])
  const [curActiveTab, setActiveTab] = useState("")

  useEffect(() => {
    if (localStorage.getItem("tabList")) {
      const sampleTabs = []
      const archiveTabs = JSON.parse(localStorage.getItem("tabList"))

      for (const tab of archiveTabs) {
        const tData = componentList.find(c => c.rawName == tab.rawName)

        if (tData) {
          sampleTabs.push(tData)
        }
      }

      tabListProxy = sampleTabs
      setTabList(sampleTabs)

      setTimeout(() => {
        if (localStorage.getItem("lastActiveTab")) {
          _setActiveTab(localStorage.getItem("lastActiveTab"))
        }
      })
    }

    emitter.on("add_tab", (component) => {
      const tData = tabListProxy.find(c => c.rawName == component)

      if (tData) {
        setActiveTab(tData.rawName)

        return // block duplicate
      }

      const cData = componentList.find(c => c.rawName == component)

      AddNewTab(cData)
    })
  }, [])

  const AddNewTab = tab => {
    let cloneArray = [...tabListProxy]
    cloneArray.push(tab)

    tabListProxy = cloneArray

    setTabList(cloneArray)
    _setActiveTab(tab.rawName)

    // archive opened tabs
    localStorage.setItem("tabList", JSON.stringify(tabListProxy))
  }

  const clickTab = tab => {
    _setActiveTab(tab.rawName)
  }

  const closeTab = tab => {
    let cloneArray = [...tabListProxy]
    cloneArray = cloneArray.filter(t => t.rawName != tab.rawName)

    tabListProxy = [...cloneArray]

    if (tab.rawName == curActiveTab && tabListProxy.length > 0) {
      _setActiveTab(tabListProxy[tabListProxy.length - 1].rawName)
    }

    setTabList(tabListProxy)

    // archive opened tabs
    localStorage.setItem("tabList", JSON.stringify(tabListProxy))
  }

  const _setActiveTab = tabName => {
    setActiveTab(tabName)
    localStorage.setItem("lastActiveTab", tabName)
  }

  const renderTabName = tab => {
    return <span><span style={{ cursor: "pointer" }} onClick={() => clickTab(tab)}>{tab.name}</span> <Cancel onClick={() => closeTab(tab)} fontSize="inherit" style={{ color: "#e74c3c", cursor: "pointer" }} /></span>
  }

  return (
    <>
      <Helmet>
        <title>Trang chủ</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <AppBar position="static" color="default">
            <Tabs
              value={curActiveTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
            >
              {tabList.map((tab, index) => <Tab value={tab.rawName} key={index} label={renderTabName(tab)} />)}
            </Tabs>
          </AppBar>

          {tabList.map((tab, index) => {
            const { component: Component } = tab
            return curActiveTab == tab.rawName &&
              <KeepAlive>
                <div style={{ height: "80vh", overflow: "auto" }}>
                  <Component key={tab.rawName} />
                </div>
              </KeepAlive>
          })}
        </Container>
      </Box>
    </>
  )
};

export default _HomePage;
