import {
  deleteAllCookies,
  getCookie,
} from './common';

export function authHeader() {
  let accessToken = getCookie('accessToken')
  if (!accessToken) {
    accessToken = localStorage.getItem('accessToken')
    console.log("accessToken: =====", accessToken);
  }

  if (accessToken) {
    return { 'Authorization': `Bearer ` + accessToken };
  } else {
    return {};
  }
}

export function handleResponse(response) {
  if (response.status === 401) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('persist:root');
    deleteAllCookies()
    window.location.href = '/login';
    return;
  }
  if (response.status === 200 || response.status === 201) {
    return response.data;
  }

  return response.data.error
}
