import {
  LICHSUHOATDONG_UPDATE_FILTER,
  LICHSUHOATDONG_COUNT_ASSIGN_SUCCESS,
  LICHSUHOATDONG_HISTORY_LIST_SUCCESS,
  LICHSUHOATDONG_IMPORT_SUCCESS,
  LICHSUHOATDONG_LIST_SUCCESS,
  LICHSUHOATDONG_NEW_CARE_SUCCESS,
} from 'src/actions/lichsuhoatdong.action';
import { LOOKUP_CLEANER } from 'src/actions/lookup.action';
import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';
import dayjs from 'dayjs';

const today = new Date();

const initState = {
  filter: {
    message: '',
    startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)),
    endDate: dayjs(today),
    page: 0,
    limit: DEFAULT_ROW_PER_PAGE,
  },
  items: [],
  total: 0,
  temp: [],
  selected: undefined,
  countAssign: 0,
  history: [],
  totalHistory: 0,
  newCare: undefined,
  canCare: 0
}

const lichsuhoatdongReducer = (state = initState, action) => {
  switch (action.type) {
    case LICHSUHOATDONG_UPDATE_FILTER:  {
      if (action.data && typeof action.data !== 'undefined' && action.data && typeof state.filter !== 'undefined' && state.filter) {
        if (action.data.page == 'no_update' && typeof state.filter.page !== 'undefined') action.data.page = state.filter.page;
        if (action.data.limit == 'no_update' && typeof state.filter.limit !== 'undefined') action.data.limit = state.filter.limit;
      }
      return {
        ...state,
        filter: action.data,
      }
    }
    case LICHSUHOATDONG_LIST_SUCCESS: {
      return {
        ...state,
        items: action.data.items,
        total: action.data.total,
        tongChuyenKhoan: action.data.tongChuyenKhoan,
        tongDonChuyen: action.data.tongDonChuyen,
      }
    }
    case LICHSUHOATDONG_NEW_CARE_SUCCESS: {
      return {
        ...state,
        newCare: action.data.newCare,
        canCare: action.data.canCare,
      }
    }
    case LICHSUHOATDONG_IMPORT_SUCCESS: {
      return {
        ...state,
        temp: action.data,
      }
    }
    case LICHSUHOATDONG_COUNT_ASSIGN_SUCCESS: {
      return {
        ...state,
        countAssign: action.data,
      }
    }
    case LICHSUHOATDONG_HISTORY_LIST_SUCCESS: {
      return {
        ...state,
        history: action.data.items,
        totalHistory: action.data.total,
      }
    }
    case LOOKUP_CLEANER: {
      return initState
    }
    default: {
      return state;
    }
  }
}
export default lichsuhoatdongReducer