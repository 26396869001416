import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LichsuhoatdongAction } from 'src/actions/lichsuhoatdong.action';
import {
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core';

import numeral from 'numeral';

const LichsuhoatdongLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const { items, total, filter, tongChuyenKhoan, tongDonChuyen } = useSelector(state => state.lichsuhoatdong);
  const { role } = useSelector(state => state.profile);
  const [selectedLichsuhoatdongIds, setSelectedLichsuhoatdongIds] = useState([]);
  const [limit, setLimit] = useState(filter.limit || DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(filter.page || 0);
  const [lichsuhoatdongUpdate, setLichsuhoatdongUpdate] = useState([]);

  useEffect(() => {
    const payload = {
      ...filter,
      page: page,
      limit: limit,
    };

    dispatch(LichsuhoatdongAction.updateFilter(payload));

    dispatch(LichsuhoatdongAction.list());
  }, [page, limit])

  useEffect(() => {
    if (items) {
      console.log(items)
      for (let i = 0; i < items.length; i++) {
        if (typeof items[i] !== 'undefined') {
          setLichsuhoatdongUpdate({
            ...lichsuhoatdongUpdate,
            [items[i].id]: {
              so_dien_thoai: items[i].so_dien_thoai,
              goi_cuoc: items[i].goi_cuoc,
            },
          });
        }
      }
    }
  }, [items, page, limit])

  const handleSelectAll = (event) => {
    let newSelectedLichsuhoatdongIds;

    if (event.target.checked) {
      newSelectedLichsuhoatdongIds = items.map((lichsuhoatdong) => lichsuhoatdong.id);
    } else {
      newSelectedLichsuhoatdongIds = [];
    }

    setSelectedLichsuhoatdongIds(newSelectedLichsuhoatdongIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedLichsuhoatdongIds.indexOf(id);
    let newSelectedLichsuhoatdongIds = [];

    if (selectedIndex === -1) {
      newSelectedLichsuhoatdongIds = newSelectedLichsuhoatdongIds.concat(selectedLichsuhoatdongIds, id);
    } else if (selectedIndex === 0) {
      newSelectedLichsuhoatdongIds = newSelectedLichsuhoatdongIds.concat(selectedLichsuhoatdongIds.slice(1));
    } else if (selectedIndex === selectedLichsuhoatdongIds.length - 1) {
      newSelectedLichsuhoatdongIds = newSelectedLichsuhoatdongIds.concat(selectedLichsuhoatdongIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedLichsuhoatdongIds = newSelectedLichsuhoatdongIds.concat(
        selectedLichsuhoatdongIds.slice(0, selectedIndex),
        selectedLichsuhoatdongIds.slice(selectedIndex + 1)
      );
    }

    setSelectedLichsuhoatdongIds(newSelectedLichsuhoatdongIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleUpdate = (id, fieldName, value) => {
    setLichsuhoatdongUpdate({
      ...lichsuhoatdongUpdate,
      [id]: {
        ...lichsuhoatdongUpdate[id],
        [fieldName]: typeof lichsuhoatdongUpdate[id] !== 'undefined' && typeof lichsuhoatdongUpdate[id][fieldName] !== 'undefined' ? value : '',
      },
    });
  };

  const updateChange = async (id, so_dien_thoai, goi_cuoc) => {
    if (typeof lichsuhoatdongUpdate[id] !== 'undefined') {
      console.log(lichsuhoatdongUpdate[id])

      const result = await dispatch(LichsuhoatdongAction.update(id, {so_dien_thoai: typeof lichsuhoatdongUpdate[id]['so_dien_thoai'] !== 'undefined' ? lichsuhoatdongUpdate[id]['so_dien_thoai'] : so_dien_thoai, goi_cuoc: typeof lichsuhoatdongUpdate[id]['goi_cuoc'] !== 'undefined' ? lichsuhoatdongUpdate[id]['goi_cuoc'] : goi_cuoc }))
    }
  };

  const approve = async (id) => {
    const question = "Xác nhận duyệt?" ;
    if (window.confirm(question)) {
      try {
        console.log("Duyệt", id)
        const result = await dispatch(LichsuhoatdongAction.approve(id))
      } catch (error) {

      }
    } else {
      
    }
  };

  const deny = async (id) => {
    const question = "Xác nhận từ chối?" ;
    if (window.confirm(question)) {
      try {
        console.log("Từ chối", id)
        const result = await dispatch(LichsuhoatdongAction.deny(id))
      } catch (error) {

      }
    } else {
      
    }
  };

  const setTrangThaiXuLy = async (id, is_read) => {
    console.log("Đổi trạng thái xử lý", id, is_read)

    const result = await dispatch(LichsuhoatdongAction.setTrangThaiXuLy(id, {is_read}))
  };

  function replaceAll(inputString, search, replacement) {
    // Create a regular expression with the 'g' flag to replace all occurrences
    const regex = new RegExp(search, 'g');
    
    // Use the replace method with the regular expression to replace all occurrences
    const result = inputString.replace(regex, replacement);
    
    return result;
  }

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow style={{ background: 'red' }}>
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  #
                </TableCell>
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Thời gian
                </TableCell>
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Tài khoản
                </TableCell>
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  Nội dung
                </TableCell>
                <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                  IP
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, (page + 1) * limit).map((lichsuhoatdong, index) => (
                <TableRow
                  hover
                  key={lichsuhoatdong.id}
                  selected={selectedLichsuhoatdongIds.indexOf(lichsuhoatdong.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    {moment(lichsuhoatdong.created_at).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {lichsuhoatdong.username} - {lichsuhoatdong.fullname}
                  </TableCell>
                  <TableCell>
                    {lichsuhoatdong.noi_dung}
                  </TableCell>
                  <TableCell>
                    {lichsuhoatdong.ip}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
    </Card>
  );
};

export default LichsuhoatdongLookupDetail;
