import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const PermissionService = {

  create: (payload) => {
    return axios.post('/api/v1/permission', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  update: (id, payload) => {
    return axios.put(`/api/v1/permission/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  findAll: (page, count) => {
    return axios.get(`/api/v1/permission?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/permission/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/permission/${id}`).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  }
}