import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const RoleService = {
  create: (payload) => {
    return axios.post('/api/v1/role', payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  update: (id, payload) => {
    return axios.put(`/api/v1/role/${id}`, payload, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  findAll: (page, count) => {
    return axios.get(`/api/v1/role?page=${page}&count=${count}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  find: (id) => {
    return axios.get(`/api/v1/role/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  },

  delete: (id) => {
    return axios.delete(`/api/v1/role/${id}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      return handleResponse(error.response)
    })
  }
}