import { SystemaccountService } from 'src/services/systemaccount.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const SYSTEMACCOUNT_NEW_CARE_SUCCESS = 'SYSTEMACCOUNT:NEW_CARE_SUCCESS'
export const SYSTEMACCOUNT_LIST_SUCCESS = 'SYSTEMACCOUNT:FIND_ALL_SUCCESS'
export const SYSTEMACCOUNT_LIST_FAILD = 'SYSTEMACCOUNT:FIND_ALL_FAILD'
export const SYSTEMACCOUNT_COUNT_ASSIGN_SUCCESS = 'SYSTEMACCOUNT:COUNT_ASSIGN_SUCCESS'
export const SYSTEMACCOUNT_COUNT_ASSIGN_FAILD = 'SYSTEMACCOUNT:COUNT_ASSIGN_FAILD'

export const SYSTEMACCOUNT_CREATE_SUCCESS = 'SYSTEMACCOUNT:CREATE_SUCCESS'
export const SYSTEMACCOUNT_CREATE_FAILD = 'SYSTEMACCOUNT:CREATE_FAILD'

export const SYSTEMACCOUNT_IMPORT_SUCCESS = 'SYSTEMACCOUNT:IMPORT_SUCCESS'
export const SYSTEMACCOUNT_SAVE_SUCCESS = 'SYSTEMACCOUNT:SAVE_SUCCESS'
export const SYSTEMACCOUNT_SAVE_FAILD = 'SYSTEMACCOUNT:SAVE_SUCCESS'

export const SYSTEMACCOUNT_DEL_SUCCESS = 'SYSTEMACCOUNT:DEL_SUCCESS'
export const SYSTEMACCOUNT_DEL_FAILD = 'SYSTEMACCOUNT:DEL_FAILD'

export const SYSTEMACCOUNT_ONE_SUCCESS = 'SYSTEMACCOUNT:FIND_ONE_SUCCESS'
export const SYSTEMACCOUNT_ONE_FAILD = 'SYSTEMACCOUNT:FIND_ONE_FAILD'

export const SYSTEMACCOUNT_HISTORY_LIST_SUCCESS = 'SYSTEMACCOUNT:HISTORY_FIND_ALL_SUCCESS'
export const SYSTEMACCOUNT_HISTORY_LIST_FAILD = 'SYSTEMACCOUNT:HISTORY_FIND_ALL_FAILD'

export const SystemaccountAction = {
    list,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    findOne,
}


function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        SystemaccountService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: SYSTEMACCOUNT_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: SYSTEMACCOUNT_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { systemaccount } = getState()
        dispatch(AppAction.appLoading(true));
        SystemaccountService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...systemaccount.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: SYSTEMACCOUNT_LIST_SUCCESS, data } }
    function failure(error) { return { type: SYSTEMACCOUNT_LIST_FAILD, error } }
}

function list(page = 0, count = 20,) {
    return (dispatch, getState) => {
        const { systemaccount } = getState()
        dispatch(AppAction.appLoading(true));
        SystemaccountService.findAll(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...systemaccount.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: SYSTEMACCOUNT_LIST_SUCCESS, data } }
    function failure(error) { return { type: SYSTEMACCOUNT_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: SYSTEMACCOUNT_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            SystemaccountService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(SystemaccountAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: SYSTEMACCOUNT_SAVE_SUCCESS, data } }
    function failure(error) { return { type: SYSTEMACCOUNT_SAVE_FAILD, error } }
}

function create(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        SystemaccountService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(SystemaccountAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: SYSTEMACCOUNT_CREATE_SUCCESS, data } }
    function failure(error) { return { type: SYSTEMACCOUNT_CREATE_FAILD, error } }
}

function update(id, payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        SystemaccountService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(SystemaccountAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: SYSTEMACCOUNT_CREATE_SUCCESS, data } }
    function failure(error) { return { type: SYSTEMACCOUNT_CREATE_FAILD, error } }
}

function del(id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        SystemaccountService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(SystemaccountAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: SYSTEMACCOUNT_DEL_SUCCESS, data } }
    function failure(error) { return { type: SYSTEMACCOUNT_DEL_FAILD, error } }
}

function findOne(id) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        SystemaccountService.find(id).then((rs) => {
            dispatch(success(rs.data));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: SYSTEMACCOUNT_ONE_SUCCESS, data } }
    function failure(error) { return { type: SYSTEMACCOUNT_ONE_FAILD, error } }
}

function assign(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        SystemaccountService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(SystemaccountAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { systemaccount } = getState()
        dispatch(AppAction.appLoading(true));
        SystemaccountService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...systemaccount.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: SYSTEMACCOUNT_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: SYSTEMACCOUNT_HISTORY_LIST_FAILD, error } }
}