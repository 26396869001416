import React, { useState } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { CustomerAction } from 'src/actions/customer.action';

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomerPreivewDialog(prod) {
    const classes = useStyles();
    const { temp } = useSelector(state => state.customer)
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(0);
    const dispatch = useDispatch();

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleSavePress = () => {
        dispatch(CustomerAction.saveData(temp))
        prod.handleClose();
    }

    return (
        <Dialog fullScreen open={prod.open} onClose={prod.handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={prod.handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Danh sách khách hàng
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleSavePress}>
                        Lưu
                    </Button>
                </Toolbar>
            </AppBar>
            <PerfectScrollbar>
                <Box sx={{ minWidth: 1050 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    ID
                                </TableCell>
                                <TableCell>
                                    Số điện thoại
                                </TableCell>
                                <TableCell>
                                    Loại thuê bao
                                </TableCell>
                                <TableCell>
                                    Gói cước
                                </TableCell>
                                <TableCell>
                                    Ngày đăng ký
                                </TableCell>
                                <TableCell>
                                    Ngày hết hạn
                                </TableCell>
                                <TableCell>
                                    Chu kỳ hiện tại
                                </TableCell>
                                <TableCell>
                                    Trạng thái
                                </TableCell>
                                <TableCell>
                                    Gói có thể ĐK
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {temp.slice(page * limit, (page + 1) * limit).map((customer, idx) => (
                                <TableRow
                                    hover
                                    key={idx}
                                >
                                    <TableCell padding="checkbox">
                                        {idx + 1}
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color="textPrimary"
                                            variant="body1"
                                        >
                                            {customer.phone}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {customer.type}
                                    </TableCell>
                                    <TableCell>
                                        {customer.package}
                                    </TableCell>
                                    <TableCell>
                                        {customer.registerAt}
                                    </TableCell>
                                    <TableCell>
                                        {customer.expiredAt}
                                    </TableCell>
                                    <TableCell>
                                        {customer.packageCycle}
                                    </TableCell>
                                    <TableCell>
                                        {customer.state}
                                    </TableCell>
                                    <TableCell>
                                        {customer.packageCanRegister}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
            <TablePagination
                component="div"
                count={temp.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[20, 50, 100]}
            />
        </Dialog>
    );
}
