import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import{  DanhsachcomAction } from 'src/actions/danhsachcom.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getBlacklistStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Select as Select1,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

//import CreateEditDialog from './CreateEditDialog';

import { showToast } from 'src/utils/common';

import numeral from 'numeral';

const DanhsachcomDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedBlacklistIds, setSelectedBlacklistIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { items, total, tongSoDuHienTai, daNapHienTai, soTienConCoTheBanDuoc } = useSelector(state => state.danhsachcom);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState({});

  useEffect(() => {
    dispatch(DanhsachcomAction.list(page, limit))
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedBlacklistIds;

    if (event.target.checked) {
      newSelectedBlacklistIds = items.map((danhsachcom) => danhsachcom.id);
    } else {
      newSelectedBlacklistIds = [];
    }

    setSelectedBlacklistIds(newSelectedBlacklistIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedBlacklistIds.indexOf(id);
    let newSelectedBlacklistIds = [];

    if (selectedIndex === -1) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds, id);
    } else if (selectedIndex === 0) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds.slice(1));
    } else if (selectedIndex === selectedBlacklistIds.length - 1) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(
        selectedBlacklistIds.slice(0, selectedIndex),
        selectedBlacklistIds.slice(selectedIndex + 1)
      );
    }

    setSelectedBlacklistIds(newSelectedBlacklistIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, danhsachcom) => {
    setSelected(danhsachcom);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(DanhsachcomAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(DanhsachcomAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const xuLyChucNang = (funcName, PortAlias, PortName, data) => {
    try {
      data = {};
      const response = dispatch(DanhsachcomAction.xuLyChucNang(funcName, PortAlias, PortName, data));
      if (response) {{
        console.log(funcName, PortAlias, PortName, data, response);
        if (funcName == 'CheckBalance' || funcName == 'ReloadBalance') {
          setBalance({
            ...balance,
            [PortAlias]: response.data.data.result.Balance,
          });
          console.log(balance);
        }
      }}
    } catch (error) {
      console.log('Error', error)
    }
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Tổng số dư hiện tại
                </TableCell>
                <TableCell>
                  {numeral(tongSoDuHienTai).format('0,0')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Đã nạp hiện tại
                </TableCell>
                <TableCell>
                  {numeral(daNapHienTai).format('0,0')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Số tiền còn có thể bắn được
                </TableCell>
                <TableCell>
                  {numeral(soTienConCoTheBanDuoc).format('0,0')}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Table style={{marginTop: 50}}>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Tên cổng
                </TableCell>
                <TableCell>
                  Trạng thái
                </TableCell>
                <TableCell>
                  Số điện thoại
                </TableCell>
                <TableCell>
                  Số dư
                </TableCell>
                <TableCell>
                  Đã nạp
                </TableCell>
                <TableCell>
                  Thao tác
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((danhsachcom, index) => (
                <TableRow
                  hover
                  key={danhsachcom.id}
                  selected={selectedBlacklistIds.indexOf(danhsachcom.id) !== -1}
                >
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      fontWeight="bold"
                    >
                      {danhsachcom.PortAlias}
                    </Typography>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      fontSize="14px"
                    >
                      ({danhsachcom.PortName})
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={danhsachcom.IsReady == true ? 'green' : 'red'}
                      variant="body1"
                    >
                      {danhsachcom.IsReady == true ? 'OK' : 'N/A'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {danhsachcom.NumberPhone}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(typeof balance[danhsachcom.PortAlias] !== 'undefined' ? balance[danhsachcom.PortAlias] : danhsachcom.Balance).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {numeral(danhsachcom.ChargedAmount).format('0,0')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Select1 onChange={(e) => xuLyChucNang(e.target.value, danhsachcom.PortAlias, danhsachcom.PortName)}>
                      <MenuItem value="-99"></MenuItem>
                      <MenuItem value="CheckBalance">Kiểm tra số dư hiện tại của COM</MenuItem>
                      <MenuItem value="ReloadBalance">Tải lại số dư của COM</MenuItem>
                      <MenuItem value="ActivePassword">Tạo mật khẩu M2U</MenuItem>
                      <MenuItem value="ChangePassword">Đổi mật khẩu M2U</MenuItem>
                      <MenuItem value="ResetPort">Chạy lại COM</MenuItem>
                    </Select1>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      {/* <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} /> */}
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default DanhsachcomDetail;
