import {
  useEffect,
  useState,
} from 'react';

import { showToast } from 'src/utils/common';

import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
  Check,
  X,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { RequestrefundreportAction } from 'src/actions/requestrefundreport.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getRequestrefundreportStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  MenuItem,
  Select,
} from '@material-ui/core';
import CreateEditDialog from './CreateEditDialog';

import { isGrantPermission } from 'src/utils/utils';
import { BorderAll } from '@material-ui/icons';

import numeral from 'numeral';

const RequestrefundreportLookupDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedRequestrefundreportIds, setSelectedRequestrefundreportIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { items, total, filter, tongHoanThem, tongHoanTien, tongNapTien, tongTienSauChietKhau } = useSelector(state => state.requestrefundreport);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const { info, permissions, role } = useSelector(state => state.profile)
  const [trangThaiTruyThuHoanTheoGoi, setTrangThaiTruyThuHoanTheoGoi1] = useState({});
  const [trangThaiTruyThuHoanThem, setTrangThaiTruyThuHoanThem1] = useState({});

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
    };
    dispatch(RequestrefundreportAction.updateFilter(payload));
    dispatch(RequestrefundreportAction.list(page, limit))
  }, [page, limit])

  const handleSelectAll = (event) => {
    let newSelectedRequestrefundreportIds;

    if (event.target.checked) {
      newSelectedRequestrefundreportIds = items.map((requestrefundreport) => requestrefundreport.id);
    } else {
      newSelectedRequestrefundreportIds = [];
    }

    setSelectedRequestrefundreportIds(newSelectedRequestrefundreportIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRequestrefundreportIds.indexOf(id);
    let newSelectedRequestrefundreportIds = [];

    if (selectedIndex === -1) {
      newSelectedRequestrefundreportIds = newSelectedRequestrefundreportIds.concat(selectedRequestrefundreportIds, id);
    } else if (selectedIndex === 0) {
      newSelectedRequestrefundreportIds = newSelectedRequestrefundreportIds.concat(selectedRequestrefundreportIds.slice(1));
    } else if (selectedIndex === selectedRequestrefundreportIds.length - 1) {
      newSelectedRequestrefundreportIds = newSelectedRequestrefundreportIds.concat(selectedRequestrefundreportIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRequestrefundreportIds = newSelectedRequestrefundreportIds.concat(
        selectedRequestrefundreportIds.slice(0, selectedIndex),
        selectedRequestrefundreportIds.slice(selectedIndex + 1)
      );
    }

    setSelectedRequestrefundreportIds(newSelectedRequestrefundreportIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, requestrefundreport) => {
    setSelected(requestrefundreport);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(RequestrefundreportAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    console.log(id);
    dispatch(RequestrefundreportAction.findOne(id));
    setOpen(true);
  };

  const setTrangThaiTruyThuHoanTheoGoi = async (id, trang_thai_truy_thu_hoan_theo_goi) => {
    const question = trang_thai_truy_thu_hoan_theo_goi == 'binh_thuong' ? "Xác nhận đổi trạng thái 'Bình thường'?" : (trang_thai_truy_thu_hoan_theo_goi == 'da_truy_thu' ? "Xác nhận đổi trạng thái 'Đã truy thu'?" : "Xác nhận đổi trạng thái 'Truy thu'?") ;
    if (window.confirm(question)) {
      setTrangThaiTruyThuHoanTheoGoi1({
        ...trangThaiTruyThuHoanTheoGoi,
        [id]: trang_thai_truy_thu_hoan_theo_goi,
      });
      try {
        const currentOriginUrl = window.location.origin;
        const response = await dispatch(RequestrefundreportAction.setTrangThaiTruyThuHoanTheoGoi(id, { currentOriginUrl, trang_thai_truy_thu_hoan_theo_goi }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  };

  const setTrangThaiTruyThuHoanThem = async (id, trang_thai_truy_thu_hoan_them) => {
    const question = trang_thai_truy_thu_hoan_them == 'binh_thuong' ? "Xác nhận đổi trạng thái 'Bình thường'?" : (trang_thai_truy_thu_hoan_them == 'da_truy_thu' ? "Xác nhận đổi trạng thái 'Đã truy thu'?" : "Xác nhận đổi trạng thái 'Truy thu'?") ;
    if (window.confirm(question)) {
      setTrangThaiTruyThuHoanThem1({
        ...trangThaiTruyThuHoanThem,
        [id]: trang_thai_truy_thu_hoan_them,
      });
      try {
        const currentOriginUrl = window.location.origin;
        const response = await dispatch(RequestrefundreportAction.setTrangThaiTruyThuHoanThem(id, { currentOriginUrl, trang_thai_truy_thu_hoan_them }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  };

  const setTrangThaiGiaoDich = async (id, trangThaiGiaoDich) => {
    const question = trangThaiGiaoDich == -9999 ? "Xác nhận chọn trạng thái 'HOÀN THÀNH'?" : "Xác nhận chọn trạng thái 'HOÀN TIỀN'?" ;
    if (window.confirm(question)) {
      try {
        const response = await dispatch(RequestrefundreportAction.setTrangThaiGiaoDich(id, { ma_trang_thai_giao_dich_doi_tac: trangThaiGiaoDich }));
        if (typeof response.channel !== 'undefined') alert("[Thông báo từ '"+response.channel+"']\n"+response.message+(response.status != "Unknown" ? (" ("+response.status+")") : ""));
        else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
      } catch (error) {
        if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
      }
    } else {
      
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        console.log('String copied to clipboard:', content);
        showToast('success', `Đã copy: ${content}`)
      })
      .catch((error) => {
        console.error('Unable to copy string:', error);
        showToast('error', `Lỗi không thể copy: ${content}`)
      });
  }

  const date = new Date(); // Current date and time

  // Formatting options
  const date_options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  return (
    <Card {...rest} style={{ width: "100%"}}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
            <TableRow>
              <TableCell style={{color: "green"}}>
                  TỔNG HOÀN TIỀN
                </TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongHoanTien).format('0,0')}
                </TableCell>
                <TableCell style={{color: "green"}}>
                  TỔNG HOÀN THÊM
                </TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongHoanThem).format('0,0')}
                </TableCell>
                <TableCell style={{color: "green"}}>
                  TỔNG NẠP TIỀN
                </TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongNapTien).format('0,0')}
                </TableCell>
                <TableCell style={{color: "green"}}>
                  TỔNG TIỀN SAU CHIẾT KHẤU
                </TableCell>
                <TableCell style={{color: "green"}}>
                  {numeral(tongTienSauChietKhau).format('0,0')}
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                {1 < 0 && <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRequestrefundreportIds.length === items.length}
                    color="primary"
                    indeterminate={
                      selectedRequestrefundreportIds.length > 0
                      && selectedRequestrefundreportIds.length < items.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>}
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Username - Tên NV
                </TableCell>
                <TableCell>
                  SĐT - Loại TB - Gói cước
                </TableCell>
                <TableCell style={{ width: '150px' }}>
                  Số tiền HOÀN hoặc NẠP
                </TableCell>
                <TableCell style={{ width: '200px' }}>
                  Trạng thái
                </TableCell>
                {1 < 0 && <TableCell>
                  Ghi nhận báo cáo
                </TableCell>}
                <TableCell>
                  Yêu cầu - Hình thức
                </TableCell>
                <TableCell style={{ width: '250px' }}>
                  Trạng thái truy thu tiền hoàn theo gói
                </TableCell>
                <TableCell style={{ width: '150px' }}>
                  Số tiền sau chiết khấu
                </TableCell>
                <TableCell style={{ width: '250px' }}>
                  Trạng thái truy thu tiền hoàn thêm
                </TableCell>
                <TableCell style={{ width: '150px' }}>
                  Số tiền hoàn thêm
                </TableCell>
                <TableCell>
                  Thời gian yêu cầu
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(page * limit, page * limit + limit).map((requestrefundreport, index) => (
                <TableRow
                  hover
                  key={requestrefundreport.id}
                  selected={selectedRequestrefundreportIds.indexOf(requestrefundreport.id) !== -1}
                >
                  {1 < 0 && <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRequestrefundreportIds.indexOf(requestrefundreport.id) !== -1}
                      onChange={(event) => handleSelectOne(event, requestrefundreport.id)}
                      value="true"
                    />
                  </TableCell>}
                  <TableCell>
                    {page * limit + index + 1}
                  </TableCell>
                  <TableCell>
                    {requestrefundreport.staff_username} - {requestrefundreport.staff_fullname}
                  </TableCell>
                  <TableCell>
                    <Button style={{padding: 0}} onClick={() => copyToClipboard(requestrefundreport.number_phone.slice(-9))}>
                      {role.priority < 3 ? requestrefundreport.number_phone.slice(-9) : ('****' + requestrefundreport.number_phone.slice(-5))}
                    </Button>
                    {requestrefundreport.number_type == 1 ? " - MC" : " - MG"} - {requestrefundreport.package_code}
                  </TableCell>
                  <TableCell style={{fontSize: '18px', fontWeight: 'bold', color: 'blue'}}>
                    {numeral(requestrefundreport.refund_price).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {requestrefundreport.trang_thai_giao_dich_doi_tac}
                  </TableCell>
                  {1 < 0 && <TableCell>
                    {requestrefundreport.request_status == 1 ? "Đã ghi nhận" : "Chưa ghi nhận"}
                  </TableCell>}
                  <TableCell>
                    {requestrefundreport.request_type == 1 && "Hoàn trước"}
                    {requestrefundreport.request_type == 2 && "Hoàn sau"}
                    {requestrefundreport.request_type == 3 && "Không hoàn"}
                    {requestrefundreport.request_type == 4 && "Hoàn thêm"}
                    {requestrefundreport.request_type == 5 && "Nạp tiền"}
                    {requestrefundreport.request_method == 1 && " - Gia hạn"}
                    {requestrefundreport.request_method == 2 && " - Nâng cấp T1"}
                    {requestrefundreport.request_method == 3 && " - Nâng cấp T2"}
                    {requestrefundreport.request_method == 4 && " - Nâng cấp T3"}
                  </TableCell>
                  <TableCell>
                    <Select style={{ fontSize: "16px", width: "100%", marginBottom: '15px', color: (trangThaiTruyThuHoanTheoGoi[requestrefundreport.id] == 'binh_thuong' ? 'green' : (trangThaiTruyThuHoanTheoGoi[requestrefundreport.id] == 'da_truy_thu' ? 'blue' : (requestrefundreport.trang_thai_truy_thu_hoan_theo_goi == 'binh_thuong' ? 'green' : (requestrefundreport.trang_thai_truy_thu_hoan_theo_goi == 'da_truy_thu' ? 'blue' : 'red')))) }} variant="outlined" value={trangThaiTruyThuHoanTheoGoi[requestrefundreport.id] || requestrefundreport.trang_thai_truy_thu_hoan_theo_goi} onChange={(e) => setTrangThaiTruyThuHoanTheoGoi(requestrefundreport.id, e.target.value)}>
                      <MenuItem value="truy_thu" style={{color: 'red'}}>TRUY THU</MenuItem>
                      <MenuItem value="da_truy_thu" style={{color: 'blue'}}>ĐÃ TRUY THU</MenuItem>
                      <MenuItem value="binh_thuong" style={{color: 'green'}}>{requestrefundreport.request_type != 5 ? 'BÌNH THƯỜNG' : 'ĐÃ THANH TOÁN'}</MenuItem>
                    </Select>
                    Người duyệt: <span style={{fontWeight: 'bold', color: requestrefundreport.username_duyet_trang_thai_truy_thu_hoan_theo_goi != '__AUTO' ? 'blue' : 'gray'}}>{requestrefundreport.username_duyet_trang_thai_truy_thu_hoan_theo_goi}</span>
                  </TableCell>
                  {requestrefundreport.request_type == 5 && <TableCell style={{fontSize: '18px', fontWeight: 'bold', color: 'blue'}}>
                    {numeral(requestrefundreport.transfer_final_amount).format('0,0')}<br></br><br></br>{"(Tỉ lệ: " + (requestrefundreport.ti_le_chiet_khau != 0 ? requestrefundreport.ti_le_chiet_khau : (requestrefundreport.refund_price != 0 ? parseInt((requestrefundreport.refund_price - requestrefundreport.transfer_final_amount) / requestrefundreport.refund_price * 100) : 0)) + "%)"}
                  </TableCell> || <TableCell></TableCell>}
                  <TableCell>
                    <Select style={{ fontSize: "16px", width: "100%", marginBottom: '15px', color: (trangThaiTruyThuHoanThem[requestrefundreport.id] == 'binh_thuong' ? 'green' : (trangThaiTruyThuHoanThem[requestrefundreport.id] == 'da_truy_thu' ? 'blue' : (requestrefundreport.trang_thai_truy_thu_hoan_them == 'binh_thuong' ? 'green' : (requestrefundreport.trang_thai_truy_thu_hoan_them == 'da_truy_thu' ? 'blue' : 'red')))) }} variant="outlined" value={trangThaiTruyThuHoanThem[requestrefundreport.id] || requestrefundreport.trang_thai_truy_thu_hoan_them} onChange={(e) => setTrangThaiTruyThuHoanThem(requestrefundreport.id, e.target.value)}>
                      <MenuItem value="truy_thu" style={{color: 'red'}}>TRUY THU</MenuItem>
                      <MenuItem value="da_truy_thu" style={{color: 'blue'}}>ĐÃ TRUY THU</MenuItem>
                      <MenuItem value="binh_thuong" style={{color: 'green'}}>{requestrefundreport.request_type != 5 ? 'BÌNH THƯỜNG' : 'ĐÃ THANH TOÁN'}</MenuItem>
                    </Select>
                    Người duyệt: <span style={{fontWeight: 'bold', color: requestrefundreport.username_duyet_trang_thai_truy_thu_hoan_them != '__AUTO' ? 'blue' : 'gray'}}>{requestrefundreport.username_duyet_trang_thai_truy_thu_hoan_them}</span>
                  </TableCell>
                  <TableCell style={{fontSize: '18px', fontWeight: 'bold', color: 'blue'}}>
                    {numeral(requestrefundreport.other_refund).format('0,0')}
                  </TableCell>
                  <TableCell>
                    {moment(requestrefundreport.created_time).format('DD-MM-YYYY HH:mm:ss')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      />
      <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} />
      <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
    </Card>
  );
};

export default RequestrefundreportLookupDetail;
