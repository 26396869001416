import { toast } from 'react-toastify';

export const appFilter = (filter, page = 1, pageSize = 15) => {
  const skip = ((page - 1) * pageSize);
  let filterQuery = `filter[skip]=${skip}&filter[take]=${pageSize}&filter[order][createdAt]=DESC`;
  if (!filter) {
    return ''
  }
  if (filter.where) {
    const where = filter.where;
    const keys = Object.keys(where);
    const whereCondition = []
    for (const i in Object.keys(where)) {
      if (typeof where[keys[i]] === 'string') {
        whereCondition.push(`filter[where][${keys[i]}]=${where[keys[i]]}`)
      } else {
        if (Array.isArray(Object.keys(where[keys[i]]))) {
          for (let valKey in Object.keys(where[keys[i]])) {
            whereCondition.push(`filter[where][${keys[i]}][${valKey}]=${Object.keys(where[keys[i]])[valKey]}`)
          }
        } else {
          for (const j of Object.keys(where[keys[i]])) {
            if (typeof where[keys[i]][j] === 'string') {
              whereCondition.push(`filter[where][${i}][${keys[i]}][${j}]=${where[keys[i]][j]}`)
            } else if (typeof where[keys[i]][j] === 'object') {
              for (let valIndex in where[keys[i]][j]) {
                whereCondition.push(`filter[where][${keys[i]}][${j}]=${where[keys[i]][j][valIndex]}`)
              }
            }
          }
        }
      }
    }
    filterQuery = `${filterQuery}&${whereCondition.join('&')}`
  }
  return filterQuery;
}


export const usersQuery = (payload) => {
  const query = []
  for (const key of Object.keys(payload)) {
    query.push(`${key}=${payload[key]}`)
  }
  return query.join('&')
}


export const appFilterWhere = (obj, prefix) => {
  var str = [], k, v;
  for (var p in obj) {
    if (!obj.hasOwnProperty(p)) { continue; } // skip things from the prototype
    if (~p.indexOf('[')) {
      k = prefix ? prefix + "[" + p.substring(0, p.indexOf('[')) + "]" + p.substring(p.indexOf('[')) : p;
    } else {
      k = prefix ? prefix + "[" + p + "]" : p;
    }
    v = obj[p];
    str.push(typeof v == "object" ?
      appFilterWhere(v, k) :
      encodeURIComponent(k) + "=" + encodeURIComponent(v));
  }
  return str.join("&");
}

export const getCookie = (key) => {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export const deleteAllCookies = () => {
  let cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export const generatePassword = () => {
  // Create a random password
  const randomPassword =
    Math.random().toString(36).slice(10) + Math.random().toString(36).slice(10);
  // Set the generated password as state
  return randomPassword;
  // // Copy the password to the clipboard
  // navigator.clipboard.writeText(randomPassword);
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const showToast = (type, message) => {
  if (type === 'error' || type === 'failed') {
    toast.error(message, {
      position: "top-left",
      autoClose: 3e3,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  } else {
    toast.dismiss()
    toast.success(message, {
      position: "top-left",
      autoClose: 3e3,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined
    });
  }

}



