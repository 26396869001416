import { UploadService } from 'src/services/upload.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const UPLOAD_FIND_ALL_SUCCESS = 'UPLOAD:FIND_ALL_SUCCESS'
export const UPLOAD_CREATE_SUCCESS = 'UPLOAD:CREATE_SUCCESS'
export const UPLOAD_TEMP_DATA = 'UPLOAD:TEMP_DATA'
export const UPLOAD_DATA_CREATED = 'UPLOAD:DATA_CREATED'
export const UploadAction = {
  list,
  createTemp,
  create,
  del,
  createdData
}


function createTemp(payload) {
  return { type: UPLOAD_TEMP_DATA, data: payload }
}

function createdData() {
  return { type: UPLOAD_DATA_CREATED }
}

function list(page = 1, count = 50,) {
  return (dispatch, getState) => {
    const { upload } = getState()
    dispatch(AppAction.appLoading(true));
    UploadService.findAll({ page, count }).then((rs) => {
      if (page === 1 || page === '1') {
        dispatch(success({
          items: rs.data.values,
          total: rs.data.count
        }));
      } else {
        dispatch(success({
          items: [...upload.items, ...rs.data.values],
          total: rs.data.count
        }));
      }
    }).catch((error) => {
      if (error) {
        showToast(NOTI_TYPES.ERROR, error.msg)
      } else {
        showToast(NOTI_TYPES.ERROR, "Lấy danh sách upload không thành công")
      }
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: UPLOAD_FIND_ALL_SUCCESS, data } }
}

function create(payload) {
  return (dispatch) => {
    dispatch(AppAction.appLoading(true));
    UploadService.create(payload).then((rs) => {
      dispatch(success(rs.data))
      dispatch(list())
    }).catch((error) => {
      if (error) {
        showToast(NOTI_TYPES.ERROR, error.msg)
      } else {
        showToast(NOTI_TYPES.ERROR, "Lấy danh sách upload không thành công")
      }
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: UPLOAD_CREATE_SUCCESS, data } }
}


function del(id) {
  return (dispatch) => {
    dispatch(AppAction.appLoading(true));
    UploadService.del(id).then((data) => {
      showToast(NOTI_TYPES.SUCCESS, "Xoá data upload thành công.")
      dispatch(list())
    }).catch((error) => {
      if (error) {
        showToast(NOTI_TYPES.ERROR, error.msg)
      } else {
        showToast(NOTI_TYPES.ERROR, "Lấy danh sách upload không thành công")
      }
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
}