import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { SystemaccountAction } from 'src/actions/systemaccount.action';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
} from '@material-ui/core';

const CreateEditDialog = (props) => {
    const dispatch = useDispatch()
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.systemaccount)
    const [position, setPosition] = useState("")
    const [account, setAccount] = useState("")
    const [password, setPassword] = useState("")
    const [additional_content, setAdditionalContent] = useState("")
    const [api, setApi] = useState("")

    useEffect(() => {
        if (items.length > 0) {
            return;
        }
        dispatch(PermissionAction.list(0, 100))
    }, [])

    useEffect(() => {
        if (props.type === 'EDIT') {
            if (selected) {
                setPosition(selected.position)
                setAccount("")
                setPassword("")
                setApi("")
                setAdditionalContent("")
            }
        }
    }, [selected])

    const onPressPositive = () => {
        if (position === "") {
            return;
        }
        if (account === "") {
            return;
        }
        if (password === "") {
            return;
        }
        if (api === "") {
            return;
        }
        if (props.type === 'EDIT') {
            dispatch(SystemaccountAction.update(selected.id, { position, account, password, api, additional_content }))
        } else {
            dispatch(SystemaccountAction.create({ position, account, password, api, additional_content }))
        }
        resetState();
        props.handleClose();
    }

    const resetState = () => {
        setPosition("")
        setAccount("")
        setPassword("")
        setApi("")
        setAdditionalContent("")
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>{props.type === 'EDIT' ? "Cập nhật bản ghi" : "Tạo bản ghi mới"}</DialogTitle>
            <DialogContent style={{ width: 600, paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Đăng nhập tại"
                            name="position"
                            onChange={(e) => setPosition(e.target.value)}
                            required
                            value={position}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="API URL"
                            name="api"
                            onChange={(e) => setApi(e.target.value)}
                            required
                            value={api}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Username"
                            name="account"
                            onChange={(e) => setAccount(e.target.value)}
                            required
                            value={account}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            value={password}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Thông tin thêm (JSON)"
                            name="additional_content"
                            onChange={(e) => setAdditionalContent(e.target.value)}
                            value={additional_content}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={onPressPositive}>{props.type === 'EDIT' ? "Cập nhật" : "Tạo"}</Button>
            </DialogActions>
        </Dialog >
    );
}

export default CreateEditDialog;