import {
  useEffect,
  useState,
} from 'react';
import {
  Edit3 as Edit3Icon,
  Lock,
  RefreshCcw,
  Trash2 as Trash2Icon,
  Unlock,
} from 'react-feather';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LssoduAction } from 'src/actions/lssodu.action';
import {
  CONFIRM_TYPES,
  DEFAULT_ROW_PER_PAGE,
  ROW_PER_PAGE_OPTIONS,
} from 'src/utils/constants';
import { getBlacklistStatus } from 'src/utils/utils';
import ConfirmDialog from '../ConfirmDialog';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Button,
  TextField,
  SvgIcon,
  InputAdornment,
  CardContent,
  MenuItem,
  Select as Select1,
} from '@material-ui/core';

import Select from 'react-select';

import { Link } from 'react-router-dom';

//import CreateEditDialog from './CreateEditDialog';

import { showToast } from 'src/utils/common';

import numeral from 'numeral';

import { Search as SearchIcon } from 'react-feather';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { AppAction } from 'src/actions/app.action';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { Download, Cached } from '@material-ui/icons';
import { AccountAction } from 'src/actions/account.acction';

const LssoduDetail = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [selectedBlacklistIds, setSelectedBlacklistIds] = useState([]);
  const [limit, setLimit] = useState(DEFAULT_ROW_PER_PAGE);
  const [page, setPage] = useState(0);
  const { items, total, filter, tong_hoa_hong, cong_so_du, tru_so_du, tru_so_du_don_nap, hoan_tien_that_bai_don } = useSelector(state => state.lssodu);
  const [confirm, setConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const { info, permissions, role } = useSelector(state => state.profile)
  const { isLoading } = useSelector(state => state.app);
  const { items: account_items, total: account_total } = useSelector(state => state.account)

  const allOption = { username: '', fullname: 'Tất cả', role: { priority: 0 } };
  const options = [allOption, ...account_items];
  
  const [values, setValues] = useState({
    username: info.username,
    so_dien_thoai: "",
    reason: "",
    loai_giao_dich: "-99",
    startDate: filter ? dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')) : '',
    endDate: filter ? dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')) : '',
  });

  useEffect(() => {
    dispatch(AccountAction.listNoLimit());
  }, [])

  useEffect(() => {
    const payload = {
      ...filter,
      limit: limit,
      page: page,
      username: values.username,
      so_dien_thoai: values.so_dien_thoai,
      reason: values.reason,
      loai_giao_dich: values.loai_giao_dich,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(LssoduAction.updateFilter(payload));
    dispatch(LssoduAction.list())
  }, [page, limit])

  const onLookup = (immediatelyPayload = {}) => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      username: typeof immediatelyPayload.username !== 'undefined' ? immediatelyPayload.username : values.username,
      so_dien_thoai: typeof immediatelyPayload.so_dien_thoai !== 'undefined' ? immediatelyPayload.so_dien_thoai : values.so_dien_thoai,
      reason: typeof immediatelyPayload.reason !== 'undefined' ? immediatelyPayload.reason : values.reason,
      loai_giao_dich: typeof immediatelyPayload.loai_giao_dich !== 'undefined' ? immediatelyPayload.loai_giao_dich : values.loai_giao_dich,
      startDate: typeof immediatelyPayload.startDate !== 'undefined' ? immediatelyPayload.startDate.format("DD/MM/YYYY") : values.startDate.format("DD/MM/YYYY"),
      endDate: typeof immediatelyPayload.endDate !== 'undefined' ? immediatelyPayload.endDate.format("DD/MM/YYYY") : values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(LssoduAction.updateFilter(payload));
    dispatch(LssoduAction.list())
  };

  const exportExcel = async (ma_don = null) => {
    dispatch(AppAction.appLoading(true))
    try {
      const payload = {
        page: 0,
        limit: 9999999999,
        username: values.username,
        so_dien_thoai: values.so_dien_thoai,
        reason: values.reason,
        loai_giao_dich: values.loai_giao_dich,
        startDate: values.startDate.format("DD/MM/YYYY"),
        endDate: values.endDate.format("DD/MM/YYYY"),
      };
      dispatch(LssoduAction.updateFilter(payload));
      const response = await dispatch(LssoduAction.list());

      const data = [
        [
          'Username được tác động',
          'Tên TK được tác động',
          'Nạp / Thu hồi',
          'Số dư sau biến động',
          'Lý do',
          'SĐT',
          'Thời điểm ghi nhận',
        ],
      ];

      if (typeof response !== 'undefined' && response != null && typeof response.data !== 'undefined' && response.data != null && typeof response.data.values !== 'undefined' && response.data.values != null && response.data.values.length > 0) {
        var count = 1;
        response.data.values.forEach((element) => {
          data.push([
            element.username,
            element.full_name,
            element.value,
            element.so_du_sau_bien_dong,
            element.reason,
            element.thue_bao,
            moment(element.created_at).format('DD-MM-YYYY HH:mm:ss'),
          ])
          count++;
        })

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Danh-sach-don-nap-tien.xlsx');
      }

      dispatch(AppAction.appLoading(false))
    } catch (error) {
      if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n" + error.msg)
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  const handleStartDateChange = (date) => {

    setValues({
      ...values,
      startDate: date
    });

    onLookup({ startDate: date });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });

    onLookup({ endDate: date });
  };

  const handleSelectAll = (event) => {
    let newSelectedBlacklistIds;

    if (event.target.checked) {
      newSelectedBlacklistIds = items.map((lssodu) => lssodu.id);
    } else {
      newSelectedBlacklistIds = [];
    }

    setSelectedBlacklistIds(newSelectedBlacklistIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedBlacklistIds.indexOf(id);
    let newSelectedBlacklistIds = [];

    if (selectedIndex === -1) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds, id);
    } else if (selectedIndex === 0) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds.slice(1));
    } else if (selectedIndex === selectedBlacklistIds.length - 1) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(selectedBlacklistIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedBlacklistIds = newSelectedBlacklistIds.concat(
        selectedBlacklistIds.slice(0, selectedIndex),
        selectedBlacklistIds.slice(selectedIndex + 1)
      );
    }

    setSelectedBlacklistIds(newSelectedBlacklistIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleConfirmOpen = (type, lssodu) => {
    setSelected(lssodu);
    setConfirmType(type);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      setTitle("Xoá bản ghi")
      setContent(`Xác nhận xoá bản ghi `)
    }
    setConfirm(true);
  };

  const onConfirm = () => {
    setConfirm(false);
    if (confirmType === CONFIRM_TYPES.DELETE) {
      dispatch(LssoduAction.del(selected.id))
      return;
    }
  }

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const onSelected = (id) => {
    dispatch(LssoduAction.findOne(id));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tableStyle = {
    marginTop: 15,
    fontSize: '14px !important',
  };

  const childElementStyle = {
    fontSize: '14px !important',
  };

  const colorByStatus = (status) => {
    if (status == "hoa_hong") return '#000000';
    else if (status == "cong_so_du") return '#000000';
    else if (status == "tru_so_du") return '#000000';
    else if (status == "tru_so_du_don_nap") return '#000000';
    else if (status == "hoan_tien_that_bai_don") return '#000000';
    else return '';
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    onLookup({ [event.target.name]: event.target.value });
  };

  const handleUsernameChange1 = (name, newValue) => {
    setValues({
      ...values,
      [name]: newValue
    });

    onLookup({[name]: newValue});
  };

  return (
    <><Card className="filter_card">
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <PerfectScrollbar>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: 1050,
            }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="reason"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={values.reason}
                placeholder="Tìm lý do"
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
              />
              <div style={{ width: '250px', zIndex: '10' }} className="full-width-select">
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: 56,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    }),
                  }}
                  options={options}
                  getOptionLabel={(item) => `${item.role.priority == 4 ? '--' : (item.role.priority == 5 ? '----' : '')} ${item.username != '' ? (item.username + ' - ') : ''}${item.fullname}`}
                  placeholder={values.username || "Tìm user..."}
                  components={{
                    DropdownIndicator: () => <SearchIcon size={16} color="action" />,
                    IndicatorSeparator: null,
                  }}
                  value={values.username || null}
                  onChange={(newValue) => handleUsernameChange1('username', newValue.username)}
                  isSearchable
                />
              </div>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                name="so_dien_thoai"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={values.so_dien_thoai}
                placeholder="Tìm theo SĐT"
                variant="outlined"
                style={{ height: 56, marginTop: 6, width: "200px", marginLeft: 0 }}
              />
              <Select1
                style={{ height: 56, marginTop: 6, width: "250px", marginLeft: 0 }}
                name="loai_giao_dich"
                value={values.loai_giao_dich || -99}
                onChange={(newValue) => handleChange(newValue)}
                variant="outlined"
                startAdornment={(
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )}
              >
                <MenuItem value="-99">Loại giao dịch...</MenuItem>
                <MenuItem value="hoa_hong" style={{ color: colorByStatus('hoa_hong') }}>Hoa hồng</MenuItem>
                <MenuItem value="cong_so_du" style={{ color: colorByStatus('cong_so_du') }}>Cộng số dư</MenuItem>
                <MenuItem value="tru_so_du" style={{ color: colorByStatus('tru_so_du') }}>Trừ số dư</MenuItem>
                <MenuItem value="tru_so_du_don_nap" style={{ color: colorByStatus('tru_so_du_don_nap') }}>Trừ số dư đơn nạp</MenuItem>
                <MenuItem value="hoan_tien_that_bai_don" style={{ color: colorByStatus('hoan_tien_that_bai_don') }}>Hoàn tiền thất bại đơn</MenuItem>
              </Select1>
              <DatePicker
                label="Thời gian bắt đầu"
                style={{ width: '200px' }}
                format='DD/MM/YYYY'
                value={values.startDate}
                onChange={(newValue) => handleStartDateChange(newValue)}
              />
              <DatePicker
                label="Thời gian kết thúc"
                style={{ width: '200px' }}
                value={values.endDate}
                format='DD/MM/YYYY'
                onChange={(newValue) => handleEndDateChange(newValue)}
              />
            </Box>
          </PerfectScrollbar>
        </LocalizationProvider>
      </CardContent>
    </Card>
      <Card {...rest}>
        <div style={{ width: '80px', margin: 'auto' }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => exportExcel()}
            style={{ height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '0', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px' }}
          >
            <Download style={{ color: "#3498db" }} />
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onLookup}
            style={{ background: !isLoading ? 'transparent' : 'url(/static/images/loading.gif) no-repeat center center / contain', height: '20px', padding: 0, marginTop: 0, width: '20px', minWidth: '20px', marginLeft: '5px', backgroundColor: 'transparent', fontWeight: 'normal', fontSize: '20px' }}
          >
            {!isLoading ? <Cached style={{ color: "#3498db" }} /> : ''}
          </Button>
        </div>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table style={tableStyle}>
              <TableHead>
                <TableRow style={{ background: 'red' }}>
                  <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    #
                  </TableCell>
                  <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    Username được tác động
                  </TableCell>
                  <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    Nạp / Thu hồi
                  </TableCell>
                  <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    Số dư sau biến động
                  </TableCell>
                  <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    Lý do
                  </TableCell>
                  <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    SĐT
                  </TableCell>
                  <TableCell style={{ color: 'white', padding: 0, textAlign: 'center', border: '0.5px solid white' }}>
                    Thời điểm ghi nhận
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items && items.length > 0 && items.slice(page * limit, page * limit + limit).map((lssodu, index) => (
                  <TableRow
                    hover
                    key={lssodu.id}
                    selected={selectedBlacklistIds.indexOf(lssodu.id) !== -1}
                  >
                    <TableCell style={childElementStyle}>
                      {page * limit + index + 1}
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        fontWeight="bold"
                      >
                        {lssodu.username} - {lssodu.full_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        fontSize="14px"
                      >
                        {numeral(lssodu.value).format('0,0')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        fontSize="14px"
                      >
                        {numeral(lssodu.so_du_sau_bien_dong).format('0,0')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {lssodu.reason}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {lssodu.thue_bao}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {moment(lssodu.created_at).format('DD-MM-YYYY HH:mm:ss')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <PerfectScrollbar>
          <div style={{ margin: 15, minWidth: 1050 }}>
            <span style={{ marginRight: 15 }}>Tổng lợi nhuận: <span style={{ color: 'blue', fontWeight: 'bold' }}>{numeral(tong_hoa_hong).format('0,0')}</span></span>
            |
            <span style={{ marginLeft: 15, marginRight: 15 }}>Tổng số dư chuyển đến: <span style={{ color: 'blue', fontWeight: 'bold' }}>{numeral(cong_so_du).format('0,0')}</span></span>
            |
            <span style={{ marginLeft: 15, marginRight: 15 }}>Tổng số dư chuyển đi: <span style={{ color: 'blue', fontWeight: 'bold' }}>{numeral(tru_so_du).format('0,0')}</span></span>
            |
            <span style={{ marginLeft: 15, marginRight: 15 }}>Tổng trừ đơn nạp: <span style={{ color: 'blue', fontWeight: 'bold' }}>{numeral(tru_so_du_don_nap).format('0,0')}</span></span>
            |
            <span style={{ marginLeft: 15, marginRight: 15 }}>Hoàn tiền thất bại đơn: <span style={{ color: 'blue', fontWeight: 'bold' }}>{numeral(hoan_tien_that_bai_don).format('0,0')}</span></span>
          </div>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
        />
        {/* <CreateEditDialog open={open} handleClose={handleClose} type={"EDIT"} /> */}
        <ConfirmDialog open={confirm} title={title} content={content || `Xác nhận xoá bản ghi `} highlight={selected.ten_goi_cuoc} handleClose={handleConfirmClose} handleConfirm={onConfirm} />
      </Card></>
  );
};

export default LssoduDetail;
