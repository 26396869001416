import { Helmet } from 'react-helmet';
import ThongkethengayDetail from 'src/components/thongkethengay-detail/ThongkethengayDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

const Thongkethengay = () => (
  <>
    <Helmet>
      <title>Thống kê thẻ (ngày)</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <Box sx={{ pt: 3 }}>
          <ThongkethengayDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Thongkethengay;
