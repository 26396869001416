import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LookupAction } from 'src/actions/lookup.action';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import io from 'socket.io-client';

import { AppAction } from '../../actions/app.action';

import MainSocket from 'src/components/MainSocket.js'
import { LstracuuAction } from 'src/actions/lstracuu.action';

const CustomerLookupPackageHistory = (props) => {
  const dispatch = useDispatch()
  const { pkg } = useSelector(state => state.lookup)
  const { socket, socket_client_id } = MainSocket(useSelector(state => state));
  const [values, setValues] = useState({
    phone: '',
  });
  const [data, setData] = useState([]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  // const onLookup = () => {
  //   dispatch(LookupAction.lookupPackage(values.phone))
  // }

  const onLookup = () => {
    dispatch(AppAction.appLoading(true));
    var phone = (values.phone).substring((values.phone).length - 9)

    if (phone.trim() == '') {
      alert('Bạn phải nhập số điện thoại')
      dispatch(AppAction.appLoading(false));
      return;
    }

    console.log(phone)

    if (typeof values.socketSend == 'undefined' || values.socketSend == null) {
      dispatch(LstracuuAction.create({ so_dien_thoai: phone, noi_dung: `Tra cứu lịch sử gói cước:\n{\nisdn: ${phone}\n}` }));
      
      values.socketSend = "traCuuLichSuGoiCuoc";
      delete values.socketReceive;

      // socket.emit("traCuuLichSuGoiCuoc", {client_id: socket_client_id, data: {
      //   isdn: phone,
      // }})
    }
  }

  // socket.on("traKetQuaTraCuuLichSuGoiCuoc", (data) => {
  //   if (typeof values.socketReceive == 'undefined' || values.socketReceive == null) {
  //     values.socketReceive = "traKetQuaTraCuuLichSuGoiCuoc";
  //     delete values.socketSend;

  //     data = data.data;
  //     if (data && typeof data.NUMBER_PHONE !== 'undefined' && values && typeof values.phone !== 'undefined' && data.NUMBER_PHONE.slice(-9) == values.phone.slice(-9)) {
  //       console.log(data);
  //       setData(data);
  //       dispatch(AppAction.appLoading(false));
  //       // if (data.result === null) onLookup();
  //       // else
  //       // {
  //       //   setValues({
  //       //     phone: '',
  //       //   })

  //       //   dispatch(AppAction.appLoading(false));
  //       // }
  //     }
  //   }
  // })

  useEffect(() => {
    return () => {
      dispatch(LookupAction.clearLookup())
    }
  }, [])

  return (
    <form {...props}>
      <Card>
        <CardHeader
          title="#5 TRA CỨU LỊCH SỬ GÓI CƯỚC"
          subheader="Không có giới hạn"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Số điện thoại"
            margin="normal"
            onChange={handleChange}
            required
            name='phone'
            value={values.phone}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={onLookup}
          >
            Tra cứu
          </Button>
        </Box>
        {data && typeof data.NUMBER_PHONE !== 'undefined' && <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="40%">
                  Kết quả
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Thuê bao
                  </Typography>
                </TableCell>
                <TableCell>
                  {data.NUMBER_PHONE}
                </TableCell>
              </TableRow>

              {data && typeof data.result !== 'undefined' && data.result && (data.result).map((pkg) => (
                <><TableRow
                  hover
                >
                  <TableCell width="40%">
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      
                    </Typography>
                  </TableCell>
                  <TableCell>
                    
                  </TableCell>
                </TableRow><TableRow
                  hover
                >
                  <TableCell width="40%">
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      Loại thuê bao
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {pkg.NumberType}
                  </TableCell>
                </TableRow><TableRow
                  hover
                >
                    <TableCell width="40%">
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        Gói cước hiện tại
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {pkg.NumberCurrentPackage}
                    </TableCell>
                  </TableRow><TableRow
                    hover
                  >
                    <TableCell width="40%">
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        Ngày đăng ký
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {pkg.NumberPackageRegisterDate != null ? moment(pkg.NumberPackageRegisterDate, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY HH:mm:ss") : null}
                    </TableCell>
                  </TableRow><TableRow
                    hover
                  >
                    <TableCell width="40%">
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        Ngày hết hạn
                      </Typography>
                    </TableCell>
                    <TableCell style={{ alignItems: 'center' }}>
                      {pkg.NumberPackageRegisterExpire != null ? moment(pkg.NumberPackageRegisterExpire, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY HH:mm:ss") : null}
                    </TableCell>
                  </TableRow><TableRow
                    hover
                  >
                    <TableCell width="40%">
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        Chu kỳ hiện tại
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {pkg.CurrentCycle}
                    </TableCell>
                  </TableRow><TableRow
                    hover
                  >
                    <TableCell width="40%">
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        Số chu kỳ còn lại
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {pkg.NumberFreeCycleLeft}
                    </TableCell>
                  </TableRow><TableRow
                    hover
                  >
                    <TableCell width="40%">
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        Trạng thái
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {pkg.Status}
                    </TableCell>
                  </TableRow></>
              ))}
            </TableBody>
          </Table>
        </Box>}
      </Card>
    </form>
  );
};

export default CustomerLookupPackageHistory;
