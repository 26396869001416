import axios from 'axios';

import { authHeader } from './auth-header';

let headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const axiosOption = () => ({
  withCredentials: true,
  credentials: 'include',
  headers: { ...headers, ...authHeader() }
})

export default axios.create(axiosOption());
