import { CustomerService } from 'src/services/customer.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const UPLOAD_FIND_ALL_SUCCESS = 'UPLOAD:FIND_ALL_SUCCESS'
export const UPLOAD_CREATE_SUCCESS = 'UPLOAD:CREATE_SUCCESS'
export const DIVISION_DATA = 'DIVISION:DATA'
export const DIVISION_COUNT = 'UPLOAD:COUNT'

export const DivisionAction = {
  divisionData,
}


function divisionData(payload) {
  return (dispatch) => {
    dispatch(divisionData(payload))
    dispatch(AppAction.appLoading(true));
    CustomerService.countAssign(payload.id).then((rs) => {
      dispatch(success(rs.data.total));
    }).catch((error) => {
      if (error) {
        showToast(NOTI_TYPES.ERROR, error.msg)
      } else {
        showToast(NOTI_TYPES.ERROR, "Lấy số lượng khách hàng có thể chia không thành công")
      }
    }).finally(() => {
      dispatch(AppAction.appLoading(false));
    })
  };
  function success(data) { return { type: DIVISION_COUNT, data } }
  function divisionData(data) { return { type: DIVISION_DATA, data } }
}