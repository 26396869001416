import { LichsuhoatdongService } from 'src/services/lichsuhoatdong.service';
import { showToast } from 'src/utils/common';
import {
    DEFAULT_ROW_PER_PAGE,
  } from 'src/utils/constants';

import { AppAction } from './app.action';

export const LICHSUHOATDONG_UPDATE_FILTER = 'LICHSUHOATDONG:LICHSUHOATDONG_UPDATE_FILTER'

export const LICHSUHOATDONG_NEW_CARE_SUCCESS = 'LICHSUHOATDONG:NEW_CARE_SUCCESS'
export const LICHSUHOATDONG_LIST_SUCCESS = 'LICHSUHOATDONG:FIND_ALL_SUCCESS'
export const LICHSUHOATDONG_LIST_FAILD = 'LICHSUHOATDONG:FIND_ALL_FAILD'
export const LICHSUHOATDONG_COUNT_ASSIGN_SUCCESS = 'LICHSUHOATDONG:COUNT_ASSIGN_SUCCESS'
export const LICHSUHOATDONG_COUNT_ASSIGN_FAILD = 'LICHSUHOATDONG:COUNT_ASSIGN_FAILD'

export const LICHSUHOATDONG_CREATE_SUCCESS = 'LICHSUHOATDONG:CREATE_SUCCESS'
export const LICHSUHOATDONG_CREATE_FAILD = 'LICHSUHOATDONG:CREATE_FAILD'

export const LICHSUHOATDONG_IMPORT_SUCCESS = 'LICHSUHOATDONG:IMPORT_SUCCESS'
export const LICHSUHOATDONG_SAVE_SUCCESS = 'LICHSUHOATDONG:SAVE_SUCCESS'
export const LICHSUHOATDONG_SAVE_FAILD = 'LICHSUHOATDONG:SAVE_SUCCESS'

export const LICHSUHOATDONG_DEL_SUCCESS = 'LICHSUHOATDONG:DEL_SUCCESS'
export const LICHSUHOATDONG_DEL_FAILD = 'LICHSUHOATDONG:DEL_FAILD'


export const LICHSUHOATDONG_HISTORY_LIST_SUCCESS = 'LICHSUHOATDONG:HISTORY_FIND_ALL_SUCCESS'
export const LICHSUHOATDONG_HISTORY_LIST_FAILD = 'LICHSUHOATDONG:HISTORY_FIND_ALL_FAILD'

export const LichsuhoatdongAction = {
    list,
    findAssign,
    create,
    updateFilter,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    history,
    approve,
    deny,
    setTrangThaiXuLy,
    getNoiDungChuyenKhoan,
    getThongTinChuyenKhoan,
}

function getThongTinChuyenKhoan() {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            LichsuhoatdongService.getThongTinChuyenKhoan()
            .then(response => {
                // Handle the successful response
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                reject(error); // Reject the promise with the error
            });
        });
    };
}

function getNoiDungChuyenKhoan() {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            LichsuhoatdongService.getNoiDungChuyenKhoan()
            .then(response => {
                // Handle the successful response
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                reject(error); // Reject the promise with the error
            });
        });
    };
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        LichsuhoatdongService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LICHSUHOATDONG_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: LICHSUHOATDONG_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { lichsuhoatdong } = getState()
        dispatch(AppAction.appLoading(true));
        LichsuhoatdongService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                }));
            } else {
                dispatch(success({
                    items: [...lichsuhoatdong.items, ...rs.data.values],
                    total: rs.data.count,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LICHSUHOATDONG_LIST_SUCCESS, data } }
    function failure(error) { return { type: LICHSUHOATDONG_LIST_FAILD, error } }
}

function list() {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { lichsuhoatdong } = getState();
        const page = lichsuhoatdong && typeof lichsuhoatdong.filter !== 'undefined' && lichsuhoatdong.filter && typeof lichsuhoatdong.filter.page !== 'undefined' ? lichsuhoatdong.filter.page : 0;
        const limit = lichsuhoatdong && typeof lichsuhoatdong.filter !== 'undefined' && lichsuhoatdong.filter && typeof lichsuhoatdong.filter.limit !== 'undefined' ? lichsuhoatdong.filter.limit : 10;
        LichsuhoatdongService.findAll(page, limit, lichsuhoatdong.filter).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                    tongChuyenKhoan: rs.data.tongChuyenKhoan,
                    tongDonChuyen: rs.data.tongDonChuyen,
                }));
            } else {
                const oldItems = lichsuhoatdong.items;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count,
                    tongChuyenKhoan: rs.data.tongChuyenKhoan,
                    tongDonChuyen: rs.data.tongDonChuyen,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LICHSUHOATDONG_LIST_SUCCESS, data } }
    function failure(error) { return { type: LICHSUHOATDONG_LIST_FAILD, error } }
}

function updateFilter(payload) {
    return { type: LICHSUHOATDONG_UPDATE_FILTER, data: payload }
}

function importData(payload) {
    return { type: LICHSUHOATDONG_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            LichsuhoatdongService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(LichsuhoatdongAction.list())
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: LICHSUHOATDONG_SAVE_SUCCESS, data } }
    function failure(error) { return { type: LICHSUHOATDONG_SAVE_FAILD, error } }
}

function setTrangThaiXuLy(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            LichsuhoatdongService.setTrangThaiXuLy(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(LichsuhoatdongAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: LICHSUHOATDONG_CREATE_SUCCESS, data } }
    function failure(error) { return { type: LICHSUHOATDONG_CREATE_FAILD, error } }
}

function approve(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            LichsuhoatdongService.approve(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(LichsuhoatdongAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: LICHSUHOATDONG_CREATE_SUCCESS, data } }
    function failure(error) { return { type: LICHSUHOATDONG_CREATE_FAILD, error } }
}

function deny(id) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            LichsuhoatdongService.deny(id)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(LichsuhoatdongAction.list());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: LICHSUHOATDONG_CREATE_SUCCESS, data } }
    function failure(error) { return { type: LICHSUHOATDONG_CREATE_FAILD, error } }
}

function update(id, payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        LichsuhoatdongService.update(id, payload).then((rs) => {
            dispatch(success(rs));
            dispatch(LichsuhoatdongAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LICHSUHOATDONG_CREATE_SUCCESS, data } }
    function failure(error) { return { type: LICHSUHOATDONG_CREATE_FAILD, error } }
}


function create(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        LichsuhoatdongService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(LichsuhoatdongAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LICHSUHOATDONG_CREATE_SUCCESS, data } }
    function failure(error) { return { type: LICHSUHOATDONG_CREATE_FAILD, error } }
}

function del(id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        LichsuhoatdongService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(LichsuhoatdongAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LICHSUHOATDONG_DEL_SUCCESS, data } }
    function failure(error) { return { type: LICHSUHOATDONG_DEL_FAILD, error } }
}


function assign(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        LichsuhoatdongService.assign(payload).then((rs) => {
            showToast('success', "Phân chia dữ liệu thành công.")
            dispatch(LichsuhoatdongAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}


function history(payload) {
    return (dispatch, getState) => {
        const { lichsuhoatdong } = getState()
        dispatch(AppAction.appLoading(true));
        LichsuhoatdongService.history(payload).then((rs) => {
            if (payload.page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                }));
            } else {
                dispatch(success({
                    items: [...lichsuhoatdong.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: LICHSUHOATDONG_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: LICHSUHOATDONG_HISTORY_LIST_FAILD, error } }
}