import {
    useEffect,
    useState,
} from 'react';

import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { CustomerAction } from 'src/actions/customer.action';
import { CustomerCareStatus } from 'src/utils/constants';
import { LookupAction } from 'src/actions/lookup.action';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import {
    DateTimePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CustomerCareCreateDialog = (props) => {
    const dispatch = useDispatch()
    const [values, setValues] = useState({
        package: '',
        status: "",
        appointmentAt: dayjs(new Date()),
        note: ''
    });

    useEffect(() => {
        if (!props.customer) {
            props.handleClose();
        }
        setValues({
            ...values,
            status: CustomerCareStatus[0].code
        });
    }, [])



    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };


    const handleDateChange = (date) => {
        setValues({
            ...values,
            appointmentAt: date
        });
    };

    const handleCareCreate = () => {
        let payload = values;

        if (values.status.trim() == '') {
            alert('Bạn phải chọn kết quả!');
            return;
        }

        if (values.status === 'APPROVED') {
            payload.package = payload.package.trim();
            if (payload.package == '') {
                alert('Bạn chưa nhập gói cước!');
                return;
            }
        }

        if (values.status === 'APPOINTMENT') {
            if (typeof payload.appointmentAt === 'undefined' || payload.appointmentAt == null || payload.appointmentAt == '') {
                alert('Bạn chưa nhập thời gian hẹn lại!');
                return;
            }
        }

        if (values.status !== 'APPOINTMENT') {
            delete payload.appointmentAt;
        }
        dispatch(CustomerAction.care(props.customer.id, payload))
        dispatch(LookupAction.lookupBalance("", true))
        handleClose()
    }

    const handleClose = () => {
        setValues({})
        props.handleClose()
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Kết quả chăm sóc khách hàng</DialogTitle>
            <DialogContent style={{ paddingTop: 20 }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            {`Thuê bao: `}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    >
                        <Typography
                            color="textPrimary"
                            variant="body1"
                        >
                            <strong>{props.customer ? props.customer.phone : ""}</strong>
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Kết quả"
                            name="status"
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.status}
                            variant="outlined"
                        >
                            {CustomerCareStatus.map((option) => (
                                <option
                                    key={option.code}
                                    value={option.code}
                                >
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    {values.status === 'APPROVED' && <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Gói đăng ký"
                            name="package"
                            required
                            onChange={handleChange}
                            value={values.package}
                            variant="outlined"
                        />
                    </Grid>}
                    {values.status === 'APPOINTMENT' && <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
                            <DateTimePicker
                                label="Thời gian hẹn lại"
                                style={{ width: '100%' }}
                                value={values.appointmentAt}
                                onChange={(newValue) => handleDateChange(newValue)}
                            />
                        </LocalizationProvider>
                    </Grid>}
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            label="Ghi chú"
                            name="note"
                            maxRows={3}
                            required={values.status !== 'APPROVED'}
                            rows={3}
                            minRows={3}
                            onChange={handleChange}
                            value={values.note}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Huỷ</Button>
                <Button onClick={handleCareCreate}>Xác nhận</Button>
            </DialogActions>
        </Dialog >
    );
}

export default CustomerCareCreateDialog;