import {
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { CustomerAction } from 'src/actions/customer.action';
import { ProfileAction } from 'src/actions/profile.action';
import { EMPTY_ID } from 'src/utils/constants';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core';

const CustomerAssignDialog = (props) => {
    const dispatch = useDispatch()
    const { countAssign } = useSelector(state => state.customer)
    const { type, setType } = useState(undefined)
    const [showBrand, setShowBrand] = useState(false);
    const [members, setMember] = useState([])
    const [target, setTarget] = useState({})
    const { items } = useSelector(state => state.role)
    const { leaders, brands, saleman } = useSelector(state => state.profile)

    useEffect(() => {
        if (saleman && saleman.length > 0 && target.priority === 5) {
            setMember(saleman)
        }
    }, [saleman])

    useEffect(() => {
        if (brands && brands.length > 0 && target.priority) {
            if (target.priority === 3) {
                setMember(brands)
            } else if (target.priority > 3) {
                setShowBrand(true)
            }
        }
    }, [brands])

    useEffect(() => {
        if (leaders && leaders.length > 0 && target.priority === 4) {
            setMember(leaders)
        }
    }, [leaders])

    const [values, setValues] = useState({
        brand: "",
        target: ""
    });

    const [assigns, setAssigns] = useState({});


    const handleChangeAssign = (event) => {
        setAssigns({
            ...assigns,
            [event.target.name]: event.target.value
        })
        console.log("assigns:", assigns)
    }

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });

        if (event.target.name === 'target') {
            let _selected = items.find(r => r.id === event.target.value);
            if (_selected) {
                setTarget(_selected)
                // Leader
                setMember([])
                setAssigns([])
                dispatch(ProfileAction.listBrand())
                if (_selected.priority === 3) {
                    setShowBrand(false)
                }
                if (_selected.priority === 4) {
                    if (values.brand !== EMPTY_ID) {
                        dispatch(ProfileAction.listLeader(values.brand))
                    }
                }
                if (_selected.priority === 5) {
                    if (values.brand !== EMPTY_ID) {
                        dispatch(ProfileAction.listSaleman({ brand: values.brand }))
                    }
                }
            }
        }

        if (event.target.name === 'brand') {
            if (target.priority === 4) {
                if (event.target.value !== EMPTY_ID) {
                    dispatch(ProfileAction.listLeader(event.target.value))
                }
            }
            if (target.priority === 5) {
                if (event.target.value !== EMPTY_ID) {
                    dispatch(ProfileAction.listSaleman({ brand: event.target.value }))
                }
            }
        }
    };

    const handleEquallyDivided = () => {
        let divided = {};
        for (let i = 0; i < members.length; i++) {
            divided[members[i].id] = Math.floor(countAssign / members.length);
        }
        setValues(divided)
    }

    const handleAssign = () => {
        let dividedAssign = Object.keys(assigns).map((key) => ({
            user: key,
            count: assigns[key]
        }));
        dispatch(CustomerAction.assign(dividedAssign))
        props.handleClose()
    }

    const handleClose = () => {
        setValues({})
        props.handleClose()
    }

    const renderMemeber = (member) => {
        return (<ListItem key={member.id}>
            <Typography
                style={{ flex: 1 }}
                color="textPrimary"
                variant="body1"
            >
                {member.fullname}
            </Typography>
            <TextField
                label="Số lượng"
                name={member.id}
                onChange={handleChangeAssign}
                type="number"
                defaultValue={0}
                value={assigns[member.id]}
                variant="outlined"
            />
        </ListItem>)
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} fullWidth={true}>
            <DialogTitle>Phân chia khách hàng</DialogTitle>
            <DialogContent style={{ paddingTop: 20 }}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                >
                    {`Tổng số khách hàng cần phân chia là: `}
                    <strong>{countAssign}</strong>
                </Typography>

                <List>
                    <ListItem key={'target'}>
                        <TextField
                            fullWidth
                            label="Đối tượng chia khách hàng"
                            name="target"
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.target}
                            variant="outlined"
                        >
                            {[{ id: EMPTY_ID, name: "Chọn đối tượng" }, ...items.filter((r) => r.priority >= 3)].map((option) => (
                                <option
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </ListItem>
                    {showBrand && <ListItem>
                        <TextField
                            fullWidth
                            label="Chi nhánh"
                            name="brand"
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.brand}
                            variant="outlined"
                        >
                            {[{ id: "00000000-0000-0000-0000-000000000000", fullname: "<<Chọn chi nhánh>>" }, ...brands].map((option) => (
                                <option
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.fullname}
                                </option>
                            ))}
                        </TextField>
                    </ListItem>}
                    {members.length > 0 && members.map((m) => renderMemeber(m))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Huỷ</Button>
                {members && members.length > 0 && <Button onClick={handleEquallyDivided}>Chia đều</Button>}
                {members && members.length > 0 && <Button onClick={handleAssign}>Xác nhận</Button>}
            </DialogActions>
        </Dialog >
    );
}

export default CustomerAssignDialog;
