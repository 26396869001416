import { Helmet } from 'react-helmet';
import GiahoantienDetail
  from 'src/components/giahoantien-detail/GiahoantienDetail';

import {
  Box,
  Container,
} from '@material-ui/core';

import GiahoantienListToolbar from '../components/giahoantien-detail/GiahoantienToolbar';

const Giahoantien = () => (
  <>
    <Helmet>
      <title>Giá hoàn tiền</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <GiahoantienListToolbar />
        <Box sx={{ pt: 3 }}>
          <GiahoantienDetail />
        </Box>
      </Container>
    </Box>
  </>
);

export default Giahoantien;
