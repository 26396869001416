import { CustomerService } from 'src/services/customer.service';
import { showToast } from 'src/utils/common';
import { NOTI_TYPES } from 'src/utils/constants';

import { AppAction } from './app.action';

export const CUSTOMER_NEW_CARE_SUCCESS = 'CUSTOMER:NEW_CARE_SUCCESS'
export const CUSTOMER_LIST_SUCCESS = 'CUSTOMER:FIND_ALL_SUCCESS'
export const CUSTOMER_LIST_FAILD = 'CUSTOMER:FIND_ALL_FAILD'
export const CUSTOMER_COUNT_ASSIGN_SUCCESS = 'CUSTOMER:COUNT_ASSIGN_SUCCESS'
export const CUSTOMER_COUNT_ASSIGN_FAILD = 'CUSTOMER:COUNT_ASSIGN_FAILD'

export const CUSTOMER_CREATE_SUCCESS = 'CUSTOMER:CREATE_SUCCESS'
export const CUSTOMER_CREATE_FAILD = 'CUSTOMER:CREATE_FAILD'

export const CUSTOMER_IMPORT_SUCCESS = 'CUSTOMER:IMPORT_SUCCESS'
export const CUSTOMER_SAVE_SUCCESS = 'CUSTOMER:SAVE_SUCCESS'
export const CUSTOMER_SAVE_FAILD = 'CUSTOMER:SAVE_SUCCESS'

export const CUSTOMER_DEL_SUCCESS = 'CUSTOMER:DEL_SUCCESS'
export const CUSTOMER_DEL_FAILD = 'CUSTOMER:DEL_FAILD'

export const CUSTOMER_CARE_SUCCESS = 'CUSTOMER:CARE_SUCCESS'

export const CUSTOMER_UPDATE_FILTER = 'CUSTOMER:CUSTOMER_UPDATE_FILTER'

export const CUSTOMER_HISTORY_LIST_SUCCESS = 'CUSTOMER:HISTORY_FIND_ALL_SUCCESS'
export const CUSTOMER_HISTORY_LIST_FAILD = 'CUSTOMER:HISTORY_FIND_ALL_FAILD'

export const CustomerAction = {
    list,
    findAssign,
    create,
    importData,
    saveData,
    update,
    del,
    assign,
    countAssign,
    care,
    history,
    newCare,
    updateFilter,
    setStatus,
    setAppointmentAt,
}

function updateFilter(payload) {
    return { type: CUSTOMER_UPDATE_FILTER, data: payload }
}

function setStatus(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            CustomerService.setStatus(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(CustomerAction.history());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: CUSTOMER_CREATE_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMER_CREATE_FAILD, error } }
}

function setAppointmentAt(id, payload) {    
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // Perform your asynchronous operation (e.g., API call)
            CustomerService.setAppointmentAt(id, payload)
            .then(response => {
                // Handle the successful response
                dispatch(success(response));
                dispatch(CustomerAction.history());
                resolve(response); // Resolve the promise with the response
            })
            .catch(error => {
                // Handle the error
                dispatch(failure(error));
                reject(error); // Reject the promise with the error
            });
        });
    };
    function success(data) { return { type: CUSTOMER_CREATE_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMER_CREATE_FAILD, error } }
}

function countAssign() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        CustomerService.countAssign().then((rs) => {
            dispatch(success(rs.data.total));
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMER_COUNT_ASSIGN_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMER_COUNT_ASSIGN_FAILD, error } }
}

function findAssign(page = 0, count = 20) {
    return (dispatch, getState) => {
        const { customer } = getState()
        dispatch(AppAction.appLoading(true));
        CustomerService.findAssign(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...customer.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMER_LIST_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMER_LIST_FAILD, error } }
}

function list(page = 0, count = 20,) {
    return (dispatch, getState) => {
        const { customer } = getState()
        dispatch(AppAction.appLoading(true));
        CustomerService.findAll(page, count).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count
                }));
            } else {
                dispatch(success({
                    items: [...customer.items, ...rs.data.values],
                    total: rs.data.count
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMER_LIST_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMER_LIST_FAILD, error } }
}

function importData(payload) {
    return { type: CUSTOMER_IMPORT_SUCCESS, data: payload }
}

function saveData(payload) {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        let count = 0
        for (let i = 0; i < payload.length; i += 200) {
            // eslint-disable-next-line no-loop-func
            CustomerService.save(payload.slice(i, i + 200)).then((rs) => {
                count += parseInt(rs.data.saved, 10)
                if (count === payload.length) {
                    dispatch(success(rs));
                    dispatch(AppAction.appLoading(false));
                }
            }).catch((error) => {
                dispatch(failure(error));
                dispatch(AppAction.appLoading(false));
            })
        }
    };

    function success(data) { return { type: CUSTOMER_SAVE_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMER_SAVE_FAILD, error } }
}

function update(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        CustomerService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(CustomerAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMER_CREATE_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMER_CREATE_FAILD, error } }
}


function create(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        CustomerService.create(payload).then((rs) => {
            dispatch(success(rs));
            dispatch(CustomerAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMER_CREATE_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMER_CREATE_FAILD, error } }
}

function del(id) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        CustomerService.delete(id).then((rs) => {
            dispatch(success(rs));
            dispatch(CustomerAction.list())
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMER_DEL_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMER_DEL_FAILD, error } }
}


function assign(payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        CustomerService.assign(payload).then((rs) => {
            showToast('success', "Phân chia thông tin khách hàng thành công.")
            dispatch(CustomerAction.list())
        }).catch((error) => {
            showToast('error', "Phân chia dữ liệu thất bại.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
}

function care(id, payload) {
    return dispatch => {
        dispatch(AppAction.appLoading(true));
        CustomerService.care(id, payload).then((rs) => {
            showToast('success', "Thông tin chăm sóc tin khách hàng ghi nhận thành công.")
            dispatch(success())
            dispatch(CustomerAction.newCare())
        }).catch((error) => {
            showToast('error', "Thông tin chăm sóc tin khách hàng ghi nhận không thành công.\nVui lòng thử lại lần sau.")
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };

    function success() { return { type: CUSTOMER_CARE_SUCCESS } }
}

function history() {
    return (dispatch, getState) => {
        dispatch(AppAction.appLoading(true));
        const { customer } = getState();
        const page = customer && typeof customer.filter !== 'undefined' && customer.filter && typeof customer.filter.page !== 'undefined' ? customer.filter.page : 0;
        const limit = customer && typeof customer.filter !== 'undefined' && customer.filter && typeof customer.filter.limit !== 'undefined' ? customer.filter.limit : 10;
        CustomerService.history(page, limit, customer.filter).then((rs) => {
            if (page === 0) {
                dispatch(success({
                    items: rs.data.values,
                    total: rs.data.count,
                }));
            } else {
                //CHÚ Ý oldItems
                const oldItems = customer.history;
                const rsDataValues = rs.data.values;

                var uniqueItems = oldItems;
                for (var i = limit * page; i < limit * page + limit; i++) {
                    if (typeof rsDataValues[i - limit * page] !== 'undefined') {
                        uniqueItems[i] = rsDataValues[i - limit * page];
                    } else if (typeof uniqueItems[i] !== 'undefined') {
                        delete uniqueItems[i];
                    }
                }
                
                dispatch(success({
                    items: uniqueItems,
                    total: rs.data.count,
                }));
            }
        }).catch((error) => {
            dispatch(failure(error));
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMER_HISTORY_LIST_SUCCESS, data } }
    function failure(error) { return { type: CUSTOMER_HISTORY_LIST_FAILD, error } }
}

function newCare() {
    return (dispatch) => {
        dispatch(AppAction.appLoading(true));
        CustomerService.newCare().then((rs) => {
            dispatch(success({
                newCare: rs.data.value,
                canCare: rs.data.count
            }));
        }).catch((error) => {
            if (error) {
                showToast(NOTI_TYPES.ERROR, error.msg)
            } else {
                showToast(NOTI_TYPES.ERROR, "Lấy khách hàng mới không thành công.\nVui lòng thử lại")
            }
        }).finally(() => {
            dispatch(AppAction.appLoading(false));
        })
    };
    function success(data) { return { type: CUSTOMER_NEW_CARE_SUCCESS, data } }
}