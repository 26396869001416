import {
  LOOKUP_BALANCE_FAILD,
  LOOKUP_BALANCE_SUCCESS,
  LOOKUP_INFO_FAILD,
  LOOKUP_INFO_SUCCESS,
  LOOKUP_PACKAGE_FAILD,
  LOOKUP_PACKAGE_SUCCESS,
} from 'src/actions/lookup.action';

const initState = {
  pkg: undefined,
  info: undefined,
  balance: undefined,
}

const lookupReducer = (state = initState, action) => {
  switch (action.type) {
    case LOOKUP_PACKAGE_SUCCESS: {
      return {
        ...state,
        pkg: action.data,
      }
    }
    case LOOKUP_PACKAGE_FAILD: {
      return {
        ...state,
        pkg: undefined,
      }
    }
    case LOOKUP_INFO_SUCCESS: {
      return {
        ...state,
        info: action.data,
      }
    }
    case LOOKUP_INFO_FAILD: {
      return {
        ...state,
        info: undefined,
      }
    }
    case LOOKUP_BALANCE_SUCCESS: {
      return {
        ...state,
        balance: action.data,
      }
    }
    case LOOKUP_BALANCE_FAILD: {
      return {
        ...state,
        balance: undefined,
      }
    }
    default: {
      return state;
    }
  }
}
export default lookupReducer