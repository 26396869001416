import axios from 'axios';
import '../App.css';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import React, { useRef, useState, useEffect } from 'react';
import { axiosOption } from '../utils/axios';

const API_BASE_URL = 'https://nap-the.dvmobifone.com/api/v1/imedia';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  (config) => {
    // Merge the default config with axiosOption
    config.headers = {
      ...config.headers,
      ...axiosOption().headers
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle responses or errors
api.interceptors.response.use(
  (response) => {
    // Handle successful response
    return response;
  },
  (error) => {
    // Handle error response
    return Promise.reject(error);
  }
);

export const getProductsTopUp = async (provider) => {
  try {
    const response = await api.get(`/productstopup`, { params: { provider } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProductsDownload = async (provider) => {
  try {
    const response = await api.get(`/productsdownload`, {
      params: { provider }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProviders = async () => {
  try {
    const response = await api.get(`/providers`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const topup = async (payload) => {
  try {
    const response = await api.post(`/topup`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const queryTopupResult = async (requestID) => {
  try {
    const response = await api.get(`/topup/${requestID}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadCard = async (payload) => {
  try {
    const response = await api.post(`/download`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const redownloadResponse = async (requestID) => {
  try {
    const response = await api.get(`/download/${requestID}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getHistory = async () => {
  try {
    const response = await api.get(`/history`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const queryMsisdn = async (msisdn) => {
  try {
    const response = await api.get(`/queryMsisdn`, { params: { msisdn } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReport = async (startDate, endDate, requestID) => {
  try {
    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append('startDate', startDate);
    if (endDate) queryParams.append('endDate', endDate);
    if (requestID) queryParams.append('requestID', requestID);

    const response = await api.get(`/report?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getUser = async () => {
  try {
    const response = await api.get(`/user`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const formatAmount = (amount) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
  }).format(amount);
};

const Modal = ({ children, action, showButtOpen = true }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  useEffect(() => {
    setIsOpen(!showButtOpen);
  }, [showButtOpen]);

  return (
    <>
      {showButtOpen && (
        <button className="open-modal-btn" onClick={toggleModal}>
          {!isOpen && 'Mua'}
        </button>
      )}

      {isOpen && (
        <div className="modal-overlay" onClick={handleOutsideClick}>
          <div className="modal-content">
            <div>{children}</div>
            {action && (
              <button
                onClick={() => {
                  new Promise(async (done, handErr) => {
                    try {
                      done(await action());
                    } catch (error) {
                      handErr(error);
                    }
                  }).then(() => closeModal());
                }}
              >
                Xác nhận
              </button>
            )}

            <button className="close-modal-btn" onClick={closeModal}>
              Hủy
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const SmoothDraggableCSSHorizontalSlider = ({
  data,
  selectProvider,
  handleSelectItem
}) => {
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    let animationFrameId;

    const handleMouseDown = (e) => {
      setIsDragging(true);
      setStartX(e.pageX - slider.offsetLeft);
      setScrollLeft(slider.scrollLeft);
      cancelAnimationFrame(animationFrameId);
    };

    const handleMouseLeave = () => {
      setIsDragging(false);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2;

      animationFrameId = requestAnimationFrame(() => {
        slider.scrollLeft = scrollLeft - walk;
      });
    };

    slider.addEventListener('mousedown', handleMouseDown);
    slider.addEventListener('mouseleave', handleMouseLeave);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      slider.removeEventListener('mousedown', handleMouseDown);
      slider.removeEventListener('mouseleave', handleMouseLeave);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
      cancelAnimationFrame(animationFrameId);
    };
  }, [isDragging, startX, scrollLeft]);

  return (
    <div
      className="slider-container"
      style={{
        width: '100%',
        overflow: 'hidden',
        cursor: isDragging ? 'grabbing' : 'grab',
        margin: '18px'
      }}
    >
      <div
        ref={sliderRef}
        className="slider"
        style={{
          display: 'flex',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          WebkitOverflowScrolling: 'touch',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          userSelect: 'none'
        }}
      >
        {data.map((item, index) => (
          <div
            onClick={() => handleSelectItem(item)}
            key={index}
            className={`slider-item ${
              selectProvider &&
              selectProvider.providerId === item.providerId &&
              'providerActive'
            }`}
            style={{
              flex: '0 0 auto',
              width: '200px',
              height: '100px',
              margin: '0 10px',
              backgroundColor: '#f0f0f0',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <div>{item.providerName}</div>
              <div>ID: {item.providerId}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Product = ({ product, handleSelectBuyItem,removeQualiti }) => {

  const [value, setValue] = useState(0);
  const handleChange = (e) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    setValue(0)
  },[removeQualiti])


  return (
    <div key={product.productId} className="product-card">
      <div className="product-name">{product.productName}</div>
      <div className="product-id">ID: {product.productId}</div>
      <div className="product-status">Status: {product.productStatus}</div>
      <div className="product-amount">
        {formatAmount(product.productAmount)}
      </div>
      <div className="change">
        <button
          onClick={() => {
            if (!value) return;
            let num = value - 1;
            setValue(num);
            handleSelectBuyItem({
              productId: product.productId,
              quantity: num
            });
          }}
        >
          -
        </button>
        <input style={{width : "80px"}} type="number" onChange={handleChange} value={value} />
        <button
          onClick={() => {
            let num = value + 1;
            setValue(num);
            handleSelectBuyItem({
              productId: product.productId,
              quantity: num
            });
          }}
        >
          +
        </button>
      </div>
    </div>
  );
};

const ProductGrid = ({ products, handleSelectBuyItem,removeQualiti }) => {
  return (
    <div className="product-grid">
      {products.map((product) => (
        <Product
          key={product.productId}
          product={product}
          handleSelectBuyItem={handleSelectBuyItem}
          removeQualiti={removeQualiti}
        />
      ))}
      <style jsx>{`
        .product-grid {
          display: grid;
          gap: 20px;
          padding: 20px;
        }
        .product-card {
          background-color: #f0f0f0;
          border-radius: 8px;
          padding: 15px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .product-name {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 10px;
        }
        .product-id,
        .product-status {
          margin-bottom: 5px;
        }
        .product-amount {
          color: #007bff;
          font-weight: bold;
        }
        @media (min-width: 1024px) {
          .product-grid {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        @media (min-width: 768px) and (max-width: 1023px) {
          .product-grid {
            grid-template-columns: repeat(2, 1fr);
          }
        }
        @media (max-width: 767px) {
          .product-grid {
            grid-template-columns: 1fr;
          }
        }
      `}</style>
    </div>
  );
};

const ProductList = ({ data }) => {
  return (
    <div>
      <h2>Loại thẻ và số lượng</h2>
      <ul
        style={{
          listStyle: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        {data.map((item, index) => (
          <li
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              textAlign: 'start'
            }}
            key={index}
          >
            <strong style={{ flex: 1 }}>{item.productName}</strong>
            <strong style={{ flex: 1 }}>:</strong>
            <strong style={{ flex: 1 }}> {item.quantity}</strong>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Muathe = ({fetcUser}) => {
  const [providers, setProvider] = useState([]);
  const [selectProvider, setSelectProvider] = useState(null);
  const [products, setProducts] = useState([]);
  const [buyItem, setBuyItem] = useState([]);
  const [removeQualiti,setRemoveQualiti] = useState(false)

  const [tran, setTran] = useState(null);

  const handleSelectItem = (item) => {
    setSelectProvider(item);
  };

  useEffect(() => {
    getProviders().then((data) => {
      setProvider(data);
      setSelectProvider(data[0]);
      setTran(null);
    });
  }, []);

  useEffect(() => {
    if (!selectProvider) return;
    getProductsDownload(selectProvider.providerCode).then((data) =>
      setProducts(data)
    );

    setBuyItem([]);
    setTran(null);
  }, [selectProvider]);

  const handleSelectBuyItem = (value) => {
    if (buyItem.find((item) => item.productId === value.productId)) {
      if (!value.quantity) {
        return setBuyItem(
          buyItem.filter((item) => item.productId !== value.productId)
        );
      }

      return setBuyItem(
        buyItem.map((item) =>
          item.productId === value.productId ? value : item
        )
      );
    }
    return setBuyItem((prev) => [...prev, value]);
  };

  const providersKey = {
    Mobifone : "MOBI",
    Vinaphone : "VINA",
    Viettel : "VIETTEL"
  }

  const TransactionDetails = ({ tran }) => {
    const exportToXlsx = () => {
      let output = [];
      tran.products.map((item, index) => {
        item.softpins.map((i, jndex) => {
          output.push([i.code,i.serial,providersKey[selectProvider.providerCode], item.productValue]);
        });
      });

      const data = [
        [`Mã thẻ`, `Số seri`, 'Nhà mạng', "Mệnh giá"],
        ...output
      ];

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);

      XLSX.utils.book_append_sheet(wb, ws, 'Transaction Data');

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const file = new Blob([excelBuffer], {
        type: 'application/octet-stream'
      });
      saveAs(file, `transaction_${tran.requestID}.xlsx`);
    };
    const styles = {
      container: {
        backgroundColor: '#f9fafc',
        padding: '20px',
        borderRadius: '10px',
        color: '#333',
        fontFamily: 'Roboto, sans-serif',
        lineHeight: '1.6',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '800px',
        margin: '0 auto'
      },
      header: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '10px',
        color: '#4a4a4a'
      },
      sectionTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '20px',
        color: '#333'
      },
      product: {
        padding: '10px 0',
        borderBottom: '1px solid #ddd',
        maxHeight : "400px",
        overflow : "scroll"
      },
      softpinItem: {
        marginTop: '5px'
      },
      softpinInfo: {
        fontSize: '14px',
        color: '#555'
      }
    };

    return (
      <div>
        <div style={styles.container}>
        <button onClick={exportToXlsx}>Xuất ra Excel</button>
          <div style={styles.header}>
            Mã đối xoát - Mã giao dịch: {tran.requestID}
          </div>
          <div style={styles.header}>
            Trạng thái giao dịch: {tran.errorMessage}
          </div>
          <div style={styles.sectionTitle}>Danh sách sản phẩm</div>

          {tran.products.map((item, index) => (
            <div key={index} style={styles.product}>
              <div style={styles.header}>
                Tên sản phẩm: {item.product.productName}
              </div>
              {item.softpins.map((i, jndex) => (
                <div key={jndex} style={styles.softpinItem}>
                  <div style={styles.softpinInfo}>Serial: {i.serial}</div>
                  <div style={styles.softpinInfo}>Mã thẻ: {i.code}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleAction = async () => {
    try {
      const data = await downloadCard({
        buyItems: buyItem,
        amount: buyItem
          .map((item) => {
            let productAmount = products.find(
              (i) => i.productId === item.productId
            )?.productAmount;

            return productAmount * item.quantity;
          })
          .reduce((prev, next) => prev + next, 0)
      });
      setBuyItem([]);
      setRemoveQualiti(!removeQualiti)
      fetcUser()

      let output = {
        ...data,
        products: data.products.map((item) => ({
          ...item,
          product: products.find((i) => i.productId === item.productId)
        }))
      };

      return setTran(output);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div>
      <SmoothDraggableCSSHorizontalSlider
        handleSelectItem={handleSelectItem}
        selectProvider={selectProvider}
        data={providers}
      />
      <ProductGrid
        products={products}
        handleSelectBuyItem={handleSelectBuyItem}
        removeQualiti={removeQualiti}
      />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Modal action={handleAction}>
          <div>
            <div>
              <ProductList
                data={buyItem.map((item) => ({
                  ...products.find((i) => i.productId === item.productId),
                  quantity: item.quantity
                }))}
              />
            </div>
            <div style={{ textAlign: 'end' }}>
              <strong>Tổng : </strong>
              <span style={{ color: 'red' }}>
                {formatAmount(
                  buyItem
                    .map((item) => {
                      let productAmount = products.find(
                        (i) => i.productId === item.productId
                      )?.productAmount;

                      return productAmount * item.quantity;
                    })
                    .reduce((prev, next) => prev + next, 0)
                )}
              </span>
            </div>
          </div>
        </Modal>
      </div>

      {tran && (
        <Modal showButtOpen={false}>
          <TransactionDetails tran={tran} />
        </Modal>
      )}
    </div>
  );
};

const Naptopup = ({ phoneType }) => {
  return <>nap topup {phoneType}</>;
};

const History = () => {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const data = await getHistory();
        setHistoryData(data);
      } catch (err) {
        setError('Failed to fetch history data');
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const exportToXlsx = (tran) => {
    let output = [];
    tran.products.map((item, index) => {
      item.softpins.map((i, jndex) => {
        output.push([i.code, i.serial,item.productId,item.productValue]);
      });
    });

    const data = [
      [`Mã thẻ`, `Số seri`, 'Nhà mạng', "Mệnh giá"],
      ...output
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, 'Transaction Data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], {
      type: 'application/octet-stream'
    });
    saveAs(file, `transaction_${tran.requestID}.xlsx`);
  };

  return (
    <div>
      <h2>Lịch sử giao dịch</h2>
      <ul>
        {historyData.map((item, index) => {
          const date = new Date(item.timestamp);
          const formattedDate = date.toLocaleString('vi-VN');
          
          return (
            <li key={index} style={{padding : "10px", border : "1px solid #ccc"}}>
              {item.responseData.requestID} - {item.responseData.errorMessage} - {formattedDate}
              {(item.responseData.errorMessage === "success" && item.type === "download") && 
                <button onClick={() => exportToXlsx(item.responseData)}>Xuất mã thẻ</button>
              }
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Report = () => {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [requestID, setRequestID] = useState('');

  const fetchReport = async () => {
    setLoading(true);
    try {
      const data = await getReport(startDate, endDate, requestID);
      setReportData(data);
    } catch (err) {
      setError('Failed to fetch report data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReport(); 
  }, []); 

  const handleSearch = () => {
    fetchReport(); 
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;


  const exportToXlsx = (cards) => {
    let output = [];
    cards.map((item, index) => {
        output.push([item.requestID,item.code, item.serial,item.productId,item.productValue,item.timestamp])
    });

    const data = [
      ["ID đơn",`Mã thẻ`, `Số seri`, 'ProductId Imedia', "Mệnh giá", "thời gian tạo"],
      ...output
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, 'Transaction Data');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], {
      type: 'application/octet-stream'
    });
    saveAs(file, `cards-${startDate}-${endDate}-${requestID}.xlsx`);
  };

  return (
    <div>
      <h2>Báo cáo</h2>

      <div>
        <label>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
        <label>
          Request ID:
          <input
            type="text"
            value={requestID}
            onChange={(e) => setRequestID(e.target.value)}
          />
        </label>
        <button onClick={handleSearch}>Search</button>
      </div>

      {
        reportData?.overallTotals && (
          <div>
            <h3>Số lượng thẻ : {reportData?.overallTotals?.totalQuantity}</h3>
            <h3>Tổng tiền: {formatAmount(reportData?.overallTotals?.totalProductValue)}</h3>
          </div>
        )
      }
      <ul>
        {reportData?.productValueDetails?.map((item, index) => (
          <li key={index} style={{ padding: '10px', border: '1px solid #ccc' }}>
            {formatAmount(item?.productValue)} - {formatAmount(item?.totalProductValue)} - {item?.totalQuantity}
          </li>
        ))}
      </ul>

      <button onClick={() => exportToXlsx(reportData?.cards || [])}>Xuất file</button>
    </div>
  );
};


const Detail = () => {
  const [select,setSelect] = useState("history")

  const component = {
    history : History,
    report : Report
  } 

  const Component = component[select]
  return <div>
    <div style={{display : "flex",gap : 10}}>
      <button onClick={() => setSelect("history")} style={{flex : 1,cursor : "pointer"}}>Lịch sử</button>
      <button onClick={() => setSelect("report")} style={{flex : 1, cursor : "pointer"}}>Báo cáo</button>
    </div>
    <div>
      <h1>{select.toUpperCase()}</h1>
    </div>
    <div className="container">
        <Component />
    </div>
  </div>
}

const NaptheImedia = () => {
  const [selectTag, setSelectTag] = useState('muathe');
  const [user,setUser] = useState()
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const fetcUser = async () => {
    try {
      const data = await getUser();
      setUser(data);
    } catch (err) {
      console.log(err);
    } finally {
    }
  };




  useEffect(() => {
    fetcUser()
  },[])

  return (
    <div className="container_topup" style={{ position: "relative" }}>
    <div style={{display: "flex",justifyContent : "space-around"}}>
      <div>{user?.username}</div>
      <div>
        <div>Tài khoản hệ thống  : {formatAmount(user?.balance)}</div>
        <div>Tài khoản đối tác  : {
        formatAmount(user?.value) }</div>
      </div>
    </div>
    <div style={{ background : "#fff",padding : "20px",position: "absolute", top: "0", right: "0%", display: "flex", alignItems: "center" ,  height: "100vh",transform : "translate(0,0)",zIndex : 999}}>
      <button onClick={handleOpen} style={{ fontSize: "50px" }}>
        {open ? ">" : "<"}
      </button>

      <div
        style={{
          width: open ? "600px" : "0",
          transition: "width 0.3s ease",
          border: open ? "1px solid #ccc" : "none",
          overflow: "scroll",
          height: "100%",
        }}
      >
        <Detail />
      </div>
    </div>
    <div className="top">
      <button
        className={selectTag === "muathe" ? "activeTag" : ""}
        onClick={() => setSelectTag("muathe")}
      >
        Mua thẻ
      </button>
      <button
        className={selectTag === "tratruoc" ? "activeTag" : ""}
        onClick={() => setSelectTag("tratruoc")}
      >
        Trả trước
      </button>
      <button
        className={selectTag === "trasau" ? "activeTag" : ""}
        onClick={() => setSelectTag("trasau")}
      >
        Trả sau
      </button>
    </div>
    {selectTag === "muathe" ? (
      <Muathe fetcUser={fetcUser} />
    ) : (
      <Naptopup phoneType={selectTag === "trasau" ? "1" : "0"} />
    )}
  </div>
  );
};

export default NaptheImedia;
