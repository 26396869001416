import {
  APP_HIDE_CONFIRM,
  APP_LOADDING,
  APP_SHOW_CONFIRM,
} from '../actions/app.action';

const initState = {
  isLoading: false,
  showConfirm: false,
  confirm: {}
}
const appReducer = (state = initState, action) => {
  switch (action.type) {
    case APP_LOADDING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case APP_SHOW_CONFIRM: {
      return {
        ...state,
        showConfirm: true,
        confirm: action.data
      }
    }
    case APP_HIDE_CONFIRM: {
      return {
        ...state,
        showConfirm: false,
        confirm: {}
      }
    }
    default: {
      return state;
    }
  }
}
export default appReducer;