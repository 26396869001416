import {
    useEffect,
    useState,
} from 'react';

import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { PermissionAction } from 'src/actions/permission.acction';
import { RequestrefundAction } from 'src/actions/requestrefund.action';
import { SettingAction } from 'src/actions/setting.action';
import { AccountAction } from 'src/actions/account.acction';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField,
    Typography,
    Grid,
    Select,
    MenuItem,
    InputLabel,
    TableCell,
} from '@material-ui/core';

const CreateEditDialogSelectChannel = (props) => {
    const dispatch = useDispatch();
    const [boiMobifone, chonBoiMobifoneValue] = useState({});
    const [boiVinaphone, chonBoiVinaphoneValue] = useState({});
    const [boiViettel, chonBoiViettelValue] = useState({});
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const { items } = useSelector(state => state.permission)
    const { selected } = useSelector(state => state.requestrefund)
    const [kenh_hoan_tien, setKenhHoanTien] = useState("")
    const [kenh_hoan_tien_tra_sau, setKenhHoanTienTraSau] = useState("")
    const [kenh_nap_chiet_khau, setKenhNapChietKhau] = useState("")
    const [kenh_nap_chiet_khau_tra_sau, setKenhNapChietKhauTraSau] = useState("")
    const [kenh_hoan_tien_vina, setKenhHoanTienVina] = useState("")
    const [kenh_hoan_tien_tra_sau_vina, setKenhHoanTienTraSauVina] = useState("")
    const [kenh_nap_chiet_khau_vina, setKenhNapChietKhauVina] = useState("")
    const [kenh_nap_chiet_khau_tra_sau_vina, setKenhNapChietKhauTraSauVina] = useState("")
    const [ti_le_chiet_khau, setTiLeChietKhau] = useState("")
    const [ti_le_chiet_khau_tra_sau, setTiLeChietKhauTraSau] = useState("")
    const [moduleBanTien, batTatModuleBanTienValue] = useState({});
    const [operator, setOperator] = useState("MOBIFONE")

    useEffect(() => {
        async function fetchSettingModuleBanTien() {
            const moduleBanTien_res = await dispatch(SettingAction.findByKey('bat_tat_module_ban_tien'))
            let moduleBanTien_value = JSON.parse(moduleBanTien_res.data.value);
            moduleBanTien_value = {
                topup: moduleBanTien_value.topup == '1' ? 1 : 2,
                tkc: moduleBanTien_value.tkc == '1' ? 1 : 2, 
                dv: moduleBanTien_value.dv == '1' ? 1 : 2,
                bc: moduleBanTien_value.bc == '1' ? 1 : 2,
                napho: moduleBanTien_value.napho == '1' ? 1 : 2,
                mymobifone: moduleBanTien_value.mymobifone == '1' ? 1 : 2,
                gsmmobifone: moduleBanTien_value.gsmmobifone == '1' ? 1 : 2,

                topup_vina: moduleBanTien_value.topup_vina == '1' ? 1 : 2,
                tkc_vina: moduleBanTien_value.tkc_vina == '1' ? 1 : 2, 
                dv_vina: moduleBanTien_value.dv_vina == '1' ? 1 : 2,
                bc_vina: moduleBanTien_value.bc_vina == '1' ? 1 : 2,

                topup_viettel: moduleBanTien_value.topup_viettel == '1' ? 1 : 2,
                tkc_viettel: moduleBanTien_value.tkc_viettel == '1' ? 1 : 2, 
                dv_viettel: moduleBanTien_value.dv_viettel == '1' ? 1 : 2,
                bc_viettel: moduleBanTien_value.bc_viettel == '1' ? 1 : 2,
            }
            batTatModuleBanTienValue(moduleBanTien_value)
        }
        fetchSettingModuleBanTien();
    }, [])

    const batTatModuleBanTien = (e, value) => {
        batTatModuleBanTienValue({
            ...moduleBanTien,
            [value]: e.target.value == 1 ? 2 : 1
        })
    }

    useEffect(() => {
      async function fetchData() {
        const kenh_hoan_tien_res = await dispatch(SettingAction.findByKey('kenh_hoan_tien'))
        setKenhHoanTien(kenh_hoan_tien_res.data.value)

        const kenh_hoan_tien_tra_sau_res = await dispatch(SettingAction.findByKey('kenh_hoan_tien_tra_sau'))
        setKenhHoanTienTraSau(kenh_hoan_tien_tra_sau_res.data.value)

        const kenh_nap_chiet_khau_res = await dispatch(SettingAction.findByKey('kenh_nap_chiet_khau'))
        setKenhNapChietKhau(kenh_nap_chiet_khau_res.data.value)

        const kenh_nap_chiet_khau_tra_sau_res = await dispatch(SettingAction.findByKey('kenh_nap_chiet_khau_tra_sau'))
        setKenhNapChietKhauTraSau(kenh_nap_chiet_khau_tra_sau_res.data.value)

        //==========

        const kenh_hoan_tien_vina_res = await dispatch(SettingAction.findByKey('kenh_hoan_tien_vina'))
        setKenhHoanTienVina(kenh_hoan_tien_vina_res.data.value)

        const kenh_hoan_tien_tra_sau_vina_res = await dispatch(SettingAction.findByKey('kenh_hoan_tien_tra_sau_vina'))
        setKenhHoanTienTraSauVina(kenh_hoan_tien_tra_sau_vina_res.data.value)

        const kenh_nap_chiet_khau_vina_res = await dispatch(SettingAction.findByKey('kenh_nap_chiet_khau_vina'))
        setKenhNapChietKhauVina(kenh_nap_chiet_khau_vina_res.data.value)

        const kenh_nap_chiet_khau_tra_sau_vina_res = await dispatch(SettingAction.findByKey('kenh_nap_chiet_khau_tra_sau_vina'))
        setKenhNapChietKhauTraSauVina(kenh_nap_chiet_khau_tra_sau_vina_res.data.value)

        //==========

        const boi_so_nap_app_res = await dispatch(SettingAction.findByKey('boi_so_nap_app'))

        try {
            const parsedObject = JSON.parse(boi_so_nap_app_res.data.value);
            chonBoiMobifoneValue(parsedObject.boiMobifone);
            chonBoiVinaphoneValue(parsedObject.boiVinaphone);
            chonBoiViettelValue(parsedObject.boiViettel);
        } catch (error) {
            console.error('Error parsing JSON:', error);
            // Handle the error, e.g., throw an exception or return a default value
            // return null;
        }
      }
      fetchData();
    }, [])

    const onPressPositive = async () => {
        const boi_so_nap_app = {
            boiMobifone,
            boiVinaphone,
            boiViettel,
        }
        
        try {
            let response = await dispatch(SettingAction.update("boi_so_nap_app", { value: boi_so_nap_app }));
            response = await dispatch(AccountAction.batTatModuleBanTien(moduleBanTien));
            //==========
            response = await dispatch(SettingAction.update("kenh_hoan_tien", { value: kenh_hoan_tien }));
            response = await dispatch(SettingAction.update("kenh_hoan_tien_tra_sau", { value: kenh_hoan_tien_tra_sau }));
            response = await dispatch(SettingAction.update("kenh_nap_chiet_khau", { value: kenh_nap_chiet_khau }));
            response = await dispatch(SettingAction.update("kenh_nap_chiet_khau_tra_sau", { value: kenh_nap_chiet_khau_tra_sau }));
            //==========
            response = await dispatch(SettingAction.update("kenh_hoan_tien_vina", { value: kenh_hoan_tien_vina }));
            response = await dispatch(SettingAction.update("kenh_hoan_tien_tra_sau_vina", { value: kenh_hoan_tien_tra_sau_vina }));
            response = await dispatch(SettingAction.update("kenh_nap_chiet_khau_vina", { value: kenh_nap_chiet_khau_vina }));
            response = await dispatch(SettingAction.update("kenh_nap_chiet_khau_tra_sau_vina", { value: kenh_nap_chiet_khau_tra_sau_vina }));
            //==========
            console.log(response)
            if (typeof response.status !== 'undefined' && response.status == 'success') alert("Cập nhật cài đặt thành công");
            else if (typeof response.code !== 'undefined' && typeof response.msg !== 'undefined') alert("[Lỗi]\n"+response.msg)
            resetState();
            props.handleClose();
        } catch (error) {
            if (typeof error.code !== 'undefined' && typeof error.msg !== 'undefined') alert("[Lỗi]\n"+error.msg)
        }
    }

    const resetState = () => {
        //setKenhHoanTien("")
    }

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPermissionIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds, id);
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(1));
        } else if (selectedIndex === selectedPermissionIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedPermissionIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedPermissionIds.slice(0, selectedIndex),
                selectedPermissionIds.slice(selectedIndex + 1)
            );
        }
        setSelectedPermissionIds(newSelectedCustomerIds);
    };

    const chonBoiMobifone = (e, value) => {
        chonBoiMobifoneValue({
            ...boiMobifone,
            [value]: e.target.value == 1 ? 2 : 1
        });
    }

    const chonBoiVinaphone = (e, value) => {
        chonBoiVinaphoneValue({
            ...boiVinaphone,
            [value]: e.target.value == 1 ? 2 : 1
        })
    }

    const chonBoiViettel = (e, value) => {
        chonBoiViettelValue({
            ...boiViettel,
            [value]: e.target.value == 1 ? 2 : 1
        })
    }

    const handleChangeOperator = (value) => {
        setOperator(value);
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Cài đặt</DialogTitle>
            <DialogContent className='setting_form' style={{ width: 600, maxWidth: '80vw', paddingTop: 20 }}>
                <Grid
                    container
                    spacing={0}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <Typography
                        color="blue"
                        variant="body1"
                        >
                            Bật/Tắt module bắn tiền
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={6}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            {operator == 'MOBIFONE' && <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["topup"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "topup")}
                                    value={moduleBanTien["topup"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>}

                            {operator == 'VINAPHONE' && <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["topup_vina"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "topup_vina")}
                                    value={moduleBanTien["topup_vina"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>}

                            {operator == 'VIETTEL' && <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["topup_viettel"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "topup_viettel")}
                                    value={moduleBanTien["topup_viettel"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>}

                            <TableCell style={{padding: 0}}>
                                TOP-UP
                            </TableCell>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            {operator == 'MOBIFONE' && <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["tkc"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "tkc")}
                                    value={moduleBanTien["tkc"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>}

                            {operator == 'VINAPHONE' && <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["tkc_vina"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "tkc_vina")}
                                    value={moduleBanTien["tkc_vina"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>}

                            {operator == 'VIETTEL' && <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["tkc_viettel"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "tkc_viettel")}
                                    value={moduleBanTien["tkc_viettel"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>}

                            <TableCell style={{padding: 0}}>
                                TKC
                            </TableCell>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            {operator == 'MOBIFONE' && <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["dv"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "dv")}
                                    value={moduleBanTien["dv"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>}

                            {operator == 'VINAPHONE' && <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["dv_vina"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "dv_vina")}
                                    value={moduleBanTien["dv_vina"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>}

                            {operator == 'VIETTEL' && <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["dv_viettel"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "dv_viettel")}
                                    value={moduleBanTien["dv_viettel"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>}

                            <TableCell style={{padding: 0}}>
                                DV
                            </TableCell>
                        </Grid>
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            {operator == 'MOBIFONE' && <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["bc"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "bc")}
                                    value={moduleBanTien["bc"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>}

                            {operator == 'VINAPHONE' && <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["bc_vina"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "bc_vina")}
                                    value={moduleBanTien["bc_vina"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>}

                            {operator == 'VIETTEL' && <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["bc_viettel"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "bc_viettel")}
                                    value={moduleBanTien["bc_viettel"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>}

                            <TableCell style={{padding: 0}}>
                                BC
                            </TableCell>
                        </Grid>
                        {operator == 'MOBIFONE' && <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["napho"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "napho")}
                                    value={moduleBanTien["napho"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>
                            <TableCell style={{padding: 0}}>
                                Nạp hộ Mobifone
                            </TableCell>
                        </Grid>}
                        {operator == 'MOBIFONE' && <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["mymobifone"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "mymobifone")}
                                    value={moduleBanTien["mymobifone"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>
                            <TableCell style={{padding: 0}}>
                                My Mobifone
                            </TableCell>
                        </Grid>}
                        {operator == 'MOBIFONE' && <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TableCell padding="checkbox" style={{width: '20px'}}>
                                <Checkbox
                                    checked={moduleBanTien["gsmmobifone"] == 1 ? true : false}
                                    onChange={(event) => batTatModuleBanTien(event, "gsmmobifone")}
                                    value={moduleBanTien["gsmmobifone"] == 1 ? 1 : 2}
                                    style={{width: '20px'}}
                                />
                            </TableCell>
                            <TableCell style={{padding: 0}}>
                                GSM Mobifone
                            </TableCell>
                        </Grid>}
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={6}
                    >
                        <Select fullWidth variant="outlined" name="operator" value={operator} onChange={(e) => handleChangeOperator(e.target.value)}>
                            <MenuItem value="MOBIFONE" selected>MOBIFONE</MenuItem>
                            <MenuItem value="VINAPHONE">VINAPHONE</MenuItem>
                            <MenuItem value="VIETTEL">VIETTEL</MenuItem>
                        </Select>
                    </Grid>

                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <Typography
                        color="green"
                        variant="body1"
                        >
                            Chọn bội cho MOBIFONE
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiMobifone["10000"] == 1 ? true : false}
                                onChange={(event) => chonBoiMobifone(event, "10000")}
                                value={boiMobifone["10000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            10K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiMobifone["20000"] == 1 ? true : false}
                                onChange={(event) => chonBoiMobifone(event, "20000")}
                                value={boiMobifone["20000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            20K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiMobifone["30000"] == 1 ? true : false}
                                onChange={(event) => chonBoiMobifone(event, "30000")}
                                value={boiMobifone["30000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            30K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiMobifone["50000"] == 1 ? true : false}
                                onChange={(event) => chonBoiMobifone(event, "50000")}
                                value={boiMobifone["50000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            50K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiMobifone["100000"] == 1 ? true : false}
                                onChange={(event) => chonBoiMobifone(event, "100000")}
                                value={boiMobifone["100000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            100K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiMobifone["200000"] == 1 ? true : false}
                                onChange={(event) => chonBoiMobifone(event, "200000")}
                                value={boiMobifone["200000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            200K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiMobifone["300000"] == 1 ? true : false}
                                onChange={(event) => chonBoiMobifone(event, "300000")}
                                value={boiMobifone["300000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            300K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiMobifone["500000"] == 1 ? true : false}
                                onChange={(event) => chonBoiMobifone(event, "500000")}
                                value={boiMobifone["500000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            500K
                        </TableCell>
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={0}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <Typography
                        color="blue"
                        variant="body1"
                        >
                            Chọn bội cho VINAPHONE
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiVinaphone["10000"] == 1 ? true : false}
                                onChange={(event) => chonBoiVinaphone(event, "10000")}
                                value={boiVinaphone["10000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            10K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiVinaphone["20000"] == 1 ? true : false}
                                onChange={(event) => chonBoiVinaphone(event, "20000")}
                                value={boiVinaphone["20000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            20K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiVinaphone["30000"] == 1 ? true : false}
                                onChange={(event) => chonBoiVinaphone(event, "30000")}
                                value={boiVinaphone["30000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            30K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiVinaphone["50000"] == 1 ? true : false}
                                onChange={(event) => chonBoiVinaphone(event, "50000")}
                                value={boiVinaphone["50000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            50K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiVinaphone["100000"] == 1 ? true : false}
                                onChange={(event) => chonBoiVinaphone(event, "100000")}
                                value={boiVinaphone["100000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            100K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiVinaphone["200000"] == 1 ? true : false}
                                onChange={(event) => chonBoiVinaphone(event, "200000")}
                                value={boiVinaphone["200000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            200K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiVinaphone["300000"] == 1 ? true : false}
                                onChange={(event) => chonBoiVinaphone(event, "300000")}
                                value={boiVinaphone["300000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            300K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiVinaphone["500000"] == 1 ? true : false}
                                onChange={(event) => chonBoiVinaphone(event, "500000")}
                                value={boiVinaphone["500000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            500K
                        </TableCell>
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={0}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <Typography
                        color="red"
                        variant="body1"
                        >
                            Chọn bội cho VIETTEL
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiViettel["10000"] == 1 ? true : false}
                                onChange={(event) => chonBoiViettel(event, "10000")}
                                value={boiViettel["10000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            10K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiViettel["20000"] == 1 ? true : false}
                                onChange={(event) => chonBoiViettel(event, "20000")}
                                value={boiViettel["20000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            20K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiViettel["30000"] == 1 ? true : false}
                                onChange={(event) => chonBoiViettel(event, "30000")}
                                value={boiViettel["30000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            30K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiViettel["50000"] == 1 ? true : false}
                                onChange={(event) => chonBoiViettel(event, "50000")}
                                value={boiViettel["50000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            50K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiViettel["100000"] == 1 ? true : false}
                                onChange={(event) => chonBoiViettel(event, "100000")}
                                value={boiViettel["100000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            100K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiViettel["200000"] == 1 ? true : false}
                                onChange={(event) => chonBoiViettel(event, "200000")}
                                value={boiViettel["200000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            200K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiViettel["300000"] == 1 ? true : false}
                                onChange={(event) => chonBoiViettel(event, "300000")}
                                value={boiViettel["300000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            300K
                        </TableCell>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        xs={3}
                    >
                        <TableCell padding="checkbox" style={{width: '20px'}}>
                            <Checkbox
                                checked={boiViettel["500000"] == 1 ? true : false}
                                onChange={(event) => chonBoiViettel(event, "500000")}
                                value={boiViettel["500000"] == 1 ? 1 : 2}
                                style={{width: '20px'}}
                            />
                        </TableCell>
                        <TableCell style={{padding: 0}}>
                            500K
                        </TableCell>
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <Typography
                        color="green"
                        variant="body1"
                        >
                        Kênh hoàn tiền trả trước MOBIFONE
                        </Typography>
                        <Select fullWidth variant="outlined" name="kenh_hoan_tien" value={kenh_hoan_tien} onChange={(e) => setKenhHoanTien(e.target.value)}>
                            <MenuItem value="khong_qua_api" selected>Không qua API</MenuItem>
                            <MenuItem value="dichvu">DV</MenuItem>
                            <MenuItem value="bunca">BC</MenuItem>
                            <MenuItem value="napho">Nạp hộ Mobifone</MenuItem>
                            <MenuItem value="tkc">TKC</MenuItem>
                            <MenuItem value="smart_vi">Smart ví</MenuItem>
                        </Select>

                        <Typography
                        color="green"
                        variant="body1"
                        >
                        Kênh hoàn tiền trả sau MOBIFONE
                        </Typography>
                        <Select fullWidth variant="outlined" name="kenh_hoan_tien_tra_sau" value={kenh_hoan_tien_tra_sau} onChange={(e) => setKenhHoanTienTraSau(e.target.value)}>
                            <MenuItem value="khong_qua_api" selected>Không qua API</MenuItem>
                            <MenuItem value="dichvu">DV</MenuItem>
                            <MenuItem value="bunca">BC</MenuItem>
                            <MenuItem value="napho">Nạp hộ Mobifone</MenuItem>
                            <MenuItem value="tkc">TKC</MenuItem>
                            <MenuItem value="smart_vi">Smart ví</MenuItem>
                        </Select>

                        <Typography
                        color="green"
                        variant="body1"
                        >
                        Kênh nạp chiết khấu trả trước MOBIFONE
                        </Typography>
                        <Select fullWidth variant="outlined" name="kenh_nap_chiet_khau" value={kenh_nap_chiet_khau} onChange={(e) => setKenhNapChietKhau(e.target.value)}>
                            <MenuItem value="khong_qua_api" selected>Không qua API</MenuItem>
                            <MenuItem value="dichvu">DV</MenuItem>
                            <MenuItem value="bunca">BC</MenuItem>
                            <MenuItem value="napho">Nạp hộ Mobifone</MenuItem>
                            <MenuItem value="tkc">TKC</MenuItem>
                            <MenuItem value="smart_vi">Smart ví</MenuItem>
                        </Select>

                        <Typography
                        color="green"
                        variant="body1"
                        >
                        Kênh nạp chiết khấu trả sau MOBIFONE
                        </Typography>
                        <Select fullWidth variant="outlined" name="kenh_nap_chiet_khau_tra_sau" value={kenh_nap_chiet_khau_tra_sau} onChange={(e) => setKenhNapChietKhauTraSau(e.target.value)}>
                            <MenuItem value="khong_qua_api" selected>Không qua API</MenuItem>
                            <MenuItem value="dichvu">DV</MenuItem>
                            <MenuItem value="bunca">BC</MenuItem>
                            <MenuItem value="napho">Nạp hộ Mobifone</MenuItem>
                            <MenuItem value="tkc">TKC</MenuItem>
                            <MenuItem value="smart_vi">Smart ví</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={12}
                        xs={12}
                    >
                        <Typography
                        color="blue"
                        variant="body1"
                        >
                        Kênh hoàn tiền trả trước VINAPHONE
                        </Typography>
                        <Select fullWidth variant="outlined" name="kenh_hoan_tien_vina" value={kenh_hoan_tien_vina} onChange={(e) => setKenhHoanTienVina(e.target.value)}>
                            <MenuItem value="khong_qua_api" selected>Không qua API</MenuItem>
                            <MenuItem value="dichvu">DV</MenuItem>
                            <MenuItem value="bunca">BC</MenuItem>
                            <MenuItem value="napho">Nạp hộ Mobifone</MenuItem>
                            <MenuItem value="tkc">TKC</MenuItem>
                            <MenuItem value="smart_vi">Smart ví</MenuItem>
                        </Select>

                        <Typography
                        color="blue"
                        variant="body1"
                        >
                        Kênh hoàn tiền trả sau VINAPHONE
                        </Typography>
                        <Select fullWidth variant="outlined" name="kenh_hoan_tien_tra_sau_vina" value={kenh_hoan_tien_tra_sau_vina} onChange={(e) => setKenhHoanTienTraSauVina(e.target.value)}>
                            <MenuItem value="khong_qua_api" selected>Không qua API</MenuItem>
                            <MenuItem value="dichvu">DV</MenuItem>
                            <MenuItem value="bunca">BC</MenuItem>
                            <MenuItem value="napho">Nạp hộ Mobifone</MenuItem>
                            <MenuItem value="tkc">TKC</MenuItem>
                            <MenuItem value="smart_vi">Smart ví</MenuItem>
                        </Select>

                        <Typography
                        color="blue"
                        variant="body1"
                        >
                        Kênh nạp chiết khấu trả trước VINAPHONE
                        </Typography>
                        <Select fullWidth variant="outlined" name="kenh_nap_chiet_khau_vina" value={kenh_nap_chiet_khau_vina} onChange={(e) => setKenhNapChietKhauVina(e.target.value)}>
                            <MenuItem value="khong_qua_api" selected>Không qua API</MenuItem>
                            <MenuItem value="dichvu">DV</MenuItem>
                            <MenuItem value="bunca">BC</MenuItem>
                            <MenuItem value="napho">Nạp hộ Mobifone</MenuItem>
                            <MenuItem value="tkc">TKC</MenuItem>
                            <MenuItem value="smart_vi">Smart ví</MenuItem>
                        </Select>

                        <Typography
                        color="blue"
                        variant="body1"
                        >
                        Kênh nạp chiết khấu trả sau VINAPHONE
                        </Typography>
                        <Select fullWidth variant="outlined" name="kenh_nap_chiet_khau_tra_sau_vina" value={kenh_nap_chiet_khau_tra_sau_vina} onChange={(e) => setKenhNapChietKhauTraSauVina(e.target.value)}>
                            <MenuItem value="khong_qua_api" selected>Không qua API</MenuItem>
                            <MenuItem value="dichvu">DV</MenuItem>
                            <MenuItem value="bunca">BC</MenuItem>
                            <MenuItem value="napho">Nạp hộ Mobifone</MenuItem>
                            <MenuItem value="tkc">TKC</MenuItem>
                            <MenuItem value="smart_vi">Smart ví</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Huỷ</Button>
                <Button onClick={onPressPositive}>OK</Button>
            </DialogActions>
        </Dialog >
    );
}

export default CreateEditDialogSelectChannel;