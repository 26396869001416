import React, { useEffect, useState } from 'react';

import { Search as SearchIcon } from 'react-feather';

import {
  Box,
  Card,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
  MenuItem,
  Select as Select1,
  Button,
} from '@material-ui/core';

import Select from 'react-select';

import {
  DEFAULT_ROW_PER_PAGE,
} from 'src/utils/constants';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';

import moment from 'moment';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CustomerAction } from 'src/actions/customer.action';

const CustomerCareHistoryListToolbar = (props) => {
  const dispatch = useDispatch();
  const { filter } = useSelector(state => state.customer)

  const [values, setValues] = useState({
    phone: "",
    status: "",
    startDate: dayjs(filter.startDate, moment(new Date()).format('DD/MM/YYYY')),
    endDate: dayjs(filter.endDate, moment(new Date()).format('DD/MM/YYYY')),
  });

  const handleStartDateChange = (date) => {
    setValues({
      ...values,
      startDate: date
    });
  };

  const handleEndDateChange = (date) => {
    setValues({
      ...values,
      endDate: date
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    const today = new Date();

    const payload = {
      page: 0,
      limit: DEFAULT_ROW_PER_PAGE,
      phone: "",
      status: "",
      startDate: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format("DD/MM/YYYY"),
      endDate: dayjs(today).format("DD/MM/YYYY"),
    };
    dispatch(CustomerAction.updateFilter(payload));
  }, []);

  const onLookup = () => {
    const payload = {
      page: 'no_update',
      limit: 'no_update',
      phone: values.phone,
      status: values.status,
      startDate: values.startDate.format("DD/MM/YYYY"),
      endDate: values.endDate.format("DD/MM/YYYY"),
    };
    dispatch(CustomerAction.updateFilter(payload));
    dispatch(CustomerAction.history())
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onLookup();
    }
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
        <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <DatePicker
                  label="Thời gian bắt đầu"
                  style={{ width: '100%', marginRight: 24 }}
                  format='DD/MM/YYYY'
                  value={values.startDate}
                  onChange={(newValue) => handleStartDateChange(newValue)}
                  />
                <DatePicker
                  label="Thời gian kết thúc"
                  style={{ width: '100%', marginLeft: 24 }}
                  value={values.endDate}
                  format='DD/MM/YYYY'
                  onChange={(newValue) => handleEndDateChange(newValue)}
                />
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                          >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  name="phone"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  value={values.phone}
                  placeholder="Tìm SĐT"
                  variant="outlined"
                />
                <Select1
                  style={{ height: 56, width: "200px", marginLeft: 0 }}
                  name="status"
                  value={values.status || -99}
                  onChange={(newValue) => handleChange(newValue)}
                  variant="outlined"
                  startAdornment={(
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )}
                >
                  <MenuItem value="-99">Kết quả...</MenuItem>
                  <MenuItem value="APPROVED">Đồng ý</MenuItem>
                  <MenuItem value="REJECTED">Không đồng ý</MenuItem>
                  <MenuItem value="APPOINTMENT">Hẹn lại</MenuItem>
                  <MenuItem value="KBM">Không bắt máy</MenuItem>
                  <MenuItem value="KLLĐ">Không liên lạc được</MenuItem>
                  <MenuItem value="KEEP">Dùng lại gói cũ</MenuItem>
                  <MenuItem value="RE-REGISTER">Đã đăng ký lại</MenuItem>
                </Select1>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onLookup}
                  style={{ height: 56, marginTop: 0, width: 200, marginLeft: 24 }}
                  >
                  Tra cứu
                </Button>
              </Box>
            </LocalizationProvider>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
};

export default CustomerCareHistoryListToolbar;
